import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { InputSearch } from '../../components/InputSearch';
import { Select, optionProps } from '../../components/Select';
import { Painel } from '../../components/Painel';
import { ChangeExercise } from '../../modais/changeExercise';

import { Container, Content, Form, Card, Header } from './styles';

import { muscleGroupsSearch } from '../../objects/select';
import { api } from '../../services/api';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import {
  ExercisesService,
  IExercise,
  IMuscleGroup,
} from '../../services/ExercisesService';
import { ButtonCircle } from '../../components/ButtonCircle';
import { AddExercise } from '../../modais/addExercise';

interface SignInFormData {
  email: string;
  password: string;
}

const Exercises: React.FC = () => {
  const exercisesService = new ExercisesService();

  const formRef = useRef<FormHandles>(null);
  const { modals, addModal } = useModal();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [exercises, setExercises] = useState<IExercise[]>([]);
  const [muscleGroups, setMuscleGroups] = useState<IMuscleGroup[]>([]);
  const [filter, setFilter] = useState<IExercise[]>([]);
  const [selectValue, setSelectValue] = useState('');

  const getExercises = useCallback(() => {
    setLoading(true);
    exercisesService
      .getExercisesOfGym()
      .then(response => {
        setExercises(response.data);
        setFilter(response.data);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na busca dos exercícios.',
          description: err?.response?.status,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, exercisesService]);

  const getMuscleGroups = useCallback(() => {
    exercisesService
      .getMuscleGroups()
      .then(response => {
        setMuscleGroups([
          { id: '', name: 'Todos', is_main: false },
          ...response.data,
        ]);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na busca dos grupos musculares.',
          description:
            'Caso o proplema persista, entre em contato com o administrador do sistema',
        });
      });
  }, [addToast, exercisesService]);

  const handleRemoveExercise = useCallback(
    async (exercise_id: string) => {
      try {
        await api.delete(`exercises/${exercise_id}`);

        setExercises(exercises.filter(exercise => exercise.id !== exercise_id));
        setFilter(filter.filter(exercise => exercise.id !== exercise_id));

        addToast({
          type: 'success',
          title: 'Exercício removido',
        });
      } catch (err: any) {
        if (err?.response?.status === 403) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para executar essa ação.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na remoção do exercício',
          description:
            'Houve um erro na remoção do exercicio. Tente novamente mais tarde.',
        });
      }
    },
    [exercises, filter],
  );

  const handleFilter = useCallback(() => {
    setFilter(exercises);
    const input = formRef.current?.getFieldValue('exerciseName') as string;

    const values = exercises.filter(current => {
      if (selectValue) {
        if (
          current.muscle_groups.some(
            group =>
              group.id === selectValue &&
              current.name.toLowerCase().indexOf(input.toLowerCase()) > -1,
          )
        ) {
          return true;
        }
      } else if (current.name.toLowerCase().indexOf(input.toLowerCase()) > -1) {
        return true;
      }
      return false;
    });

    setFilter(values);
  }, [exercises, selectValue]);

  const handleChangeSelectForm = useCallback((e: any) => {
    setSelectValue(e.value);
  }, []);

  useEffect(() => {
    handleFilter();
  }, [handleFilter, selectValue]);

  useEffect(() => {
    getExercises();
  }, [modals]);

  useEffect(() => {
    getExercises();
    getMuscleGroups();
  }, []);

  return (
    <Painel loading={loading}>
      <Container>
        <Header>
          <h3>Exercícios</h3>
          <ButtonCircle
            name="addExercise"
            value="Novo Exercício"
            image="add"
            onClick={() => addModal(<AddExercise />)}
          />
        </Header>
        <Form ref={formRef} onSubmit={() => console.log()}>
          <InputSearch name="exerciseName" onChange={handleFilter} />
          <Select
            name="muscleGroup"
            options={muscleGroups.map(group => ({
              label: group.name,
              value: group.id,
            }))}
            placeholder="Grupo muscular"
            onChange={handleChangeSelectForm}
            blurInputOnSelect
          />
        </Form>
        <Content>
          {filter.map(exercise => (
            <Card
              key={exercise.id}
              id={exercise.id}
              thumbnail_url={exercise.thumbnail_url}
              exerciseName={exercise.name}
              muscleGroups={exercise.muscle_groups}
              functionChangeButton={() =>
                addModal(
                  <ChangeExercise
                    id={exercise.id}
                    name={exercise.name}
                    muscle_groups={exercise.muscle_groups}
                    description={exercise.description}
                    thumbnail_url={exercise.thumbnail_url}
                    video_url={exercise.video_url}
                  />,
                )
              }
              functionRemoveButton={() => handleRemoveExercise(exercise.id)}
            />
          ))}
        </Content>
      </Container>
    </Painel>
  );
};

export { Exercises };
