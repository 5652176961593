/* eslint-disable no-unused-expressions */
import React, { InputHTMLAttributes, useRef } from 'react';
import InputChar from '../InputChar';

import { Container } from './styles';

const InputToken: React.FC = () => {
  const ref1 = useRef<any>(null);
  const ref2 = useRef<any>(null);
  const ref3 = useRef<any>(null);
  const ref4 = useRef<any>(null);
  const ref5 = useRef<any>(null);

  return (
    <Container>
      <InputChar ref={ref1} name="c1" onChange={() => ref2.current?.focus()} />
      <InputChar ref={ref2} name="c2" onChange={() => ref3.current?.focus()} />
      <InputChar ref={ref3} name="c3" onChange={() => ref4.current?.focus()} />
      <InputChar ref={ref4} name="c4" onChange={() => ref5.current?.focus()} />
      <InputChar ref={ref5} name="c5" />
    </Container>
  );
};

export { InputToken };
