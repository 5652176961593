import styled from 'styled-components';
import { Form as FormContainer } from '@unform/web';
import { InputSearch } from '../../components/InputSearch';
import { CardUser } from '../../components/CardUser';

export const Container = styled.div`
  padding: 20px;
  min-height: 100%;

  @media (max-width: 1199px) {
    padding: 0;
    width: calc(100% - 40px);
    margin: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 20px 0;

  h3 {
    font-size: 32px;
    color: #151515;
    margin-bottom: 10px;
  }
`;

export const Form = styled(FormContainer)`
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: center;

  grid-column-gap: 10px;

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: stretch;

    grid-row-gap: 10px;
  }
`;

export const Content = styled.div`
  flex: 1;
  margin-top: 10px;

  display: grid;
  grid-gap: 1rem;
  justify-content: space-between;

  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 1199px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

export const Card = styled(CardUser)``;
