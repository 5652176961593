import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

interface containerProps {
  is_active?: boolean;
}

export const Container = styled.button<containerProps>`
  min-width: 250px;
  height: 65px;
  border-radius: 5px;

  text-decoration: none;

  border: none;

  display: flex;
  align-items: center;

  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 11.43%,
    rgba(196, 196, 196, 0.46) 100%
  );

  ${props =>
    !props.is_active &&
    css`
      outline: 3px solid #e00000;
    `}

  transition: background-color 0.5s;

  &:hover {
    background-color: ${shade(0.2, '#c4c4c480')};
  }

  span {
    font-size: 16px;
    color: #151515;
  }

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const ContainerImg = styled.div`
  margin: 0 10px;
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    max-height: 100%;
    width: auto;
  }
`;

export const ContainerName = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-left: 5px;
  }
`;
