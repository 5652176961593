import { FormHandles } from '@unform/core';
import React, { FormEvent, useCallback, useRef, useState } from 'react';
import * as yup from 'yup';

import { HeaderModal1 } from '../../../components/HeaderModal1';
import { InputCheckboxBox } from '../../../components/InputCheckboxBox';
import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';
import { api } from '../../../services/api';
import { formatUsername } from '../../../utils/formatUsername';
import { getValidationErrors } from '../../../utils/getValidationErrors';

import {
  Container,
  ContainerCheckboxBox,
  FormContainer,
  Info,
  Input,
  Separate,
  Submit,
} from './styles';

interface modalProps {
  fullname: string;
  nickname: string;
  email: string;
  gym_id: string;
  code: string;
  birthday?: string;
  phone?: string;
}

interface formProps {
  username: string;
  password: string;
  password_confirmation: string;
  min_age: boolean;
  privacity: boolean;
}

const SignUp3: React.FC<modalProps> = ({
  fullname,
  nickname,
  email,
  gym_id,
  code,
  birthday,
  phone,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { removeAllModals } = useModal();
  const { addToast } = useToast();
  const [successText, setSuccessText] = useState('');
  const [enableDisableSubmit, setenableDisableSubmit] = useState(false);

  const handleEnableOrDisableSubmit = useCallback(() => {
    setenableDisableSubmit(
      formRef.current?.getFieldValue('min_age') &&
        formRef.current?.getFieldValue('privacity'),
    );
  }, []);

  const handleSubmit = useCallback(
    async (data: formProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          password: yup
            .string()
            .min(8, 'A senha deve ter no mínimo 8 caracteres'),
          password_confirmation: yup
            .string()
            .min(8, 'A senha deve ter no mínimo 8 caracteres')
            .oneOf([yup.ref('password'), null], 'Senhas não coincidem.'),
          min_age: yup.boolean().required().isTrue(),
          privacity: yup.boolean().required().isTrue(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post(
          '/users',
          {
            fullname,
            nickname,
            username: data.username,
            phone: phone || undefined,
            birthday: birthday || undefined,
            email,
            password: data.password,
            minAge: data.min_age,
            privacity: data.privacity,
          },
          {
            headers: {
              code: code.toUpperCase(),
            },
          },
        );

        addToast({
          type: 'success',
          title: 'Cadastro finalizado com sucesso.',
          description:
            'Faça o login e curta a nossa plataforma, bons treinos! 😁',
        });

        removeAllModals();
      } catch (err: any) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização dos dados',
          description:
            'Houve um erro na atualização dos dados. Tente novamente mais tarde.',
        });
      }
    },
    [
      addToast,
      birthday,
      code,
      email,
      fullname,
      nickname,
      phone,
      removeAllModals,
    ],
  );

  const handleVerifyUsername = useCallback(
    async (e: FormEvent<HTMLInputElement>) => {
      const username = e.currentTarget.value;

      try {
        await api.get('users/verify-username', {
          params: {
            username,
          },
        });
        setSuccessText('Username disponível.');
        formRef.current?.setFieldError('username', '');
      } catch {
        formRef.current?.setFieldError('username', 'Username já cadastrado.');
        setSuccessText('');
      }
    },
    [],
  );

  const handleFormatUsername = useCallback(
    async (e: FormEvent<HTMLInputElement>) => {
      const username = e.currentTarget.value;
      formRef.current?.setFieldValue('username', formatUsername(username));
    },
    [],
  );

  return (
    <Container>
      <HeaderModal1 title="Continuar Cadastro" />
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <Info text="Finalize o seu cadastro, inserindo as informações solicitadas abaixo." />

        <Separate label="Senha de acesso" />
        <Input name="password" label="Insira a sua senha" type="password" />
        <Input
          name="password_confirmation"
          label="Confirme a sua senha"
          type="password"
        />

        <Separate label="Concentimento" />
        <ContainerCheckboxBox>
          <InputCheckboxBox
            name="min_age"
            id="min_age"
            onChange={handleEnableOrDisableSubmit}
          />
          <label htmlFor="min_age">Tenho mais de 18 anos de idade.</label>
        </ContainerCheckboxBox>

        <ContainerCheckboxBox>
          <InputCheckboxBox
            name="privacity"
            id="privacity"
            onChange={handleEnableOrDisableSubmit}
          />
          <label htmlFor="privacity">
            Li e aceito as{' '}
            <a
              href="http://suaacademia.com/privacity"
              target="_blank"
              rel="noopener noreferrer"
            >
              políticas de privacidade
            </a>{' '}
            da plataforma <label className="black-ops">SUA ACADEMIA</label>.
          </label>
        </ContainerCheckboxBox>

        <Submit
          name="submit"
          value="Finalizar cadastro"
          onSubmit={() => formRef.current?.submitForm()}
          disabled={!enableDisableSubmit}
        />
      </FormContainer>
    </Container>
  );
};

export { SignUp3 };
