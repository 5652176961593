import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as Yup from 'yup';

import { HeaderModal3 } from '../../components/HeaderModal3';
import { InputText } from '../../components/InputText';
import { InputTextArea } from '../../components/InputTextArea';
import { Select } from '../../components/Select';
import { SeparateLabel } from '../../components/SeparateLabel';
import { useToast } from '../../hooks/toast';
import { muscleGroupsSearch } from '../../objects/select';

import { api } from '../../services/api';
import { formatDayOfWeek } from '../../utils/formatDayOfWeek';
import { getValidationErrors } from '../../utils/getValidationErrors';
import { isNaNVerify } from '../../utils/isNaNVerify';

import {
  Container,
  ContainerInline,
  FormContainer,
  Input,
  Submit,
} from './styles';
import { useModal } from '../../hooks/modal';

interface IRequestTraining {
  exercise: {
    name: string;
    muscle_group: string;
    thumbnail_url: string | undefined;
  };
  user: {
    avatar_url: string | undefined;
  };
  id: string;
  interval_min: number | undefined;
  interval_max: number | undefined;
  reps_min: number | undefined;
  reps_max: number | undefined;
  sets_min: number | undefined;
  sets_max: number | undefined;
  additional_information: string | undefined;
  day_of_week: number;
}

interface IChangeUserFormProp {
  interval_min: number;
  interval_max: number;
  reps_min: number;
  reps_max: number;
  sets_min: number;
  sets_max: number;
  additional_information: string;
  type: string;
}

interface IChangeTrainingProps {
  training_id: string;
}

const ChangeTrainingUser: React.FC<IChangeTrainingProps> = ({
  training_id,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [training, setTraining] = useState<IRequestTraining>();
  const { addToast } = useToast();
  const { removeModal } = useModal();

  const handleSubmit = useCallback(
    async (data: IChangeUserFormProp): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          reps_min: Yup.number()
            .nullable(true)
            .transform((currentValue, _) =>
              !Number.isNaN(currentValue) ? Number(currentValue) : undefined,
            ),
          reps_max: Yup.number()
            .nullable(true)
            .transform((currentValue, _) =>
              !Number.isNaN(currentValue) ? Number(currentValue) : undefined,
            ),
          sets_max: Yup.number()
            .nullable(true)
            .transform((currentValue, _) =>
              !Number.isNaN(currentValue) ? Number(currentValue) : undefined,
            ),
          sets_min: Yup.number()
            .nullable(true)
            .transform((currentValue, _) =>
              !Number.isNaN(currentValue) ? Number(currentValue) : undefined,
            ),
          interval_min: Yup.number()
            .nullable(true)
            .transform((currentValue, _) =>
              !Number.isNaN(currentValue) ? Number(currentValue) : undefined,
            ),
          interval_max: Yup.number()
            .nullable(true)
            .transform((currentValue, _) =>
              !Number.isNaN(currentValue) ? Number(currentValue) : undefined,
            ),
          additional_information: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const dataUpdateTraining = {
          interval_min: !Number.isNaN(data.interval_min)
            ? data.interval_min
            : undefined,
          interval_max: !Number.isNaN(data.interval_max)
            ? data.interval_max
            : undefined,
          sets_min: !Number.isNaN(data.sets_min) ? data.sets_min : undefined,
          sets_max: !Number.isNaN(data.sets_max) ? data.sets_max : undefined,
          reps_min: !Number.isNaN(data.reps_min) ? data.reps_min : undefined,
          reps_max: !Number.isNaN(data.reps_max) ? data.reps_max : undefined,
          // type: !(data.type.length === 0) ? data.type : undefined,
          additional_information: !(data.additional_information.length === 0)
            ? data.additional_information
            : undefined,
        };

        await api.put(`trainings/${training_id}`, dataUpdateTraining);

        addToast({
          title: 'Dados alterados com sucesso',
          type: 'success',
        });

        removeModal();
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          title: 'Erro na atualização',
          description:
            'Houve um erro na atualização dos dados. Tente novamente.',
          type: 'error',
        });
      }
    },
    [addToast, training_id],
  );

  useEffect(() => {
    api
      .get(`trainings/${training_id}`)
      .then(response => {
        setTraining(response.data);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na busca dos dados do treino.',
          description: err?.response?.status,
        });
      });
  }, []);

  return (
    <Container>
      <HeaderModal3
        title={training?.exercise.name || ''}
        subtitleLeft={training?.exercise.muscle_group || ''}
        subtitleRight={formatDayOfWeek(training?.day_of_week).labelABC}
        avatar_user={training?.user.avatar_url}
        midia_exercise={training?.exercise.thumbnail_url}
      />

      <FormContainer
        ref={formRef}
        initialData={training}
        onSubmit={handleSubmit}
      >
        {/* <ContainerInline>
          <Select
            name="methodSelect"
            options={muscleGroupsSearch}
            placeholder="Método de treino"
          />

          <Input name="methodOther" placeholder="Outro" />
        </ContainerInline> */}

        <SeparateLabel label="Quantidade de séries" />
        <ContainerInline>
          <InputText type="number" label="De" name="sets_min" />
          <InputText type="number" label="Até" name="sets_max" />
        </ContainerInline>

        <SeparateLabel label="Quantidade de repetições" />
        <ContainerInline>
          <InputText type="number" label="De" name="reps_min" />
          <InputText type="number" label="Até" name="reps_max" />
        </ContainerInline>

        <SeparateLabel label="Intervalo entre séries/repetições (segundos)" />
        <ContainerInline>
          <InputText type="number" label="De" name="interval_min" />
          <InputText type="number" label="Até" name="interval_max" />
        </ContainerInline>

        <SeparateLabel label="Informações adicionais" />
        <InputTextArea
          name="additional_information"
          placeholder="Insira aqui alguma informação adicional desse exercício para este aluno."
        />
        <Submit name="submit" value="Alterar exercício" />
      </FormContainer>
    </Container>
  );
};

export { ChangeTrainingUser };
