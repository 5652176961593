import styled from 'styled-components';
import { ButtonFlat } from '../ButtonFlat';

export const Container = styled.div`
  position: relative;

  width: 100%;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #545454;

  span {
    width: 100%;

    text-align: center;

    color: #f5f5f5;
    font-size: 32px;
  }

  @media (max-width: 1199px) {
    height: 70px;

    span {
      font-size: 23px;
    }
  }
`;

export const CloseButton = styled(ButtonFlat)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
