import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 250px;
  height: 65px;
  border-radius: 5px;

  display: flex;
  position: relative;
  align-items: center;

  background: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 11.43%,
    rgba(196, 196, 196, 0.46) 100%
  );

  transition: background-color 0.5s;

  &:hover {
    background-color: ${shade(0.2, '#c4c4c480')};
  }

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 16px;
  color: #151515;
`;

export const Subtitle = styled.span`
  font-size: 12px;
  color: #151515;
`;

export const ContainerImg = styled.div`
  margin: 0 10px;
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    max-height: 100%;
    width: auto;
  }
`;
