import React, { useCallback } from 'react';
import { CardPaymentMethod } from '../../../components/CardPaymentMethod';
import { HeaderModal1 } from '../../../components/HeaderModal1';
import { useModal } from '../../../hooks/modal';

import { PixPayment } from '../pixPayment';

import { Container, Content, PaymentMethods, Title } from './styles';

interface modalPaymentsProps {
  bill_id: string;
  month: string;
}

const SelectPaymentMethod: React.FC<modalPaymentsProps> = ({
  bill_id,
  month,
}) => {
  const { addModal, removeModal } = useModal();

  const handleGeneratePixPayment = useCallback(() => {
    removeModal();
    addModal(<PixPayment bill_id={bill_id} month={month} />);
  }, [addModal, bill_id, month, removeModal]);

  return (
    <Container>
      <HeaderModal1 title="Método de pagamento" />
      <Content>
        <Title>
          Selecione o <span>método de pagamento</span>:
        </Title>

        <PaymentMethods>
          <CardPaymentMethod
            name="Pix"
            method="pix"
            onClick={() => handleGeneratePixPayment()}
          />
        </PaymentMethods>
      </Content>
    </Container>
  );
};

export { SelectPaymentMethod };
