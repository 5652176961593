import React, { HtmlHTMLAttributes } from 'react';

import {
  BottonHeader,
  CloseButton,
  Container,
  ContainerImg,
  Content,
  TopHeader,
} from './styles';

import noAvatar from '../../assets/no-avatar.jpg';
import { useModal } from '../../hooks/modal';

interface headerModalProps extends HtmlHTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle: string;
  avatar_user?: string | undefined;
}

const HeaderModal2: React.FC<headerModalProps> = ({
  title,
  subtitle,
  avatar_user,
  ...rest
}) => {
  const { removeModal } = useModal();
  return (
    <Container {...rest}>
      <CloseButton name="close" onClick={() => removeModal()} />
      <ContainerImg>
        <img src={avatar_user || noAvatar} alt="img user" />
      </ContainerImg>
      <Content>
        <TopHeader>
          <span>{title}</span>
        </TopHeader>
        <BottonHeader>
          <span>{subtitle}</span>
        </BottonHeader>
      </Content>
    </Container>
  );
};

export { HeaderModal2 };
