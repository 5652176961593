import { Form } from '@unform/web';
import styled from 'styled-components';
import { ButtonConfirm } from '../../../components/ButtonConfirm';
import { InputText } from '../../../components/InputText';
import { SeparateLabel } from '../../../components/SeparateLabel';
import { TextInfo } from '../../../components/TextInfo';

export const Container = styled.div`
  width: 100%;

  background-color: #f5f5f5;
`;

export const Info = styled(TextInfo)`
  margin: 30px 0;

  @media (max-width: 1199px) {
    max-width: calc(100% - 40px);
    margin: 20px auto;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;

  padding: 10px 20px;

  row-gap: 5px;
`;

export const Separate = styled(SeparateLabel)`
  margin: 5px 0;
`;

export const Input = styled(InputText)``;

export const Submit = styled(ButtonConfirm)`
  align-self: center;
  margin: 10px 0 30px;
`;

export const ContainerCheckboxBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:not(& + &) {
    margin-top: 15px;
  }

  & + & {
    margin-top: 5px;
  }

  label {
    margin-left: 10px;
    font-size: 16px;

    color: #151515;

    a {
      font-size: 16px;
      color: #151515;
    }

    &.black-ops {
      font-family: 'Black Ops One', cursive;
      margin-left: 0;
    }
  }
`;
