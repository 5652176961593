import { shade } from 'polished';
import styled from 'styled-components';
import { ButtonConfirm } from '../../components/ButtonConfirm';

export const Container = styled.div`
  width: 100%;
  background-color: #f5f5f5;

  height: auto;
`;

export const Content = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  width: 90%;
  margin: auto;
`;

export const ContainerText = styled.div`
  margin: 20px 0;
`;

export const Text = styled.p`
  font-size: 21px;
  margin-bottom: 5px;

  span {
    font-weight: 500;
  }
`;

export const Button = styled(ButtonConfirm)`
  align-self: center;
  margin: 20px auto;

  background-color: #c53030;

  &:hover {
    background-color: ${shade(0.2, '#c53030')};
  }
`;
