import { useField } from '@unform/core';
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FiAlertCircle } from 'react-icons/fi';
import errorImg from './assets/error.svg';
import checkImg from './assets/check.svg';

import {
  Container,
  ContainerError,
  ContainerInput,
  ContainerSuccess,
  Info,
  Input,
} from './styles';

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  labelColor?: string;
  successText?: string;
  info?: string;
}

const InputText: React.FC<inputProps> = ({
  name,
  label,
  labelColor,
  property,
  style,
  successText,
  info,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { defaultValue, fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: inputRef.current?.type === 'number' ? 'valueAsNumber' : 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <Container property={property} style={style} labelColor={labelColor}>
      {label && <span>{label}</span>}
      <ContainerInput
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        // {...rest}
      >
        <Input
          ref={inputRef}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          autoCapitalize="none"
          {...rest}
        />

        {!!info && !isFilled && (
          <Info title={info}>
            <FiAlertCircle color="#ff955f" size={20} />
          </Info>
        )}
      </ContainerInput>

      {!!successText && (
        <ContainerSuccess>
          <img src={checkImg} alt="img alert" />
          <span>{successText}</span>
        </ContainerSuccess>
      )}
      {error && (
        <ContainerError>
          <img src={errorImg} alt="img error" />
          <span>{error}</span>
        </ContainerError>
      )}
    </Container>
  );
};

export { InputText };
