import React, { useCallback, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import Lottie from 'react-lottie';

import { HeaderModal1 } from '../../../components/HeaderModal1';
import { useToast } from '../../../hooks/toast';

import loadingLottie from './assets/loading.json';

import {
  ButtonCopy,
  ButtonFinish,
  Container,
  ContainerBill,
  ContainerInfo,
  ContainerQrCodeImage,
  Content,
  Info,
  Instructions,
  KeyCopyPast,
  LineBill,
  SectionCopyPast,
  SectionQr,
  Title,
} from './styles';
import { api } from '../../../services/api';
import { useModal } from '../../../hooks/modal';

interface modalPaymentsProps {
  bill_id: string;
  month: string;
}

interface pixPaymentProps {
  id: number;
  qr_code_base64: string;
  key_copy_past: string;
  amount: number;
  notification_url: string;
  collector: {
    name: string;
    long_name: string;
  };
}

const PixPayment: React.FC<modalPaymentsProps> = ({ bill_id, month }) => {
  const [paymentData, setPaymentData] = useState<pixPaymentProps>(
    {} as pixPaymentProps,
  );

  const { addToast } = useToast();
  const { removeAllModals } = useModal();

  useEffect(() => {
    api
      .post(`payments/pix/${bill_id}`)
      .then(response => {
        setPaymentData(response.data);
      })
      .catch(err => {
        console.log(err?.response?.status);
        addToast({
          type: 'error',
          title: 'Erro na criação do método de pagamento.',
          description: 'Entre em contato com o adiministrador do sistema.',
        });
      });
  }, [addToast, bill_id]);

  useEffect(() => {
    const verifyStatusPayment = setInterval(() => {
      api
        .get(`payments/${bill_id}`)
        .then(response => {
          if (response?.data[0]?.status === 'approved') {
            addToast({
              type: 'success',
              title: 'Pagamento recebido.',
            });
            removeAllModals();

            window.location.reload();
          }
        })
        .catch(err => console.log(err));
    }, 5000);

    return () => clearTimeout(verifyStatusPayment);
  }, [addToast, bill_id, removeAllModals]);

  const handleCopyText = useCallback(
    (text: string) => {
      copy(text);
      addToast({
        title: 'Copiado para área de transferência.',
      });
    },
    [addToast],
  );

  return (
    <Container>
      <HeaderModal1 title={`Pagamento - ${month}`} />
      <Content>
        <ContainerInfo>
          <Info text="Ao realizar o pagamento, clique em “Concluir” e aguarde a confirmação na tela “Histórico de pagamentos”." />
          <Info text="A confirmação ocorrerá alguns instantes após o pagamento." />
          <Info text="O QRCODE gerado expira em 1 hora." />
        </ContainerInfo>
        <SectionQr>
          <div>
            <Title>Pagamento com QRCODE</Title>
            <Instructions>
              <h3>Instruções:</h3>
              <p>Entre no aplicativo do seu banco e procure por PIX.</p>
              <p>Selecione a opção de pagamento com QRCODE.</p>
              <p>
                Escaneie o código ao lado, confira os dados e finalize o
                pagamento.
              </p>
            </Instructions>
          </div>
          <ContainerQrCodeImage>
            {paymentData?.qr_code_base64 ? (
              <img
                src={`data:image/png;base64,${paymentData?.qr_code_base64}`}
                alt="qr-code"
              />
            ) : (
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loadingLottie,
                }}
                width={100}
                height={100}
              />
            )}
          </ContainerQrCodeImage>
        </SectionQr>

        <SectionCopyPast>
          <Title>Pix copia e cola</Title>
          <ContainerInfo>
            <Info text="Pix Copia e Cola é uma forma de realizar pagamentos PIX com valores determinados pelo recebedor." />
          </ContainerInfo>
          <Instructions>
            <h3>Instruções:</h3>
            <p>Entre no aplicativo do seu banco e procure por PIX.</p>
            <p>Procure pela opção “Pix Copia e Cola”.</p>
            <p>
              Copie o código abaixo e cole no campo indicado no aplicativo do
              seu banco, confira os dados e finalize o pagamento.
            </p>
          </Instructions>
          <KeyCopyPast>
            <span>{paymentData?.key_copy_past}</span>
          </KeyCopyPast>
          <ButtonCopy
            value="Copiar chave"
            name="copy"
            onClick={() => handleCopyText(paymentData?.key_copy_past)}
          />
        </SectionCopyPast>
        <ContainerBill>
          <LineBill>
            <span>Valor</span>
            <span>R$ {paymentData?.amount}</span>
          </LineBill>
          <LineBill>
            <span>Recebedor</span>
            <span>{paymentData?.collector?.name}</span>
          </LineBill>
        </ContainerBill>
        <ButtonFinish
          value="Concluir"
          name="close modal"
          onClick={() => removeAllModals()}
        />
      </Content>
    </Container>
  );
};

export { PixPayment };
