import styled from 'styled-components';
import { ButtonFlat } from '../ButtonFlat';

export const Container = styled.div`
  width: 100%;
  height: 140px;

  padding-top: 10px;

  display: flex;
  align-items: center;

  background: linear-gradient(
    0deg,
    rgba(245, 245, 245, 1) 30%,
    rgba(84, 84, 84, 1) 50%
  );

  @media (max-width: 1199px) {
    height: 100px;
  }
`;

export const ContainerImgUser = styled.div`
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  margin-left: 20px;

  z-index: 10;

  transform: rotate(-10deg);

  img {
    max-height: 100%;
    width: auto;
  }

  @media (max-width: 1199px) {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
  }
`;

export const ContainerImgExercise = styled.div`
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  z-index: 9;

  margin-right: 20px;
  margin-left: -60px;

  transform: rotate(10deg);

  img {
    max-height: 100%;
    width: auto;
  }

  @media (max-width: 1199px) {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;

    margin-left: -25px;
  }
`;

export const TopHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;

  span {
    text-align: left;

    color: #f5f5f5;
    font-size: 32px;

    @media (max-width: 1199px) {
      font-size: 20px;
    }
  }
`;

export const BottonHeader = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    text-align: center;

    color: #151515;
    font-size: 23px;

    @media (max-width: 1199px) {
      font-size: 18px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100%;

  justify-content: space-evenly;

  flex-direction: column;
  flex: 1;

  padding: 0 20px;
`;

export const CloseButton = styled(ButtonFlat)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
