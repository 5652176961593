import styled, { css } from 'styled-components';
import { ButtonOptions } from '../ButtonOptions';

interface containerProps {
  currentSubscription?: boolean;
}

export const Container = styled.div<containerProps>`
  width: 560px;
  height: 120px;
  background-color: #f5f5f5;

  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  border: 5px solid transparent;

  ${props =>
    props.currentSubscription &&
    css`
      border: 5px solid #ff955fe6;
    `}

  @media (max-width: 1199px) {
    flex-direction: column;

    width: 100%;
    height: auto;

    padding: 10px 0;
  }
`;

// 1

export const ContainerTitle = styled.div`
  width: 25%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1199px) {
    width: 100%;
    height: auto;
    padding: 5px 0;
  }
`;

export const Title = styled.h2`
  font-size: 23px;
  font-weight: 400;
  text-align: center;
`;

// 2

export const ContainerInfo = styled.div`
  width: 40%;
  height: calc(100% - 20px);

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  border-left: 1px solid #9b9b9b;
  border-right: 1px solid #9b9b9b;

  @media (max-width: 1199px) {
    border: none;
    width: calc(100% - 20px);
    height: auto;

    padding: 5px 0;

    border-top: 1px solid #9b9b9b;
    border-bottom: 1px solid #9b9b9b;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 21px;

  width: calc(100% - 20px);

  div {
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-right: 2px;
    }
  }

  & > span {
    color: #9b9b9b;
  }
`;

// 3

export const ContainerPrice = styled.div`
  width: 35%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1199px) {
    width: 100%;
    height: auto;

    padding: 5px 0;
  }
`;

export const Price = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  span {
    font-size: 13px;
    color: #9b9b9b;

    &.price {
      font-size: 23px;
      color: #151515;
      margin: 0px 5px;
    }
  }
`;

export const Button = styled(ButtonOptions)`
  width: 150px;
`;
