import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import { HeaderModal1 } from '../../components/HeaderModal1';

import {
  Container,
  ContainerDataSubscription,
  ContainerInfo,
  Content,
  DataSubscription,
  Info,
  Select,
  Submit,
} from './styles';

import alertImg from './assets/alert.svg';
import halterImg from './assets/halter.svg';
import usersImg from './assets/users.svg';
import dueDateImg from './assets/due-date.svg';
import priceImg from './assets/price.svg';
import { dueDates } from '../../objects/select';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import { getValidationErrors } from '../../utils/getValidationErrors';
import { api } from '../../services/api';

interface modalProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  news: number;
  exercises: number;
  users: number;
  price: number;
  subscription_id: string;
}

interface formProps {
  due_date: number;
}

const SubscriptionData: React.FC<modalProps> = ({
  title,
  news,
  exercises,
  users,
  price,
  subscription_id,
  ...rest
}) => {
  const formRef = useRef<FormHandles>(null);
  const { removeAllModals } = useModal();
  const { addToast } = useToast();

  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    api
      .get('subscriptions/current')
      .then(() => {
        setIsSubscribed(true);
      })
      .catch(() => {
        setIsSubscribed(false);
      });
  }, []);

  const handleSubmit = useCallback(
    async (data: formProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          due_date: yup
            .number()
            .required()
            .test(
              'test',
              'Data não disponível.',
              (value: number | undefined) =>
                value === 5 || value === 10 || value === 15,
            ),
        });

        await schema.validate(data, { abortEarly: false });

        if (!isSubscribed) {
          await api.post('subscriptions/subscribe', {
            due_date_at: data.due_date,
            subscription_id,
          });
        } else {
          await api.put('subscriptions/subscribe', {
            due_date_at: data.due_date,
            subscription_id,
          });
        }

        addToast({
          type: 'success',
          title: 'Assinatura realizada com sucesso.',
        });

        addToast({
          type: 'info',
          title: 'Para migrações do período de teste',
          description:
            'Caso o aviso de teste não seja removido, basta fazer o logoff e entrar novamente na plataforma.',
        });

        removeAllModals();
      } catch (err: any) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        if (err?.response?.status === 401 || err?.response?.status === 403) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para executar essa ação.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Houve um erro. Tente novamente.',
        });
      }
    },
    [addToast, isSubscribed, removeAllModals, subscription_id],
  );

  return (
    <Container {...rest}>
      <HeaderModal1 title="Dados da assinatura" />
      <ContainerInfo>
        <Info text="Escolha o dia do vencimento da fatura." />
      </ContainerInfo>

      <Content ref={formRef} onSubmit={handleSubmit}>
        <h2>Plano {title}</h2>

        <ContainerDataSubscription>
          <DataSubscription>
            <div>
              <img src={alertImg} alt="alert img" />
              <span>Alertas: </span>
            </div>
            <span>{news}</span>
          </DataSubscription>
          <DataSubscription>
            <div>
              <img src={halterImg} alt="alert img" />
              <span>Exercícios: </span>
            </div>
            <span>{exercises}</span>
          </DataSubscription>
          <DataSubscription>
            <div>
              <img src={usersImg} alt="alert img" />
              <span>Usuários: </span>
            </div>
            <span>{users}</span>
          </DataSubscription>
          <DataSubscription>
            <div>
              <img src={dueDateImg} alt="due date img" />
              <span>Vencimento da fatura: </span>
            </div>
            <Select
              name="due_date"
              options={dueDates}
              defaultValue={{ value: 10, label: '10' }}
              isFlex1Mobile
            />
          </DataSubscription>
          <DataSubscription>
            <div>
              <img src={priceImg} alt="price img" />
              <span>Preço: </span>
            </div>
            <span>{`R$ ${price.toFixed(2)}`}</span>
          </DataSubscription>
        </ContainerDataSubscription>
        <Submit
          name="submit"
          value="Confirmar"
          onClick={formRef.current?.submitForm}
        />
      </Content>
    </Container>
  );
};

export { SubscriptionData };
