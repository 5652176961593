import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_SERVER,
});

const token = localStorage.getItem('@SuaAcademia:token');

api.defaults.headers.common.Authorization = `Bearer ${token}`;

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 403) {
      // TODO
      /**
       * transformar o service em um hook para usar o toast aqui (é viavel?)
       */
    }

    return Promise.reject(error);
  },
);

export { api };
