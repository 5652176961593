import { Form } from '@unform/web';
import styled from 'styled-components';
import { ButtonConfirm } from '../../../components/ButtonConfirm';
import { TextInfo } from '../../../components/TextInfo';

export const Container = styled.div`
  width: 100%;

  background-color: #f5f5f5;
`;

export const Info = styled(TextInfo)`
  margin: 25px 0;
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;

  padding: 0 20px;

  @media (max-width: 1199px) {
    width: calc(100% - 40px);
  }
`;

export const Submit = styled(ButtonConfirm)`
  align-self: center;
  margin: 40px 0;
`;
