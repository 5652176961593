import React, { HtmlHTMLAttributes, useCallback, useState } from 'react';

import { Container, OptMenu, Submenu, OptSubmenu } from './styles';

import chevron from '../../assets/chevron.svg';

interface menuProps extends HtmlHTMLAttributes<HTMLUListElement> {
  icon?: string;
  label: string;
  submenu?: subMenuProps[];
  onClick?: any;
  minLevelAccess?: number;
  levelAccessUser?: number;
}

interface subMenuProps {
  label: string;
  onClick?: any;
  minLevelAccess: number;
}

const ElementMenu: React.FC<menuProps> = ({
  label,
  icon,
  submenu,
  onClick,
  minLevelAccess = 1,
  levelAccessUser = 1,
  ...rest
}) => {
  const [activeSubmenu, setActiveSubmenu] = useState(false);

  const handleActiveMenu = useCallback(() => {
    setActiveSubmenu(!activeSubmenu);
  }, [activeSubmenu]);

  return minLevelAccess <= levelAccessUser ? (
    <Container onClick={onClick} {...rest}>
      <OptMenu
        onClick={handleActiveMenu}
        show={minLevelAccess <= levelAccessUser}
      >
        <img src={icon} alt="icon" />
        <span>{label}</span>
        {!!submenu?.length && (
          <img className="chevron" src={chevron} alt="icon" />
        )}
      </OptMenu>
      {!!submenu?.length && (
        <Submenu activeSubmenu={activeSubmenu}>
          {submenu.map((sub, i) => (
            <OptSubmenu
              key={i.toString()}
              onClick={sub.onClick}
              show={sub.minLevelAccess <= levelAccessUser}
            >
              <span>{sub.label}</span>
            </OptSubmenu>
          ))}
        </Submenu>
      )}
    </Container>
  ) : (
    <></>
  );
};

export { ElementMenu };
