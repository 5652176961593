import styled, { css } from 'styled-components';
import { IconBaseProps } from 'react-icons';
import { shade } from 'polished';
import { ButtonOptions } from '../ButtonOptions';

interface containerProps {
  no_shadow?: boolean;
}

export const ContainerInput = styled.div`
  display: flex;

  margin: auto;
  width: 80%;

  justify-content: center;

  padding: 0;
  margin: 10px auto;

  transition: border-color 0.5s;
`;

export const ContainerContainerMidia = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const ContainerMidia = styled.div<containerProps>`
  display: flex;

  justify-content: center;

  overflow: hidden;

  width: 180px;
  height: 180px;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  ${props =>
    props.no_shadow &&
    css`
      box-shadow: none;
    `}

  border-radius: 50%;

  @media (max-width: 1199px) {
    width: 150px;
    height: 150px;
  }

  img {
    width: auto;
    height: 100%;

    border-radius: 5px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;

  flex: 1;

  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

interface IButtonProps {
  removeStyle?: boolean;
}

export const Button = styled(ButtonOptions)<IButtonProps>`
  width: 210px;
  height: 32px;

  font-size: 21px;

  ${props =>
    props.removeStyle &&
    css`
      background-color: #9f9e9e;

      &:hover {
        background-color: ${shade(0.2, '#9f9e9e')};
      }
    `}

  @media (max-width:1199px) {
    width: 35px;
    height: 35px;

    span {
      display: none;
    }
  }
`;

export const Input = styled.input`
  display: none;
  flex: 1;
  padding: 0 10px;
  border: none;
  background-color: transparent;

  color: #151515;

  font-size: 22px;
  font-weight: 500;
`;
