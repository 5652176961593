const labelLevelAccess = (level = 1) => {
  let label = 'Colaborador(a)';

  if (level === 1) {
    label = 'Colaborador(a)';
  } else if (level === 2) {
    label = 'Adm da academia';
  } else if (level >= 2) {
    label = 'Adm do sistema';
  }

  return label;
};
export { labelLevelAccess };
