import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import * as yup from 'yup';

import { HeaderModal2 } from '../../../components/HeaderModal2';
import { InputToken } from '../../../components/InputToken';
import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';
import { api } from '../../../services/api';
import { getValidationErrors } from '../../../utils/getValidationErrors';

import { Container, FormContainer, Info, Submit } from './styles';

interface ChangeMailProps {
  avatar_url?: string;
  fullname: string;
}

interface formProps {
  c1: string;
  c2: string;
  c3: string;
  c4: string;
  c5: string;
}

// DNDOY

const ChangeMail2: React.FC<ChangeMailProps> = ({ avatar_url, fullname }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { removeModal, removeAllModals } = useModal();

  const handleSubmit = useCallback(async (data: any) => {
    try {
      const code: string = data.c1 + data.c2 + data.c3 + data.c4 + data.c5;

      formRef.current?.setErrors({});

      const schema = yup.object().shape({
        c1: yup.string().required('Campo obrigatório'),
        c2: yup.string().required('Campo obrigatório'),
        c3: yup.string().required('Campo obrigatório'),
        c4: yup.string().required('Campo obrigatório'),
        c5: yup.string().required('Campo obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      await api.put(
        'users/email/',
        {},
        {
          headers: {
            code: code.toUpperCase(),
          },
        },
      );

      addToast({
        type: 'success',
        title: 'Email alterado com sucesso',
      });

      removeAllModals();
    } catch (err: any) {
      if (err instanceof yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);

        addToast({
          type: 'error',
          title: 'Campos em branco',
          description: 'Um ou mais campos estão em branco.',
        });

        return;
      }

      if (err?.response?.status === 403) {
        addToast({
          type: 'error',
          title: 'Código inválido',
          description: `Por favor, insira o token recebido pelo email corretamente.`,
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Houve um erro na solicitação. Tente novamente mais tarde.',
      });
    }
  }, []);
  return (
    <Container>
      <HeaderModal2
        title="Alterar Senha"
        avatar_user={avatar_url}
        subtitle={fullname}
      />

      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <Info text="Agora, insira abaixo o código recebido no email para confirmação." />

        <InputToken />

        <Info text="Verifique também na caixa de spam" />
        <Submit name="submit" value="Concluir" />
      </FormContainer>
    </Container>
  );
};

export { ChangeMail2 };
