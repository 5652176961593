import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { HeaderModal1 } from '../../components/HeaderModal1';
import { useAuth } from '../../hooks/auth';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import { api } from '../../services/api';

import { Button, Content, Container, Text, ContainerText } from './styles';

const DeleteAccount: React.FC = () => {
  const { removeAllModals } = useModal();
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    api
      .delete(`users`)
      .then(() => {
        signOut();
        navigate('/');
        removeAllModals();
      })
      .catch(err =>
        addToast({
          title: `Erro cod: ${err?.response?.status}`,
          description:
            'Erro ao deletar a sua conta, entre em contato com o gertor do sistema.',
        }),
      );
  }, [addToast, navigate, removeAllModals, signOut]);

  return (
    <Container>
      <HeaderModal1 title="Apagar conta" />
      <Content>
        <ContainerText>
          <Text>
            Ao confirmar, <span>TODOS</span> os dados relacionados a sua conta
            serão apagados.
          </Text>
          <Text>Deseja continuar?</Text>
        </ContainerText>
        <Button
          name="deleteAcount"
          value="Confirmar"
          onClick={() => handleSubmit()}
        />
      </Content>
    </Container>
  );
};

export { DeleteAccount };
