import styled from 'styled-components';
import { ButtonOptions } from '../../components/ButtonOptions';
import { SeparateLabel } from '../../components/SeparateLabel';
import { TextInfo } from '../../components/TextInfo';

export const Container = styled.div`
  padding: 20px;
  min-height: auto;

  h3 {
    font-size: 32px;
    color: #151515;
    margin: 20px 0;
    font-weight: 400;
  }

  @media (max-width: 1199px) {
    padding: 0;
    width: calc(100% - 40px);
    margin: auto;
  }
`;

export const WarningsContainer = styled.div`
  margin: 40px auto;
  max-width: 750px;
`;

export const Info = styled(TextInfo)`
  margin-bottom: 5px;
`;

export const HistoryPaymentsContainer = styled.div`
  width: 750px;
  margin: auto;

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const Separate = styled(SeparateLabel)`
  margin-bottom: 5px;
`;

export const Button = styled(ButtonOptions)`
  margin-bottom: 10px;
  float: right;
`;

export const BillsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 2px 2px 4px #c4c4c4;

  span {
    color: #ff955f;
    text-decoration: underline;
    cursor: pointer;
  }

  th,
  tr,
  td {
    height: 25px;
  }

  tr.header {
    background-color: #c4c4c4;
  }
  th {
    font-weight: 400;
  }
  td {
    font-weight: 400;
  }

  th,
  td {
    text-align: left;
    padding: 2.5px;
    border-bottom: 1px solid #c4c4c4;
  }
`;
