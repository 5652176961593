import React, {
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTransition, animated, useSpring } from 'react-spring';
import { useModal } from '../../hooks/modal';

import { Container, Overlay } from './styles';

interface modalProps {
  id: string;
}

const ModalContainer: React.FC<modalProps> = ({ id, children }) => {
  const { removeModal } = useModal();
  const [open, setOpen] = useState(false);
  const delay = 100;

  const { x } = useSpring({
    from: { x: 0 },
    x: open ? 1 : 0,
    config: { duration: delay },
  });

  useEffect(() => {
    setOpen(true);
    window.scrollTo(0, 0);
  }, []);

  const handlePressOverlay = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      removeModal();
    }, delay);
  }, [removeModal]);

  const handleChildClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <Overlay id="overlay" onClick={handlePressOverlay}>
      <Container
        style={{
          opacity: x.to({ range: [0, 1], output: [0.3, 1] }),
          scale: x.to({
            range: [0, 1],
            output: [0.6, 1],
          }),
        }}
        onClick={handleChildClick}
      >
        {children}
      </Container>
    </Overlay>
  );
};

export { ModalContainer };
