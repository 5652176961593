import { useField } from '@unform/core';
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import searchImg from './assets/search.svg';

import { ContainerInput, Input } from './styles';

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  inputLabel?: string;
  style?: any;
}

const InputSearch: React.FC<inputProps> = ({
  name,
  inputLabel,
  style,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { defaultValue, fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <ContainerInput
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      style={style}
    >
      <img src={searchImg} alt="search img" />
      <Input
        ref={inputRef}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        autoCapitalize="none"
        {...rest}
      />
    </ContainerInput>
  );
};

export { InputSearch };
