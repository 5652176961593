import styled from 'styled-components';

interface bpxProps {
  bg: string;
}

export const BoxStyle = styled.div<bpxProps>`
  background-color: #545454;
  width: 100%;

  background-color: #${props => props.bg};

  @media (max-width: 1199px) {
    background-color: transparent;
  }
`;
