import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import noAvatar from '../../assets/no-avatar.jpg';

import dashboard from './assets/dashboard.svg';
import exerciseIcon from './assets/exercise.svg';
import userIcon from './assets/user.svg';
import gymIcon from './assets/gym.svg';
import profileIcon from './assets/profile.svg';
import logoutIcon from './assets/logout.svg';
import AdminPainelIcon from './assets/admin_painel.svg';

import {
  ButtonMenu,
  Container,
  Divisor,
  DropDown,
  InfoProfile,
  ChildrenContent,
  ButtonMenuMobile,
  Overlay,
} from './styles';

import { ElementMenu } from './components/ElementMenu';
import { Box } from '../Box';
import { Header } from '../Header';

import { useAuth } from '../../hooks/auth';
import { AddUser } from '../../modais/addUser';
import { useModal } from '../../hooks/modal';
import { AddExercise } from '../../modais/addExercise';
import { labelLevelAccess } from '../../utils/labelLeverAccess';
import { Loading } from '../Loading';

interface IPainelProps {
  loading?: boolean;
  children: React.ReactNode;
}

const Painel: React.FC<IPainelProps> = ({ children, loading = false }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [overlayLoading, setOverlayLoading] = useState(loading);

  const { user, signOut } = useAuth();
  const { addModal } = useModal();

  const navigate = useNavigate();

  const handleDropMenu = useCallback(() => {
    setActiveMenu(!activeMenu);
  }, [activeMenu]);

  useEffect(() => {
    setOverlayLoading(loading);
  }, [loading]);

  return (
    <>
      <Box background="545454">
        <Header
          gym_logo={user?.gym?.logo_url}
          gym_name={user?.gym?.name}
          gym_signature={user?.gym?.signature}
          gym_created_at={user?.gym?.created_at}
        />
      </Box>
      <Container>
        <ButtonMenuMobile activeMenu={activeMenu} onClick={handleDropMenu} />
        <DropDown activeMenu={activeMenu}>
          <ButtonMenu activeMenu={activeMenu} onClick={handleDropMenu} />

          <InfoProfile activeMenu={activeMenu}>
            <div className="container-avatar">
              <img src={user?.avatar_url || noAvatar} alt="avatar menu" />
            </div>
            <h2>{user?.fullname}</h2>
            <h3>{labelLevelAccess(user?.level_access)}</h3>
          </InfoProfile>

          <div id="opts">
            <div>
              <ElementMenu
                icon={dashboard}
                label="Dashboard"
                onClick={() => navigate('/dashboard')}
              />

              <ElementMenu
                icon={userIcon}
                label="Usuários"
                onClick={() => navigate('/users')}
                levelAccessUser={user?.level_access}
                // submenu={[
                //   {
                //     label: 'Adicionar usuário',
                //     onClick: () => addModal(<AddUser />),
                //     minLevelAccess: 2,
                //   },
                //   {
                //     label: 'Lista completa',
                //     onClick: () => navigate('/users'),
                //     minLevelAccess: 1,
                //   },
                // ]}
              />

              <ElementMenu
                icon={exerciseIcon}
                label="Exercicios"
                minLevelAccess={1}
                levelAccessUser={user?.level_access}
                onClick={() => navigate('/exercises')}
                // submenu={[
                //   {
                //     label: 'Adicionar exercicio',
                //     onClick: () => addModal(<AddExercise />),
                //     minLevelAccess: 2,
                //   },
                //   {
                //     label: 'Lista completa',
                //     onClick: () => navigate('/exercises'),
                //     minLevelAccess: 1,
                //   },
                // ]}
              />

              <ElementMenu
                icon={gymIcon}
                label="Academia"
                minLevelAccess={1}
                levelAccessUser={user?.level_access}
                submenu={[
                  {
                    label: 'Alterar dados',
                    onClick: () => navigate('/gym/profile'),
                    minLevelAccess: 2,
                  },
                  {
                    label: 'Gerenciar avisos',
                    onClick: () => navigate('/gym/warnings'),
                    minLevelAccess: 1,
                  },
                  {
                    label: 'Planos',
                    onClick: () => navigate('/gym/subscriptions'),
                    minLevelAccess: 2,
                  },
                  {
                    label: 'Pagamentos',
                    onClick: () => navigate('/gym/payments'),
                    minLevelAccess: 2,
                  },
                ]}
              />

              <ElementMenu
                icon={AdminPainelIcon}
                label="Administração"
                minLevelAccess={3}
                levelAccessUser={user?.level_access}
                submenu={[
                  {
                    label: 'Academias',
                    onClick: () => navigate('/admin/gyms'),
                    minLevelAccess: 3,
                  },
                ]}
              />
            </div>

            <div>
              <Divisor />

              <ElementMenu
                icon={profileIcon}
                label="Perfil"
                onClick={() => navigate('/profile')}
              />

              <ElementMenu
                icon={logoutIcon}
                label="Sair"
                onClick={() => {
                  signOut();
                  navigate('/');
                }}
              />

              <span>SUA ACADEMIA</span>
            </div>
          </div>
        </DropDown>
        {activeMenu && <Overlay onClick={() => setActiveMenu(false)} />}
        {overlayLoading && <Loading />}
        <ChildrenContent>{children}</ChildrenContent>
      </Container>
    </>
  );
};

export { Painel };
