import styled from 'styled-components';
import { TextInfo } from '../../components/TextInfo';

export const Container = styled.div`
  padding: 20px;
  min-height: 100vh;

  @media (max-width: 1199px) {
    padding: 0;
    width: calc(100% - 40px);
    margin: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 20px 0;

  h3 {
    font-size: 32px;
    color: #151515;
    margin-bottom: 10px;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  row-gap: 10px;

  margin-top: 15px;
`;

export const ContainerWarnings = styled.div`
  width: 350px;
  height: 150px;
  margin: auto;
  margin-top: 100px;
  display: flex;
  flex-direction: column;

  border: 1px solid #e6e6f2;
  border-left: none;
  border-right: none;

  box-shadow: 5px 5px 10px #e6e6f2;

  @media (max-width: 1199px) {
    padding: 0;
    width: 100%;
    margin: auto;
    margin-top: 40px;
  }
`;

export const TitleContainerWarnings = styled.div`
  width: 90%;
  height: 25px;

  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 10px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  span {
    font-size: 15px;
    font-weight: 500;
  }
`;

export const ScrollWarnings = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 0 10px;

  overflow-x: scroll;
`;

export const NoDataContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
