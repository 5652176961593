import React, { HTMLAttributes, useEffect, useState } from 'react';
import { addDays, getTime, getDate, compareAsc, getDay } from 'date-fns';

import noMidia from '../../assets/no-midia.png';
import LogoApp from '../../assets/logo.svg';

import { Content, Container, ContainerGym, ContainerLogoApp } from './styles';
import { useAuth } from '../../hooks/auth';

interface IHeaderProps extends HTMLAttributes<HTMLDivElement> {
  gym_name?: string;
  gym_logo?: string;
  gym_signature?: string;
  gym_created_at?: Date;
}

const Header: React.FC<IHeaderProps> = ({
  gym_name,
  gym_logo,
  gym_signature,
  gym_created_at,
  ...rest
}) => {
  const maxDaysTestPeriod = 15;
  const now = getTime(new Date());
  const maxTestPeriodDate = getTime(
    addDays(getTime(new Date(gym_created_at || now)), maxDaysTestPeriod),
  );

  return (
    <Container {...rest}>
      <Content>
        <ContainerGym>
          <div className="container-logo-gym">
            <img src={gym_logo || noMidia} alt="logo gym" />
          </div>
          <div className="container-name-gym">
            <h2>{gym_name}</h2>
            <h3>
              {gym_signature === 'test'
                ? `Período de teste: Expira em ${getDate(
                    maxTestPeriodDate - now,
                  )} dias`
                : `Plano ${gym_signature?.toUpperCase()}`}
            </h3>
          </div>
        </ContainerGym>
        <ContainerLogoApp>
          <h2>
            SUA
            <br /> ACADEMIA
          </h2>
          <img src={LogoApp} alt="logo SUA ACADEMIA" />
        </ContainerLogoApp>
      </Content>
    </Container>
  );
};

export { Header };
