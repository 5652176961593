import { FormHandles, useField } from '@unform/core';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { StylesConfig, Props } from 'react-select';
import { CSSProperties } from 'styled-components';

import errorImg from './assets/error.svg';

import { SelectComponent, ContainerInput, ContainerError } from './styles';

export interface optionProps {
  value: string | number;
  label: string;
  object?: any;
}

interface inputProps extends Props {
  name: string;
  options?: optionProps[];
  label?: string;
  isFlex1Mobile?: boolean;
  heightMobile?: number;
  clearValueChange?: boolean;
  refImperative?: any;
}

const Select: React.FC<inputProps> = ({
  name,
  options,
  label,
  children,
  isFlex1Mobile = false,
  heightMobile,
  clearValueChange = false,
  refImperative,
  ...rest
}) => {
  const selectRef = useRef<any>(null);
  const [filled, setFilled] = useState(false);
  const customControlStyles: CSSProperties = {
    color: '#f5f5f5',
    outline: 'none',
    borderRadius: 15,
    boxShadow: 'none',

    border: 0,
  };

  let propsMobile;

  const selectStyle: StylesConfig = {
    control: (provided, state) => ({
      ...provided,
      ...customControlStyles,
      borderColor: state.isFocused ? '#f59000' : 'transparent',

      minWidth: 200,

      '&:focus': {
        borderColor: '#ff9000',
      },
      '&:active': {
        borderColor: '#ff9000',
      },

      '@media (max-width: 1199px)': {
        minHeight: heightMobile || 30,
        maxHeight: heightMobile || 30,

        fontSize: 18,
      },
    }),

    option: (provided, state) => ({
      ...provided,

      color: state.isSelected ? '#f5f5f5' : '#151515',
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: state.isSelected
        ? '#ff9000'
        : state.isFocused
        ? '#ff900080'
        : undefined,

      ':active': {
        backgroundColor: state.isSelected ? '#ff9000' : '#ff900080',
      },
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,

      '@media (max-width: 1199px)': {
        minHeight: heightMobile || 30,
        maxHeight: heightMobile || 30,

        fontSize: 18,
      },
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const { defaultValue, fieldName, error, registerField } = useField(name);

  useImperativeHandle(refImperative, () => ({
    clear() {
      selectRef.current.clearValue();
    },
  }));

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        // if (rest.isMulti) {
        //   if (!ref.state.value) {
        //     return [];
        //   }
        //   return ref.state.value.map((option: inputProps) => option.value);
        // }
        // if (!ref.state.value) {
        //   return 'teste';
        // }

        if (!ref.props.value) {
          return [];
        }
        // console.log(ref.props.value);
        return ref.props.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleSetFilled = useCallback(() => {
    if (clearValueChange) {
      selectRef.current.clearValue();
    } else {
      setFilled(!!selectRef.current?.props?.value?.value || false);
    }
  }, []);

  return (
    <ContainerInput isFlex1Mobile={isFlex1Mobile}>
      {label && <span>{label}</span>}
      <SelectComponent
        isErrored={!!error}
        isFilled={filled}
        styles={selectStyle}
        defaultValue={defaultValue}
        ref={selectRef}
        options={options}
        classNamePrefix="react-select"
        onMenuClose={handleSetFilled}
        onBlur={handleSetFilled}
        // onChange={handleSelect}
        {...rest}
      />
      {error && (
        <ContainerError>
          <img src={errorImg} alt="img error" />
          <span>{error}</span>
        </ContainerError>
      )}
    </ContainerInput>
  );
};

export { Select };
