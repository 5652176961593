import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 13px;
    color: #545454;
    margin-right: 5px;
  }
  div {
    flex: 1;
    height: 0.5px;
    background-color: #545454;
  }
`;
