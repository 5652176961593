import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { api } from '../services/api';

export interface IUser {
  id: string;
  fullname: string;
  nickname: string;
  username: string;
  email: string;
  phone: string | undefined;
  birthday: Date | undefined;
  gym_id: string;
  avatar: string | undefined;
  avatar_url: string | undefined;
  level_access: number;
  is_active: boolean;
  created_at: Date;
  updated_at: Date;
  gym: {
    id: string;
    name: string;
    domain: string;
    logo: string | undefined;
    logo_url: string | undefined;
    phone: string | undefined;
    address: string | undefined;
    description: string | undefined;
    signature: string;
    is_active: boolean;
    created_at: Date;
    updated_at: Date;
    training_type?: 'WEEKLY' | 'ABC';
  };
}
interface SignInCredentials {
  user: IUser;
  token: string;
}

interface AuthContextData {
  user: IUser;
  token: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): Promise<void>;
  loading: boolean;
  setLoading(set: boolean): void;
  loadingStoragedData(): Promise<void>;
}

interface AuthState {
  token: string;
  user: IUser;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>({} as AuthState);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<IUser>({} as IUser);
  const [userToken, setUserToken] = useState<string>('');

  const loadingStoragedData = useCallback(async (): Promise<void> => {
    const token = localStorage.getItem('@SuaAcademia:token');
    const user = localStorage.getItem('@SuaAcademia:user');

    if (token && user) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      setData({ token, user: JSON.parse(user) });
    }
    setLoading(false);
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem('@SuaAcademia:token');
    localStorage.removeItem('@SuaAcademia:user');

    api.defaults.headers.common.Authorization = '';

    setData({} as AuthState);
  }, []);

  const signIn = useCallback(async ({ user, token }) => {
    setLoading(true);

    localStorage.setItem('@SuaAcademia:token', token);
    localStorage.setItem('@SuaAcademia:user', JSON.stringify(user));

    setLoading(false);

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    setData({ token, user });

    setLoading(false);
  }, []);

  const updateUser = useCallback(
    async (user: IUser) => {
      localStorage.setItem('@SuaAcademia:user', JSON.stringify(user));
      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  useEffect(() => {
    loadingStoragedData();
  }, []);

  useEffect(() => {
    setUserData(data.user);
    setUserToken(data.token);
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user as IUser,
        token: data.token,
        loadingStoragedData,
        signIn,
        signOut,
        updateUser,
        loading,
        setLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
