import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  min-width: 35px;
  min-height: 35px;

  width: 35px;
  height: 35px;

  display: flex;

  justify-content: center;
  align-items: center;

  border: none;

  background-color: #ff955f;

  color: #f5f5f5;
  font-size: 32px;

  border-radius: 50%;

  transition: background-color 0.2s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${shade(0.2, '#ff955f')};
  }

  img {
    min-width: 20px;
    min-height: 20px;

    max-width: 20px;
    max-height: 20px;
  }
`;
