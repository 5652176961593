import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface IMenuProps {
  activeMenu: boolean;
}

export const Container = styled.ul`
  width: 90%;
`;

export interface IShowMenu {
  show: boolean;
}

export const OptMenu = styled.li<IShowMenu>`
  list-style: none;
  background-color: #b7b7b7;
  width: 100%;
  height: 30px;
  margin-top: 8px;

  position: relative;

  display: flex;
  align-items: center;
  transition: background-color 0.5s;

  cursor: default;

  overflow-x: hidden;

  &:hover {
    background-color: ${shade(0.2, '#b7b7b7')};
  }

  img {
    width: 25px;
    height: 25px;

    margin: 0 5px;
  }

  .chevron {
    position: absolute;
    right: 0;
  }

  span {
    font-size: 18px;
    font-weight: 500;
  }
`;

interface ISubmenuProps {
  activeSubmenu: boolean;
}

export const Submenu = styled.ul<ISubmenuProps>`
  ${props =>
    props.activeSubmenu
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  list-style: none;
`;

export const OptSubmenu = styled.li<IShowMenu>`
  margin: 0;

  display: flex;

  align-items: center;
  width: 100%;
  min-height: 30px;
  background-color: #9f9e9e;
  list-style: none;

  overflow-x: hidden;

  transition: background-color 0.5s;

  cursor: pointer;

  &:hover {
    background-color: ${shade(0.2, '#9F9E9E')};
  }

  span {
    font-size: 16px;
    margin-left: 10px;
  }
  ${props =>
    props.show
      ? css`display; flex;`
      : css`
          display: none;
        `}
`;
