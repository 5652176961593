import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import * as yup from 'yup';

import { HeaderModal2 } from '../../../components/HeaderModal2';
import { InputText } from '../../../components/InputText';
import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';
import { api } from '../../../services/api';
import { getValidationErrors } from '../../../utils/getValidationErrors';
import { ChangeMail2 } from '../changeMail2';

import { Container, FormContainer, Info, Submit } from './styles';

interface ChangeMailProps {
  avatar_url?: string;
  fullname: string;
  email: string;
}

interface formProps {
  newEmail: string;
}

const ChangeMail1: React.FC<ChangeMailProps> = ({
  avatar_url,
  fullname,
  email,
}) => {
  const { addModal, removeModal } = useModal();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: formProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          newEmail: yup
            .string()
            .email('Insira um email válido')
            .required('Campo obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('users/email/request-change', {
          new_email: data.newEmail,
        });

        addModal(<ChangeMail2 avatar_url={avatar_url} fullname={fullname} />);
      } catch (err: any) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        if (err?.response?.status === 403) {
          addToast({
            type: 'error',
            title: 'Email em uso',
            description: `O email ${data.newEmail} já está em uso no nosso sistema.`,
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Houve um erro na solicitação. Tente novamente mais tarde.',
        });
      }
    },
    [addModal, addToast, avatar_url, fullname],
  );

  return (
    <Container>
      <HeaderModal2
        title="Alterar Email"
        avatar_user={avatar_url}
        subtitle={fullname}
      />
      <Info text="Insira o novo email. Fique atento, um token será enviado ao seu novo email." />
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <InputText label="Novo email" name="newEmail" />

        <Submit name="submit" value="Concluir" />
      </FormContainer>
    </Container>
  );
};

export { ChangeMail1 };
