import React, { ButtonHTMLAttributes } from 'react';

import { Container, Title, Data } from './styles';

interface ICardProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  data: string | number;
}

const Card: React.FC<ICardProps> = ({ title, data, ...rest }) => {
  return (
    <Container {...rest}>
      <Title>{title}</Title>
      <Data>{data}</Data>
    </Container>
  );
};

export { Card };
