import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CardExercise } from '../../components/CardExercise';
import { HeaderModal2 } from '../../components/HeaderModal2';
import { InputSearch } from '../../components/InputSearch';
import { Select } from '../../components/Select';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import { muscleGroupsSearch } from '../../objects/select';
import { api } from '../../services/api';
import { formatDayOfWeek } from '../../utils/formatDayOfWeek';

import { Container, Content, FormContainer, Submit } from './styles';
import {
  ExercisesService,
  IExercise,
  IMuscleGroup,
} from '../../services/ExercisesService';
import { TrainingsService } from '../../services/TrainingsService';

interface IChangeTrainingUserProps {
  user_avatar?: string;
  training_number: number;
  user_id: string;
  exercises_already_registered_of_day?: IExercise[];
}

const AddExercisesInTraining: React.FC<IChangeTrainingUserProps> = ({
  user_avatar,
  training_number,
  user_id,
  exercises_already_registered_of_day,
}) => {
  const trainingsService = new TrainingsService();
  const exercisesService = new ExercisesService();

  const formRef = useRef<FormHandles>(null);
  const { removeAllModals } = useModal();

  const [allExercises, setAllExercises] = useState<IExercise[]>([]);
  const [exercises, setExercises] = useState<IExercise[]>([]);
  const [muscleGroups, setMuscleGroups] = useState<IMuscleGroup[]>([]);
  const [filter, setFilter] = useState<IExercise[]>([]);
  const [selectValue, setSelectValue] = useState('');

  const { addToast } = useToast();

  const getMuscleGroups = useCallback(() => {
    exercisesService
      .getMuscleGroups()
      .then(response => {
        setMuscleGroups([
          { id: '', name: 'Todos', is_main: false },
          ...response.data,
        ]);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na busca dos grupos musculares.',
          description:
            'Caso o proplema persista, entre em contato com o administrador do sistema',
        });
      });
  }, [addToast, exercisesService]);

  const handleFilter = useCallback(() => {
    setFilter(exercises);
    const input = formRef.current?.getFieldValue('name') as string;

    const values = exercises.filter(current => {
      if (selectValue) {
        if (
          current.muscle_groups.some(
            group =>
              group.id === selectValue &&
              current.name.toLowerCase().indexOf(input.toLowerCase()) > -1,
          )
        ) {
          return true;
        }
      } else if (current.name.toLowerCase().indexOf(input.toLowerCase()) > -1) {
        return true;
      }
      return false;
    });

    setFilter(values);
  }, [exercises, selectValue]);

  const handleChangeSelectForm = useCallback((e: any) => {
    setSelectValue(e.value);
  }, []);

  const handleAddExercise = useCallback(
    async (exercise: IExercise) => {
      try {
        setFilter(filter.filter(e => e.id !== exercise.id));

        exercises_already_registered_of_day?.push(exercise);

        await trainingsService.createTraining({
          user_id,
          exercise_id: exercise.id,
          day_of_week: Number(training_number),
        });

        addToast({
          type: 'success',
          title: 'Exercicio adicionado!',
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro no registro',
          description:
            'Tente novamente. Caso o problema persista, entre em contato com a administração do sistema.',
        });
      }
    },
    [
      filter,
      exercises_already_registered_of_day,
      user_id,
      training_number,
      addToast,
    ],
  );

  useEffect(() => {
    if (exercises_already_registered_of_day?.length) {
      setExercises(
        allExercises.filter(
          exercise =>
            !exercises_already_registered_of_day.find(
              current => current.id === exercise.id,
            ),
        ),
      );
      setFilter(
        allExercises.filter(
          exercise =>
            !exercises_already_registered_of_day.find(
              current => current.id === exercise.id,
            ),
        ),
      );
    } else {
      setExercises(allExercises);
      setFilter(allExercises);
    }
  }, [allExercises, exercises_already_registered_of_day]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter, selectValue]);

  useEffect(() => {
    exercisesService
      .getExercisesOfGym()
      .then(response => {
        setAllExercises(response?.data);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na busca dos exercicios.',
          description: err?.response?.status,
        });
      });

    getMuscleGroups();
  }, []);

  return (
    <Container>
      <HeaderModal2
        avatar_user={user_avatar}
        title="Adição de exercícios"
        subtitle={formatDayOfWeek(training_number).labelABC}
      />

      <FormContainer ref={formRef} onSubmit={() => removeAllModals()}>
        <InputSearch name="name" placeholder="" onChange={handleFilter} />

        <Select
          name="muscle_groups"
          options={muscleGroups.map(group => ({
            label: group.name,
            value: group.id,
          }))}
          placeholder="Grupo muscular"
          onChange={handleChangeSelectForm}
          blurInputOnSelect
        />
      </FormContainer>
      <Content>
        {filter?.map(exercise => (
          <CardExercise
            key={exercise.id}
            id={exercise.id}
            exerciseName={exercise.name}
            muscleGroups={exercise.muscle_groups}
            thumbnail_url={exercise.thumbnail_url}
            disableOptHover
            onClick={() => handleAddExercise(exercise)}
          />
        ))}
      </Content>

      <Submit
        name="submit"
        value="Concluir"
        onClick={() => formRef.current?.submitForm()}
      />
    </Container>
  );
};

export { AddExercisesInTraining };
