import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.input`
  width: 100%;
  height: 55px;

  background-color: #ff955f;

  color: #f5f5f5;
  font-size: 32px;

  border-radius: 15px;
  border: none;

  transition: background-color 0.2s;

  &:hover {
    background-color: ${shade(0.2, '#ff955f')};
  }

  &:disabled {
    background-color: ${shade(0.5, '#ff955f')};
  }
`;
