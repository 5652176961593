import styled, { css } from 'styled-components';
import LottieComponent from 'react-lottie';

interface containerProps {
  no_shadow: boolean;
}

export const Container = styled.div<containerProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 99;

  width: 100%;
  height: 100%;

  background-color: #15151580;

  ${props =>
    props.no_shadow &&
    css`
      background-color: transparent;
      transform: scale(0.4);
      position: relative;
    `}

  @media (max-width: 1199px) {
    position: fixed;

    height: 100vh;
    width: 100vw;

    top: 0;
    left: 0;

    ${props =>
      props.no_shadow &&
      css`
        position: relative;
        height: initial;
        width: initial;
      `}
  }
`;

export const Lottie = styled(LottieComponent)`
  z-index: 100;
`;

export const LogoImg = styled.img<containerProps>`
  height: 120px;
  width: 120px;
  border-radius: 50%;

  box-shadow: 0px 0px 10px #151515;

  ${props =>
    props.no_shadow &&
    css`
      box-shadow: none;
    `}

  @media (max-width: 1199px) {
    /* width: 10%; */
  }

  @keyframes jump {
    0% {
      transform: translateY(0px);
    }
    60% {
      transform: translateY(-10px) scale(105%);
    }
    100% {
      transform: translateY(0px);
    }
  }

  animation: jump 1s infinite;
`;
