/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import { ButtonConfirm } from '../../components/ButtonConfirm';
import { Painel } from '../../components/Painel';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import { ModalPayments } from '../../modais/payments';
import { api } from '../../services/api';

import {
  BillsTable,
  Button,
  Container,
  HistoryPaymentsContainer,
  Info,
  Separate,
  WarningsContainer,
} from './styles';

interface IBillProps {
  id: string;
  gym_id: string;
  subscription_id: string;
  subscription_data_id: string;
  price: number;
  status: string;
  paid_at: string;
  due_at: string;
}

const Payments: React.FC = () => {
  const [bills, setBills] = useState<IBillProps[]>([]);
  const [loading, setLoading] = useState(true);

  const { addModal } = useModal();
  const { addToast } = useToast();

  const getBills = useCallback(() => {
    api
      .get('payments')
      .then(response => {
        setBills(response.data);
      })
      .catch(err => console.log(err?.response?.status))
      .finally(() => {
        setLoading(false);
      });

    addToast({
      type: 'info',
      title: 'Lista de pagamentos atualizada.',
    });
  }, []);

  useEffect(() => {
    getBills();
  }, []);

  return (
    <Painel loading={loading}>
      <Container>
        <h3>Histórico de pagamentos</h3>

        <WarningsContainer>
          <Info
            text="Nessa área você pode visualizar de forma simples e clara o resumo
            das suas faturas dos últimos 12 meses."
          />
          <Info text="Caso haja alguma fatura com status “Em aberto”, basta clicar no link em destaque para abrir os meios de pagamento." />
          <Info text="Ao realizar o pagamento, aguarde alguns instantes para confirmação abaixo." />
        </WarningsContainer>

        <HistoryPaymentsContainer>
          <Separate label="Informações gerais" />

          <Button name="refresh" value="Atualizar" onClick={() => getBills()} />
          <BillsTable>
            <tr className="header">
              <th>Data</th>
              <th>Vencimento</th>
              <th>Valor</th>
              <th>Status</th>
            </tr>
            {bills.map(bill => {
              const due_at = new Date(bill.due_at);
              return (
                <tr key={bill.id}>
                  <td>{`${due_at.getMonth() + 1}/${due_at.getFullYear()}`}</td>
                  <td>{`${due_at.getDate()}/${
                    due_at.getMonth() + 1
                  }/${due_at.getFullYear()}`}</td>
                  <td>R$ {bill.price}</td>
                  <td>
                    {bill.status === 'approved' ? (
                      'Paga'
                    ) : (
                      <span
                        onClick={() =>
                          addModal(
                            <ModalPayments
                              bill_id={bill.id}
                              month={`${
                                due_at.getMonth() + 1
                              }/${due_at.getFullYear()}`}
                            />,
                          )
                        }
                      >
                        Em aberto
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </BillsTable>
        </HistoryPaymentsContainer>
      </Container>
    </Painel>
  );
};

export { Payments };
