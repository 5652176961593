import React from 'react';
import { SelectPaymentMethod } from './selectPaymentMethod';

interface modalPaymentsProps {
  bill_id: string;
  month: string;
}

const ModalPayments: React.FC<modalPaymentsProps> = ({ bill_id, month }) => {
  return <SelectPaymentMethod bill_id={bill_id} month={month} />;
};

export { ModalPayments };
