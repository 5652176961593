import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import * as Yup from 'yup';

import { HeaderModal1 } from '../../components/HeaderModal1';
import { InputText } from '../../components/InputText';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';

import { api } from '../../services/api';
import { getValidationErrors } from '../../utils/getValidationErrors';

import {
  Container,
  FormContainer,
  Info,
  Submit,
  InputTextArea,
} from './styles';

interface IFormProps {
  title: string;
  content: string;
}

const AddWarning: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { removeModal } = useModal();

  const handleSubmit = useCallback(
    async (data: IFormProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Campo obrigatório'),
          content: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('news', {
          title: data.title,
          content: data.content,
        });

        addToast({
          type: 'success',
          title: 'Aviso criado com sucesso',
        });

        addToast({
          type: 'info',
          title:
            'Pode ser necessário que os alunos saiam e entrem no app para exibir os novos alertas',
        });

        removeModal();
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description:
            'Tente novamente mais tarde. Caso o erro persista, entre em contato com a administração do sistema.',
        });
      }
    },
    [addToast],
  );

  return (
    <Container>
      <HeaderModal1 title="Novo aviso" />
      <Info text="Insira abaixo as informações para criar o aviso" />

      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <InputText label="Titulo" name="title" />
        <InputTextArea label="Conteúdo" name="content" rows={3} />

        <Submit name="submit" value="Adicionar" />
      </FormContainer>
    </Container>
  );
};

export { AddWarning };
