/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { InputHTMLAttributes } from 'react';

import { Container } from './styles';

import addImage from './assets/add.svg';

interface buttonProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
  image?: string;
}

const ButtonCircle: React.FC<buttonProps> = ({
  name,
  value,
  image,
  ...rest
}) => {
  if (image === 'add') {
    image = addImage;
  }

  return (
    <Container type="submit" {...rest}>
      {image && <img src={image} alt="image button" />}
      <span>{value}</span>
    </Container>
  );
};

export { ButtonCircle };
