import styled from 'styled-components';
import { CardSubscription as Card } from '../../components/CardSubscription';
import { TextInfo } from '../../components/TextInfo';

export const Container = styled.div`
  padding: 20px;
  min-height: 100vh;

  h3 {
    font-size: 32px;
    color: #151515;
    margin: 20px 0;
    font-weight: 400;
  }

  @media (max-width: 1199px) {
    padding: 0;
    width: calc(100% - 40px);
    margin: auto;
  }
`;

export const ContainerWarnings = styled.div`
  margin: auto;
  min-width: auto;

  display: flex;
  flex-direction: column;
  row-gap: 10px;

  margin: 60px 0;

  @media (max-width: 1199px) {
    margin: 30px 0;
  }
`;

export const Info = styled(TextInfo)`
  margin-bottom: 5px;
`;

export const ContainerSubscriptions = styled.div`
  display: flex;

  align-items: center;
  flex-direction: column;
`;

export const CardSubscription = styled(Card)`
  margin-bottom: 10px;
  width: 650px;

  @media (max-width: 1199px) {
    width: 100%;
  }
`;
