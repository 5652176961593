import { Form } from '@unform/web';
import styled from 'styled-components';
import { ButtonConfirm } from '../../components/ButtonConfirm';
import { InputText } from '../../components/InputText';
import { TextInfo } from '../../components/TextInfo';

export const Container = styled.div`
  width: 100%;

  background-color: #f5f5f5;
`;

export const Info = styled(TextInfo)`
  margin: 10px 0;
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;

  padding: 0 20px;
  margin-top: 10px;

  & > * {
    margin-bottom: 10px;
  }
`;

export const Input = styled(InputText)`
  @media (max-width: 1199px) {
    width: 100%;
    background-color: yellow;
  }
`;

export const ContainerInline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  grid-gap: 5px;

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Submit = styled(ButtonConfirm)`
  align-self: center;
  margin: 20px 0;
`;
