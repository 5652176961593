import { useField } from '@unform/core';
import React, {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import errorImg from './assets/error.svg';

import { Container, ContainerInput, Input } from './styles';

interface inputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
}

const InputTextArea: React.FC<inputProps> = ({
  name,
  label,
  property,
  style,
  ...rest
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { defaultValue, fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <Container property={property} style={style}>
      {label && <span>{label}</span>}
      <ContainerInput
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      >
        <Input
          ref={inputRef}
          defaultValue={defaultValue}
          autoCapitalize="none"
          {...rest}
        />
      </ContainerInput>
      {/* {error && <img src={errorImg} alt="img error" />} */}
    </Container>
  );
};

export { InputTextArea };
