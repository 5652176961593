import React, { HTMLAttributes } from 'react';

import alertImg from './assets/alert.svg';
import halterImg from './assets/halter.svg';
import usersImg from './assets/users.svg';

import {
  Button,
  Container,
  ContainerInfo,
  ContainerPrice,
  ContainerTitle,
  Info,
  Price,
  Title,
} from './styles';

interface cardProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  news: number;
  exercises: number;
  users: number;
  price: number;
  currentSubscription?: boolean;
  functionButton?: any;
}

const CardSubscription: React.FC<cardProps> = ({
  title,
  news,
  exercises,
  users,
  price,
  currentSubscription = false,
  functionButton,
  ...rest
}) => {
  return (
    <Container currentSubscription={currentSubscription} {...rest}>
      <ContainerTitle>
        <Title>{title}</Title>
      </ContainerTitle>

      <ContainerInfo>
        <Info>
          <div>
            <img src={alertImg} alt="alert img" />
            <span>Alertas: </span>
          </div>
          <span>{news}</span>
        </Info>
        <Info>
          <div>
            <img src={halterImg} alt="alert img" />
            <span>Exercícios: </span>
          </div>
          <span>{exercises}</span>
        </Info>
        <Info>
          <div>
            <img src={usersImg} alt="alert img" />
            <span>Usuários: </span>
          </div>
          <span>{users}</span>
        </Info>
      </ContainerInfo>

      <ContainerPrice>
        <Price>
          <span>R$</span>
          <span className="price">{price.toFixed(2)}</span>
          <span>/mês</span>
        </Price>
        <Button name="contract" value="Contratar" onClick={functionButton} />
      </ContainerPrice>
    </Container>
  );
};

export { CardSubscription };
