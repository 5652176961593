/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IExercise } from './ExercisesService';
import { api } from './api';

interface ICreateTraining {
  user_id: string;
  exercise_id: string;
  day_of_week: number;
}
interface IGetTrainings {
  username?: string;
  day?: string;
}

export interface ITraining {
  id: string;
  exercise: IExercise;
}

interface IResponseDaysOfWeek {
  training_type: 'WEEKLY' | 'ABC';
  groups: IGroup[];
}

interface IGroup {
  label: string;
  index: number;
  muscle_groups?: string[];
}

class TrainingsService {
  private apiService = api;

  public async getTrainingsByUsernameAndDay({ username, day }: IGetTrainings) {
    return this.apiService.get<ITraining[]>(`trainings/${username}/${day}`);
  }

  public async getTrainingsOfWeek(username: string) {
    return this.apiService.get<IResponseDaysOfWeek>(
      `trainings/${username}/week`,
    );
  }

  public async createTraining({
    user_id,
    exercise_id,
    day_of_week,
  }: ICreateTraining) {
    return this.apiService.post<ITraining>('trainings', {
      user_id,
      exercise_id,
      day_of_week,
    });
  }
}

export { TrainingsService };
