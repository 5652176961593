export const muscleGroups = [
  { value: 'Ombro', label: 'Ombro' },
  { value: 'Peito', label: 'Peito' },
  { value: 'Biceps', label: 'Biceps' },
  { value: 'Triceps', label: 'Triceps' },
  { value: 'Costas', label: 'Costas' },
  { value: 'Pernas', label: 'Pernas' },
  { value: 'Abdome', label: 'Abdome' },
  { value: 'Cardio', label: 'Cardio' },
  { value: 'Outro', label: 'Outro' },
];

export const muscleGroupsSearch = [
  { value: '', label: 'Todos' },
  { value: 'Ombro', label: 'Ombro' },
  { value: 'Peito', label: 'Peito' },
  { value: 'Biceps', label: 'Biceps' },
  { value: 'Triceps', label: 'Triceps' },
  { value: 'Costas', label: 'Costas' },
  { value: 'Pernas', label: 'Pernas' },
  { value: 'Cardio', label: 'Cardio' },
  { value: 'Abdome', label: 'Abdome' },
];

export const levelAccess = [
  { value: 0, label: 'Aluno' },
  { value: 1, label: 'Funcionário(a)' },
  { value: 2, label: 'Administrador(a)' },
];

export const dueDates = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
];
