import React from 'react';
import { Painel } from '../../components/Painel';

import { Container } from './styles';

const HelloWorld: React.FC = () => {
  return (
    <Painel>
      <Container>
        <h2>Hello world</h2>
      </Container>
    </Painel>
  );
};

export { HelloWorld };
