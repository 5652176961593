import { FormHandles } from '@unform/core';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';

import { HeaderModal1 } from '../../components/HeaderModal1';
import { InputText } from '../../components/InputText';
import { useToast } from '../../hooks/toast';
import { api } from '../../services/api';
import { getValidationErrors } from '../../utils/getValidationErrors';

import { Container, FormContainer, Info, Submit } from './styles';

interface IFormProps {
  fullname: string;
  nickname: string;
  email: string;
}

const AddUser: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: IFormProps) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          fullname: Yup.string().required('Campo obrigatório'),
          nickname: Yup.string().required('Campo obrigatório'),
          email: Yup.string()
            .email('Insira um email válido')
            .required('Campo obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('users/invite-user', {
          fullname: data.fullname,
          nickname: data.nickname,
          email: data.email.toLowerCase(),
        });

        addToast({
          type: 'success',
          title: 'Convite enviado',
          description: `Convite enviado com sucesso ao email ${data.email}`,
        });

        setLoading(false);

        formRef.current?.clearField('fullname');
        formRef.current?.clearField('nickname');
        formRef.current?.clearField('email');
      } catch (err: any) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        if (err?.response?.status === 401) {
          addToast({
            type: 'error',
            title: 'Email já castrado',
            description:
              'O email informado ja está cadastrado em nosso sistema.',
          });
          return;
        }
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description:
            'Tente novamente mais tarde. Caso o erro persista, entre em contato com a administração do sistema.',
        });
      }
    },
    [addToast],
  );

  return (
    <Container>
      <HeaderModal1 title="Adicionar usuário" />
      <Info text="Ao cadastrar os dados, um token será enviado ao email do usuário." />
      <Info text="Esse token deve ser inserido no app “Sua academia”, para finalizar o cadastro." />
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <InputText label="Nome completo" name="fullname" />
        <InputText label="Apelido" name="nickname" />
        <InputText label="Email" name="email" />

        <Submit
          name="submit"
          value="Enviar convite"
          loading={loading}
          disabled={loading}
        />
      </FormContainer>
    </Container>
  );
};

export { AddUser };
