/* eslint-disable react-hooks/exhaustive-deps */
import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { HeaderModal1 } from '../../components/HeaderModal1';

import { InputFileExercise } from '../../components/InputFileExercise';
import { InputTextArea } from '../../components/InputTextArea';
import { Select, optionProps } from '../../components/Select';
import { SeparateLabel } from '../../components/SeparateLabel';
import { useToast } from '../../hooks/toast';
import { getValidationErrors } from '../../utils/getValidationErrors';

import {
  ButtonImg,
  Container,
  ContainerCardsMuscleGroups,
  ContainerImg,
  ContainerInline,
  FormContainer,
  Info,
  Input,
  Submit,
} from './styles';
import {
  ExercisesService,
  IExercise,
  IMuscleGroup,
} from '../../services/ExercisesService';
import { CardMuscleGroup } from '../../components/CardMuscleGroup';
import { useModal } from '../../hooks/modal';

interface IFormProps {
  name: string;
  muscle_groups: IMuscleGroup[];
  description: string;
}

const ChangeExercise: React.FC<IExercise> = ({
  id,
  name,
  muscle_groups,
  description,
  thumbnail_url,
  video_url,
}) => {
  const exercisesService = new ExercisesService();

  const formRef = useRef<FormHandles>(null);
  const [exercise, setExercise] = useState<IExercise>();
  const [muscleGroups, setMuscleGroups] = useState<IMuscleGroup[]>([]);
  const [listMuscleGroups, setListMuscleGroups] = useState<IMuscleGroup[]>([]);
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();
  const { removeModal } = useModal();

  const handleSubmit = useCallback(
    async (data: IFormProps) => {
      try {
        setLoading(true);

        if (listMuscleGroups.length < 1) {
          addToast({
            type: 'error',
            title: 'Pelo menos um grupo muscular deve ser cadastrado.',
          });
          setLoading(false);
          return;
        }

        if (!listMuscleGroups.some(m => m.is_main)) {
          addToast({
            type: 'error',
            title: 'Pelo menos um grupo muscular deve ser o principal.',
          });
          setLoading(false);
          return;
        }

        if (listMuscleGroups.filter(m => m.is_main).length > 1) {
          addToast({
            type: 'error',
            title: 'Apenas um grupo muscular pode ser o principal.',
          });
          setLoading(false);
          return;
        }

        formRef.current?.setErrors({});

        const schema = Yup.object({
          name: Yup.string().required('Campo orbigatório'),
          description: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await exercisesService.updateExercise({
          id,
          name: data.name,
          muscle_groups: listMuscleGroups,
          description: data.description || undefined,
        });

        setExercise(response.data);

        addToast({ type: 'success', title: 'Exercicio alterado com sucesso.' });

        setLoading(false);
        removeModal();
      } catch (err: any) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        if (err?.response?.status === 403) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para executar essa ação.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description:
            'Houve um erro na tentativa de atualização do exercicio. Tente novamente mais tarde.',
        });
      }
    },
    [addToast, id, listMuscleGroups],
  );

  const getMuscleGroups = useCallback(() => {
    exercisesService
      .getMuscleGroups()
      .then(response => {
        setMuscleGroups(response.data);
      })
      .catch(err => {
        addToast({
          type: 'error',
          title: 'Erro na busca dos grupos musculares.',
          description:
            'Caso o proplema persista, entre em contato com o administrador do sistema',
        });
      });
  }, [addToast, exercisesService]);

  const addItemMuscleGroup = useCallback(
    (selected: optionProps) => {
      if (!listMuscleGroups.find(group => group.id === selected.value)) {
        const groupfinded = muscleGroups.find(
          finded => finded.id === selected.value,
        );
        if (groupfinded) {
          setListMuscleGroups(element => [...element, groupfinded]);
        }
      }
    },
    [listMuscleGroups, muscleGroups],
  );

  const handleChangeSelect = useCallback(
    (e: any) => {
      const option: optionProps = e;
      if (option) {
        addItemMuscleGroup(option);
      }
    },
    [addItemMuscleGroup],
  );

  useEffect(() => {
    setExercise({
      name,
      id,
      muscle_groups,
      description,
      thumbnail_url,
      video_url,
    });

    setListMuscleGroups(muscle_groups);

    getMuscleGroups();
  }, []);

  return (
    <Container>
      <HeaderModal1 title="Editar exercício" />

      <FormContainer
        ref={formRef}
        initialData={exercise}
        onSubmit={handleSubmit}
      >
        <InputFileExercise
          name="midia"
          defaultFile={thumbnail_url}
          video_url={video_url}
          id={id}
        />
        <Info text="Fotos ou vídeos de até 5mb." />
        <SeparateLabel label="Informações gerais" />

        <ContainerInline>
          <Input name="name" label="Nome" placeholder="" />
          <Select
            clearValueChange
            name="muscle_groups"
            label="Grupo muscular"
            options={muscleGroups.map(group => ({
              label: group.name,
              value: group.id,
            }))}
            onChange={handleChangeSelect}
            placeholder="Grupo muscular"
          />
        </ContainerInline>

        {listMuscleGroups.length > 0 &&
        !listMuscleGroups.find(mg => mg.is_main) ? (
          <Info text="Clique em um grupo muscular para torná-lo principal (usado para ordenação dos exercicios)." />
        ) : (
          <></>
        )}

        <SeparateLabel label="Grupos musculares" />

        <ContainerCardsMuscleGroups>
          {listMuscleGroups.map(group => {
            return (
              <CardMuscleGroup
                key={group.id}
                label={group.name}
                isMain={group.is_main}
                onClickClose={() =>
                  setListMuscleGroups(groups => [
                    ...groups.filter(current => current.id !== group.id),
                  ])
                }
                onClickSetMain={() => {
                  setListMuscleGroups(groups =>
                    groups.map(current => {
                      return { ...current, is_main: false };
                    }),
                  );

                  setListMuscleGroups(groups =>
                    groups.map(current => {
                      if (current.id === group.id) {
                        return { ...current, is_main: !current.is_main };
                      }
                      return current;
                    }),
                  );
                }}
              />
            );
          })}
        </ContainerCardsMuscleGroups>

        <SeparateLabel label="Informações adicionais" />
        <InputTextArea name="description" label="Descrição" placeholder="" />
        <Submit
          name="submit"
          value="Alterar exercício"
          loading={loading}
          disabled={loading}
        />
      </FormContainer>
    </Container>
  );
};

export { ChangeExercise };
