import React, { HtmlHTMLAttributes } from 'react';
import { JsxElement } from 'typescript';

import { Container } from './styles';

type ButtonProps = HtmlHTMLAttributes<HTMLDivElement>;

const ButtonRemove: React.FC<ButtonProps> = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <span>Remover</span>
    </Container>
  );
};

export { ButtonRemove };
