import React, { HtmlHTMLAttributes } from 'react';

import { Container } from './styles';

import info from './assets/info.svg';

interface textInfoProps extends HtmlHTMLAttributes<HTMLDivElement> {
  text: string;
}

const TextInfo: React.FC<textInfoProps> = ({ text, ...rest }) => {
  return (
    <Container {...rest}>
      <img src={info} alt="info" />
      <span>{text}</span>
    </Container>
  );
};

export { TextInfo };
