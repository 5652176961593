import styled from 'styled-components';
import { ButtonConfirm } from '../../../components/ButtonConfirm';
import { ButtonOptions } from '../../../components/ButtonOptions';
import { TextInfo } from '../../../components/TextInfo';

export const Container = styled.div`
  background-color: #f5f5f5;
  padding-bottom: 10px;

  min-height: 500px;
  max-height: 650px;

  max-width: 750px;

  overflow-y: scroll;
`;

export const Content = styled.div`
  width: calc(100% - 40px);
  margin: auto;
`;

export const Title = styled.h2`
  font-size: 30px;
  margin: 15px 0;
  font-weight: 500;
`;

export const ContainerInfo = styled.div`
  margin: 15px 0;
`;

export const Info = styled(TextInfo)`
  span {
    font-size: 13px;
  }
`;

export const SectionQr = styled.div`
  padding-bottom: 10px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1199px) {
    flex-direction: column;
  }
`;

export const ContainerQrCodeImage = styled.div`
  width: 250px;
  height: 250px;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  margin-left: 20px;

  img {
    max-height: 100%;
    width: auto;
  }
`;

export const Instructions = styled.div`
  h3 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
  }

  p {
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 3px;
  }
`;

export const SectionCopyPast = styled.div`
  padding-bottom: 15px;

  border-bottom: 1px solid #15151580;
`;

export const KeyCopyPast = styled.div`
  margin: 10px 0;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px 5px;
  word-break: break-all;
`;

export const ButtonCopy = styled(ButtonOptions)``;

export const ContainerBill = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  padding: 10px 0;
`;

export const LineBill = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;

  & span:last-child {
    text-align: right;
    color: #9b9b9b;
  }

  @media (max-width: 1199px) {
    span {
      font-size: 16px;
    }
  }
`;

export const ButtonFinish = styled(ButtonConfirm)`
  margin: 20px auto;
`;
