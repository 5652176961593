import { api } from './api';

interface IGym {
  id: string;
  name: string;
  domain: string;
  logo: string | undefined;
  logo_url: string | undefined;
  phone: string | undefined;
  address: string | undefined;
  description: string | undefined;
  signature: string;
  is_active: boolean;
  created_at: Date;
  updated_at: Date;
  training_type?: 'WEEKLY' | 'ABC';
}

class GymsService {
  private apiService = api;

  public async getGyms() {
    return this.apiService.get<IGym[]>(`gyms`);
  }

  public async unlock(gym_id: string) {
    return this.apiService.patch<void>(`gyms/unlock`, {
      gym_id,
    });
  }
}

export { GymsService };
export type { IGym };
