import { Form } from '@unform/web';
import styled from 'styled-components';
import { ButtonConfirm } from '../../../components/ButtonConfirm';
import { TextInfo } from '../../../components/TextInfo';

export const Container = styled.div`
  width: 100%;

  background-color: #f5f5f5;
`;

export const Info = styled(TextInfo)`
  margin: 30px 0;

  @media (max-width: 1199px) {
    max-width: calc(100% - 40px);
    margin: 20px auto;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;

  padding: 10px 20px;
`;

export const Submit = styled(ButtonConfirm)`
  align-self: center;
  margin: 30px 0;
`;
