import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

import chevron from './assets/chevron.svg';

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  value: string;
  arrowRight?: boolean;
}

const ButtonSecurity: React.FC<buttonProps> = ({
  name,
  value,
  arrowRight = true,
  ...rest
}) => {
  return (
    <Container type="submit" {...rest}>
      <span>{value}</span>
      {arrowRight && <img src={chevron} alt="icon chevron" />}
    </Container>
  );
};

export { ButtonSecurity };
