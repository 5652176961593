import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import * as yup from 'yup';

import { HeaderModal1 } from '../../../components/HeaderModal1';
import { InputText } from '../../../components/InputText';
import { InputToken } from '../../../components/InputToken';
import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';
import { api } from '../../../services/api';
import { getValidationErrors } from '../../../utils/getValidationErrors';

import { Container, FormContainer, Info, Submit } from './styles';

interface formProps {
  new_password: string;
  confirmation_new_password: string;
}

interface modalProps {
  code: string;
}

const ForgotPassword3: React.FC<modalProps> = ({ code }) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { removeAllModals } = useModal();

  const handleSubmit = useCallback(
    async (data: formProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          new_password: yup
            .string()
            .min(8, 'A senha deve ter no mínimo 8 caracteres')
            .required('Campo obrigatório.'),
          confirmation_new_password: yup
            .string()
            .min(8, 'A senha deve ter no mínimo 8 caracteres')
            .oneOf([yup.ref('new_password'), null], 'Senhas não coincidem.')
            .required('Campo obrigatório.'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put(
          'users/password/change-forgot',
          {
            new_password: data.new_password,
            confirmation_new_password: data.confirmation_new_password,
          },
          {
            headers: {
              code: code.toUpperCase(),
            },
          },
        );

        addToast({
          type: 'success',
          title: 'Senha alterada com sucesso',
          description: 'Faça o login para ter acesso a nossa plataforma.',
        });

        removeAllModals();
      } catch (err: any) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na requisição',
          description:
            'Houve um erro na requisição realizada. Tente novamente mais tarde.',
        });
      }
    },
    [addToast, code, removeAllModals],
  );

  return (
    <Container>
      <HeaderModal1 title="Recuperar senha" />
      <Info text="Insira sua nova senha." />
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <InputText name="new_password" label="Nova senha" type="password" />
        <InputText
          name="confirmation_new_password"
          label="Confirme a nova senha"
          type="password"
        />

        <Submit name="submit" value="Alterar senha" />
      </FormContainer>
    </Container>
  );
};

export { ForgotPassword3 };
