import React, {
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';

import { FormHandles } from '@unform/core';
import { InputCheckboxBox } from '../../components/InputCheckboxBox';
import logoImg from '../../assets/logo.svg';
import imgWomanTraining from './assets/woman-training.svg';
import { getValidationErrors } from '../../utils/getValidationErrors';

import {
  ButtonSubmit,
  Container,
  ContainerCheckboxBox,
  ContainerInfo,
  ContainerRegister,
  Form,
  HeaderInfo,
  ImageInfo,
  Input,
  Label,
  TextInfo,
} from './styles';
import { phoneMask } from '../../utils/inputMasks';
import { formatUsername } from '../../utils/formatUsername';
import { api } from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useModal } from '../../hooks/modal';
import { InfoNewGym } from '../../modais/infoNewGym';

interface FormProps {
  name_gym: string;
  phone_gym: string;
  address_gym: string;
  fullname_user: string;
  nickname_user: string;
  email_user: string;
  min_age: boolean;
  privacity: boolean;
}

const NewGym: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [enableDisableSubmit, setenableDisableSubmit] = useState(false);

  const { addToast } = useToast();
  const { addModal } = useModal();

  useEffect(() => {
    const root = document.getElementById('root');
    document.title = 'Cadastro - Sua Academia';

    if (root) {
      root.style.backgroundColor = '#313131';
    }
  }, []);

  const handleEnableOrDisableSubmit = useCallback(() => {
    setenableDisableSubmit(
      formRef.current?.getFieldValue('min_age') &&
        formRef.current?.getFieldValue('privacity'),
    );
  }, []);

  const handleSubmit = useCallback(
    async (data: FormProps): Promise<void> => {
      window.scrollTo(0, 0);

      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name_gym: yup.string().required('Esse campo é obrigatório.'),
          phone_gym: yup
            .string()
            .nullable()
            .transform((value: string, o: string) => (o === '' ? null : value))
            .min(10, 'Insira o seu telefone com DDD'),
          address_gym: yup.string(),
          fullname_user: yup.string().required('Esse campo é obrigatório.'),
          nickname_user: yup.string().required('Esse campo é obrigatório.'),
          email_user: yup
            .string()
            .email('Insira um email válido.')
            .required('Esse campo é obrigatório.'),
          min_age: yup.boolean().required(),
          privacity: yup.boolean().required(),
        });

        await schema.validate(data, { abortEarly: false });

        console.log(data);

        addToast({
          type: 'info',
          title: 'Processando dados',
          description: 'Aguarde...',
        });

        await api.post('/gyms', {
          nameGym: data.name_gym,
          addressGym: data.address_gym || undefined,
          phoneGym: data.phone_gym || undefined,
          fullnameUser: data.fullname_user,
          nicknameUser: data.nickname_user,
          emailUser: data.email_user,
          minAge: !!data.min_age,
          privacity: !!data.privacity,
        });

        formRef.current?.reset();

        addModal(<InfoNewGym />);
      } catch (err: any) {
        console.log(err);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Por favor, verifique os dados informados',
          });

          return;
        }

        if (err?.response?.status === 403) {
          addToast({
            type: 'error',
            title: 'Email ja cadastrado na plataforma',
            description: 'Tente recuperar a sua de senha na página de login.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Houve um erro na criação da academia',
          description: 'Verifique os seus dados.',
        });
      }
    },
    [addToast],
  );

  return (
    <Container>
      <ContainerInfo>
        <HeaderInfo>
          <img src={logoImg} alt="Logo SUA ACADEMIA" />
          <h2>
            Preparado para uma nova experiência de gestão na sua academia? 😎
          </h2>
        </HeaderInfo>
        <TextInfo>
          <p>
            Disponibilizamos um teste de <span>15 dias</span> de algumas das
            funções da nossa plataforma.
          </p>
          <p>
            Insira as informações da instituição e do gestor responsável. A
            gestão pode ser alterada a qualquer momento na plataforma, definindo
            o nível de acesso do usuário.
          </p>
        </TextInfo>
        <ImageInfo>
          <img src={imgWomanTraining} alt="Woman training" />
        </ImageInfo>
      </ContainerInfo>
      <ContainerRegister>
        <Form ref={formRef} onSubmit={handleSubmit}>
          {/* Dados da academia */}
          <Label label="Informações da academia" />
          <Input
            name="name_gym"
            label="Nome da academia *"
            labelColor="#f5f5f580"
            placeholder="Exemplo Fitness"
          />

          <Input
            name="phone_gym"
            label="Telefone da academia"
            labelColor="#f5f5f580"
            placeholder="Telefone com DDD"
            type="phone"
            maxLength={11}
            onBlur={() => {
              formRef.current?.setFieldValue(
                'phone_gym',
                phoneMask(formRef.current?.getFieldValue('phone_gym')),
              );
            }}
            onChange={() => {
              formRef.current?.setFieldValue(
                'phone_gym',
                formRef.current?.getFieldValue('phone_gym').replace(/\D/g, ''),
              );
            }}
          />
          <Input
            name="address_gym"
            label="Endereço da academia"
            labelColor="#f5f5f580"
            placeholder="Rua, Número, Cidade - Estado"
          />

          {/* Dados do usuário */}
          <Label label="Informações do usuário" />
          <Input
            name="fullname_user"
            label="Nome do gestor *"
            labelColor="#f5f5f580"
            placeholder="Jonh Doe"
          />
          <Input
            name="nickname_user"
            label="Como você quer ser chamado? *"
            labelColor="#f5f5f580"
            placeholder="Jonh"
          />

          <Input
            name="email_user"
            label="Email do gestor *"
            labelColor="#f5f5f580"
            placeholder="jonhdoe@exemple.com"
          />

          <ContainerCheckboxBox>
            <InputCheckboxBox
              name="min_age"
              id="min_age"
              onChange={handleEnableOrDisableSubmit}
            />
            <label htmlFor="min_age">Tenho mais de 18 anos de idade.</label>
          </ContainerCheckboxBox>

          <ContainerCheckboxBox>
            <InputCheckboxBox
              name="privacity"
              id="privacity"
              onChange={handleEnableOrDisableSubmit}
            />
            <label htmlFor="privacity">
              Li e aceito as{' '}
              <a
                href="http://suaacademia.com/privacity"
                target="_blank"
                rel="noopener noreferrer"
              >
                políticas de privacidade
              </a>{' '}
              da plataforma <label className="black-ops">SUA ACADEMIA</label>.
            </label>
          </ContainerCheckboxBox>

          <ButtonSubmit
            name="submit"
            value="Realizar cadastro"
            type="submit"
            disabled={!enableDisableSubmit}
          />
        </Form>
      </ContainerRegister>
    </Container>
  );
};

export { NewGym };
