import React, { HtmlHTMLAttributes } from 'react';

import { Container } from './styles';

interface SeparateProps extends HtmlHTMLAttributes<HTMLDivElement> {
  label?: string;
}

const SeparateLabel: React.FC<SeparateProps> = ({ label, ...rest }) => {
  return (
    <Container {...rest}>
      {label && <span>{label}</span>} <div />{' '}
    </Container>
  );
};

export { SeparateLabel };
