import { shade } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.button`
  min-width: 250px;
  height: 110px;
  width: 100%;
  border-radius: 5px;

  text-decoration: none;

  border: none;

  display: flex;
  align-items: center;

  background: #f5f5f5;
  box-shadow: 0px 2px 5px #c4c4c4cc;

  transition: background-color 0.5s;

  &:hover {
    background-color: ${shade(0.2, '#f5f5f5')};
  }

  span {
    font-size: 42px;
    color: #151515;
    margin-left: 20px;
  }

  @media (max-width: 1199px) {
    height: 80px;
  }
`;

export const ContainerImg = styled.div`
  margin-left: 20px;
  width: 80px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    max-height: 100%;
    width: auto;
  }

  @media (max-width: 1199px) {
    width: 50px;
    height: 50px;
  }
`;

export const ContainerName = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 42px;
  }

  @media (max-width: 1199px) {
    span {
      font-size: 32px;
    }
  }
`;
