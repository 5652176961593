import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isChecked: boolean;
}

export const ContainerInput = styled.label`
  background-color: transparent;
`;

export const Input = styled.input<ContainerProps>`
  width: 20px;
  height: 20px;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: none;
  outline: 0;
  flex-grow: 0;
  border-radius: 50%;
  background-color: #ffffff;
  transition: background 300ms;
  cursor: pointer;

  border: 1px solid transparent;
  border-color: #ff955f;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  &::before {
    content: '';
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: none;
    background-color: transparent;
    background-size: contain;
  }

  &:checked {
    background-color: #ff955f;
  }

  &:checked::before {
    display: none;
  }

  &:disabled {
    background-color: #ccd3d8;
    opacity: 0.84;
    cursor: not-allowed;
  }
`;
