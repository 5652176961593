import React, { ButtonHTMLAttributes } from 'react';

import { Container, ContainerImg, ContainerName } from './styles';

import noMidia from '../../assets/no-midia.png';

interface cardGymProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  id: string;
  name: string;
  domain: string;
  logo?: string;
  is_active?: boolean;
}

const CardGym: React.FC<cardGymProps> = ({
  id,
  name,
  domain,
  logo,
  is_active = false,
  ...rest
}) => {
  //
  return (
    <Container is_active={is_active} {...rest}>
      <ContainerImg>
        <img src={logo || noMidia} alt="img user" />
      </ContainerImg>
      <ContainerName>
        <span>{name}</span>
        {/* <img src={level > 2 ? key[3] : key[level]} alt="access level" /> */}
      </ContainerName>
    </Container>
  );
};

export { CardGym };
