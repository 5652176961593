/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { HtmlHTMLAttributes } from 'react';
import { useModal } from '../../hooks/modal';

import { CloseButton, Container } from './styles';

interface headerModalProps extends HtmlHTMLAttributes<HTMLDivElement> {
  title: string;
}

const HeaderModal1: React.FC<headerModalProps> = ({ title, ...rest }) => {
  const { removeModal } = useModal();
  return (
    <Container {...rest}>
      <CloseButton name="close" onClick={() => removeModal()} />
      <span>{title}</span>
    </Container>
  );
};

export { HeaderModal1 };
