import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const ContainerInput = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: border-color 0.5s;

  height: 70px;
  width: 50px;
  border-radius: 5px;
  border: 1px solid transparent;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: #ff9000;
    `}

  ${props =>
    props.isFilled &&
    css`
      border-color: #ff9000;
    `}
`;

export const Input = styled.input`
  flex: 1;
  padding: 0;
  border: none;
  background-color: transparent;

  color: #151515;

  font-size: 30px;
  font-weight: 500;
  text-align: center;

  overflow: hidden;

  text-transform: uppercase;
`;
