import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { HeaderModal1 } from '../../components/HeaderModal1';
import { useAuth } from '../../hooks/auth';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import { api } from '../../services/api';

import { Button, Content, Container, Text, ContainerText } from './styles';

const InfoNewGym: React.FC = () => {
  const { removeAllModals } = useModal();
  const { addToast } = useToast();
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    addToast({
      title: `Redirecionando para login ...`,
      description: 'Insira os dados para continuar.',
    });

    navigate('/');
    signOut();
    removeAllModals();
  }, [addToast, navigate, removeAllModals, signOut]);

  return (
    <Container>
      <HeaderModal1 title="Boas vindas a nossa plataforma" />
      <Content>
        <ContainerText>
          <Text>
            <span>
              A criação da sua academia e acesso do gestor foram realizadas com
              sucesso.
            </span>
          </Text>
          <Text>
            Nesse momento, um <span>email</span> foi enviado a caixa de entrada
            do gestor cadastrado. Nele existem algumas orientações sobre o
            <span>período de teste</span> e uma <span>senha provisória</span>{' '}
            para o primeiro acesso a plataforma.
          </Text>
          <Text>
            Verifique também a <span>caixa de span</span>.
          </Text>
        </ContainerText>
        <Button
          name="signUp"
          value="Ir para login"
          onClick={() => handleSubmit()}
        />
      </Content>
    </Container>
  );
};

export { InfoNewGym };
