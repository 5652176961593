import styled from 'styled-components';
import { Form as FormContainer } from '@unform/web';
import { shade } from 'polished';
import { SeparateLabel } from '../../components/SeparateLabel';
import { ButtonConfirm } from '../../components/ButtonConfirm';
import { ButtonChangeImage } from '../../components/ButtonChangeImage';
import { ButtonSecurity } from '../../components/ButtonSecurity';
import { TextInfo } from '../../components/TextInfo';

export const Container = styled.div`
  padding: 20px;
  min-height: 100vh;

  h3 {
    font-size: 32px;
    color: #151515;
    margin: 20px 0;
  }

  @media (max-width: 1199px) {
    padding: 0;
    width: calc(100% - 40px);
    margin: auto;
  }
`;

export const Form = styled(FormContainer)`
  display: flex;
  width: 560px;

  margin: auto;

  flex-direction: column;

  & > * {
    margin-bottom: 10px;
  }

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const Info = styled(TextInfo)`
  margin: 10px 0;
`;

export const ContainerImg = styled.div`
  width: 150px;
  height: 150px;

  position: relative;

  border-radius: 50%;

  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 30px;

  img {
    min-height: 100%;
    width: auto;

    border-radius: 50%;
  }
`;

export const ButtonImg = styled(ButtonChangeImage)`
  position: absolute;

  bottom: 0;
  right: 0;
`;

export const Separate = styled(SeparateLabel)``;

export const Confirm = styled(ButtonConfirm)`
  align-self: center;
  margin: 30px 0;
`;

export const ButtonDeleteAccount = styled(ButtonSecurity)`
  background-color: #c53030;

  &:hover {
    background-color: ${shade(0.2, '#c53030')};
  }

  span {
    color: #f5f5f5;
  }
`;
