import { animated } from 'react-spring';
import styled from 'styled-components';
import { ButtonFlat } from '../../components/ButtonFlat';

export const Content = styled.div`
  position: relative;

  width: 100%;
  min-height: 100%;

  padding-top: 40px;

  display: grid;
  justify-content: space-around;
  grid-template-columns: auto auto auto;
  grid-row-gap: 20px;

  @media (max-width: 1199px) {
    width: calc(100% - 40px);
    min-height: none;
    margin: auto;
    flex-direction: column;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding-top: 20px;
  }
`;

export const ContainerNotification = styled(animated.div)`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  top: 0;

  width: 100%;
  height: 35px;

  background-color: #ccc;

  p {
    font-size: 18px;
    margin-left: 15px;
  }
`;

export const CloseNotification = styled(ButtonFlat)`
  position: absolute;
  right: 15px;
`;
