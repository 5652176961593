import React, { useEffect, useState } from 'react';
import { Painel } from '../../components/Painel';
import { useAuth } from '../../hooks/auth';
import { useModal } from '../../hooks/modal';
import { SubscriptionData } from '../../modais/subscriptionData';
import { api } from '../../services/api';

import {
  CardSubscription,
  Container,
  ContainerSubscriptions,
  Info,
  ContainerWarnings,
} from './styles';

interface subscriptionProps {
  description: string;
  id: string;
  max_exercises: number;
  max_news: number;
  max_users: number;
  name: string;
  price: number;
}

const Subscriptions: React.FC = () => {
  const [subscriptions, setSubscriptions] = useState<subscriptionProps[]>([]);

  const { addModal } = useModal();
  const { user } = useAuth();

  useEffect(() => {
    api
      .get('subscriptions')
      .then(response => {
        const subs = response.data as subscriptionProps[];
        setSubscriptions(subs.filter(sub => sub.name !== 'test'));
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Painel>
      <Container>
        <h3>Planos SUA ACADEMIA</h3>
        <ContainerWarnings>
          <Info text="Selecione um dos nossos planos para ter acesso as nossas funcionalidades. " />
          <Info text="O plano atual da sua instituição, caso esteja assinado, estará em destaque. " />
          <Info text="Para fazer o upgrade do plano da sua instituição basta selecionar um plano superior ao atualmente em uso. " />
        </ContainerWarnings>

        <ContainerSubscriptions>
          {subscriptions.map(subscription => {
            return (
              <CardSubscription
                key={subscription.id}
                title={subscription.name.toUpperCase()}
                news={subscription.max_news}
                exercises={subscription.max_exercises}
                users={subscription.max_users}
                price={subscription.price}
                currentSubscription={subscription.name === user.gym.signature}
                functionButton={() =>
                  addModal(
                    <SubscriptionData
                      subscription_id={subscription.id}
                      title={subscription.name}
                      news={subscription.max_news}
                      exercises={subscription.max_exercises}
                      users={subscription.max_users}
                      price={subscription.price}
                    />,
                  )
                }
              />
            );
          })}
        </ContainerSubscriptions>
      </Container>
    </Painel>
  );
};

export { Subscriptions };
