import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import * as yup from 'yup';

import { HeaderModal2 } from '../../components/HeaderModal2';
import { InputText } from '../../components/InputText';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import { api } from '../../services/api';
import { getValidationErrors } from '../../utils/getValidationErrors';

import { Container, FormContainer, Info, Submit } from './styles';

interface changePasswordProps {
  fullname: string;
  avatar_url?: string;
}

interface formProps {
  new_password: string;
  confirmation_new_password: string;
}

const ChangePassoword: React.FC<changePasswordProps> = ({
  fullname,
  avatar_url,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();

  const handleSubmit = useCallback(async (data: formProps) => {
    try {
      formRef.current?.setErrors({});

      const schema = yup.object().shape({
        new_password: yup
          .string()
          .min(8, 'A senha deve ter no mínimo 8 caracteres'),
        confirmation_new_password: yup
          .string()
          .min(8, 'A senha deve ter no mínimo 8 caracteres')
          .oneOf([yup.ref('new_password'), null], 'Senhas não coincidem.'),
      });

      await schema.validate(data, { abortEarly: false });

      await api.put('users/password/change-authenticated', {
        new_password: data.new_password,
        confirmation_new_password: data.confirmation_new_password,
      });

      addToast({
        type: 'success',
        title: 'Senha anterada com sucesso',
      });

      removeModal();
    } catch (err: any) {
      if (err instanceof yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }

      addToast({
        type: 'error',
        title: 'Erro na requisição',
        description:
          'Houve um erro na tentativa da atualização da senha. Tente novamente mais tarde.',
      });
    }
  }, []);
  return (
    <Container>
      <HeaderModal2
        title="Alterar Senha"
        subtitle={fullname}
        avatar_user={avatar_url}
      />
      <Info text="Insira a nova senha e a confirme nos campos abaixo." />
      <Info text="A senha deve ter no mínimo 8 caracteres." />
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <InputText
          label="Nova senha"
          name="new_password"
          type="password"
          autoComplete="off"
        />
        <InputText
          label="Confirme a nova senha"
          name="confirmation_new_password"
          type="password"
          autoComplete="off"
        />
        <Submit name="submit" value="Concluir" />
      </FormContainer>
    </Container>
  );
};

export { ChangePassoword };
