import React, { InputHTMLAttributes } from 'react';

import { Container } from './styles';

interface buttonProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
}

const Button: React.FC<buttonProps> = ({ name, value, ...rest }) => {
  return <Container type="submit" value={value} {...rest} />;
};

export { Button };
