import styled, { css } from 'styled-components';
import { IconBaseProps } from 'react-icons';
import { shade } from 'polished';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const ContainerInput = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 0;
  transition: border-color 0.5s;

  padding: 2.5px 0;

  width: 100%;

  height: 35px;
  border-radius: 20px;
  flex: 1;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  grid-gap: 5px;

  img {
    padding-left: 5px;
    margin: auto;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #ff9000;
      border-color: #ff9000;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #ff9000;
      border-color: #ff9000;
    `}
`;

export const Input = styled.input`
  padding: 0;
  border: none;
  background-color: transparent;

  width: calc(100% - 40px);

  color: #151515;

  font-size: 22px;
  font-weight: 500;

  @media (max-width: 1199px) {
    font-size: 18px;
  }
`;
