import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import * as yup from 'yup';
import { HeaderModal1 } from '../../../components/HeaderModal1';
import { InputToken } from '../../../components/InputToken';
import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';
import { api } from '../../../services/api';
import { SignUp2 } from '../signUp2';

import { Container, FormContainer, Info, Submit } from './styles';

interface formProps {
  c1: string;
  c2: string;
  c3: string;
  c4: string;
  c5: string;
}

const SignUp1: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addModal } = useModal();
  const { addToast } = useToast();

  const handleSubmit = useCallback(async (data: formProps) => {
    try {
      formRef.current?.setErrors({});

      const schema = yup.object().shape({
        c1: yup.string().required('Campo obrigatório'),
        c2: yup.string().required('Campo obrigatório'),
        c3: yup.string().required('Campo obrigatório'),
        c4: yup.string().required('Campo obrigatório'),
        c5: yup.string().required('Campo obrigatório'),
      });

      await schema.validate(data, { abortEarly: false });

      const code: string = data.c1 + data.c2 + data.c3 + data.c4 + data.c5;

      const response = await api.get('/users/verify-token', {
        headers: {
          code: code.toUpperCase(),
        },
      });

      const userData = {
        fullname: response.data.fullname,
        nickname: response.data.nickname,
        email: response.data.email,
        gym_id: response.data.gym_id,
        code,
      };

      addModal(
        <SignUp2
          fullname={userData.fullname}
          nickname={userData.nickname}
          email={userData.email}
          gym_id={userData.gym_id}
          code={userData.code.toUpperCase()}
        />,
      );
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        formRef.current?.setErrors({
          c1: 'Required',
          c2: 'Required',
          c3: 'Required',
          c4: 'Required',
          c5: 'Required',
        });

        return;
      }
      addToast({
        type: 'error',
        title: 'Token inválido.',
        description:
          'Tente novamente ou entre em contato com sua academia ou personal.',
      });
    }
  }, []);

  return (
    <Container>
      <HeaderModal1 title="Continuar Cadastro" />
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <Info text="Agora, insira abaixo o código recebido no email para confirmação." />
        <InputToken />
        <Info text="Verifique também na caixa de spam." />

        <Submit
          name="submit"
          value="Continuar"
          onSubmit={() => formRef.current?.submitForm()}
        />
      </FormContainer>
    </Container>
  );
};

export { SignUp1 };
