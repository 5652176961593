import styled from 'styled-components';

export const Container = styled.div`
  background-color: #545454;

  width: 100%;
  height: 250px;

  margin-bottom: -80px;
  z-index: -10;

  @media (max-width: 1199px) {
    margin: auto;
    background-color: transparent;

    width: calc(100% - 40px);
    height: 80px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  background-color: #545454;

  height: 170px;
  min-width: 1200px;
  max-width: 1400px;

  margin: auto;

  @media (max-width: 1199px) {
    flex: 1;

    justify-content: flex-start;
    flex-direction: row-reverse;
    min-width: 100%;
    max-width: 100%;
    height: 100%;

    background-color: transparent;
  }
`;

export const ContainerGym = styled.div`
  display: flex;

  align-items: center;
  height: 100%;

  div.container-logo-gym {
    height: 130px;
    width: 130px;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      max-height: 100%;
      width: auto;
    }
  }

  div.container-name-gym {
    h2 {
      color: #f5f5f5;
      margin-left: 30px;

      font-size: 54px;
    }

    h3 {
      color: #f5f5f5cc;
      margin-left: 30px;

      font-size: 16px;
    }
  }

  @media (max-width: 1199px) {
    div.container-logo-gym {
      height: 40px;
      width: 40px;

      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      img {
        max-height: 100%;
        width: auto;
      }
    }

    div.container-name-gym {
      h2 {
        display: none;
      }

      h3 {
        display: none;
        color: #151515;
      }
    }
  }
`;

export const ContainerLogoApp = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-family: 'Black Ops One', cursive;
    color: #f5f5f5;
    padding-right: 15px;
    font-size: 32px;
    border-right: 2px solid #f5f5f580;
    text-align: right;
  }

  img {
    margin-left: 15px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }

  @media (max-width: 1199px) {
    h2 {
      display: none;
    }

    img {
      width: 40px;
      height: 40px;

      margin-right: 10px;
    }
  }
`;
