import { useField } from '@unform/core';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  ContainerButton,
  ContainerInput,
  ContainerMidia,
  Input,
  Button,
  ContainerContainerMidia,
} from './styles';

import noMidia from '../../assets/no-midia.png';
import cameraImage from './assets/camera.svg';
import trashImage from './assets/trash.svg';

import { api } from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { TextInfo } from '../TextInfo';
import { Loading } from '../Loading';

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;

  style?: any;
  defaultFile?: string;
}

const InputFileGymLogo: React.FC<inputProps> = ({
  name,
  style,
  defaultFile,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { addToast } = useToast();
  const { updateUser } = useAuth();

  const [file, setFile] = useState<string | undefined>(defaultFile);
  const [loading, setLoading] = useState(false);

  const { defaultValue, fieldName, error, registerField } = useField(name);

  const supportedFileTypes = ['.jpg', '.jpeg', '.png'];

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setFile(defaultFile || undefined);
  }, [defaultFile]);

  const handleChangeFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      try {
        if (e.target.files) {
          const data = new FormData();

          if (e.target.files[0]) {
            const [, type] = e.target.files[0].type.split('/');

            if (
              !supportedFileTypes.some(x =>
                x.toUpperCase().includes(type.toUpperCase()),
              )
            ) {
              addToast({
                type: 'error',
                title: `Arquivo ${type} não suportado`,
                description: `Os tipos de arquivos suportados são ${supportedFileTypes.join(
                  ', ',
                )}`,
              });
              setLoading(false);
              return;
            }
          }

          data.append('logo', e.target.files[0]);

          const response = await api.patch('gyms/logo', data, {
            headers: {
              identificator: 'gym-logo',
            },
          });

          setFile(response.data.logo_url);

          const userData = await api.get('users');
          updateUser(userData.data);

          addToast({
            type: 'success',
            title: 'Upload realizado com sucesso.',
          });

          addToast({
            type: 'info',
            title: 'Caso a miniatura não atualize automaticamente',
            description: 'Faça o logoff e entre novamente na plataforma.',
          });

          setLoading(false);
        }
      } catch (err: any) {
        setLoading(false);

        if (err?.response?.status === 413) {
          addToast({
            type: 'error',
            title: 'Arquivo muito grande',
            description:
              'O arquivo enviado excede o limite permitido. Tente novamente com um arquivo menor.',
          });
          addToast({
            type: 'info',
            title: 'Permitidas imagens de até 5mb.',
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description:
            'Ocorreu um erro na atualização. tente novamente mais tarde.',
        });
      }
    },
    [addToast, updateUser],
  );

  const handleRemoveFile = useCallback(async () => {
    try {
      await api.delete(`gyms/logo`);
      setFile(undefined);

      const response = await api.get('users');
      updateUser(response?.data);

      addToast({
        type: 'success',
        title: 'Arquivo removido com sucesso.',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro na atualização',
        description:
          'Ocorreu um erro na atualização. tente novamente mais tarde.',
      });
    }
  }, [addToast, updateUser]);

  return (
    <>
      <ContainerInput style={style}>
        <ContainerContainerMidia>
          <ContainerMidia>
            {loading ? (
              <Loading no_shadow={loading} />
            ) : (
              <img src={file || noMidia} alt="midia exercise" />
            )}
          </ContainerMidia>
        </ContainerContainerMidia>

        <ContainerButton>
          <label htmlFor="file">
            <Button
              name="changeImage"
              value={!file ? 'Enviar Imagem' : 'Alterar Imagem'}
              imageMobile={cameraImage}
            />
          </label>

          <Button
            name="removeImage"
            value="Remover Imagem"
            onClick={handleRemoveFile}
            imageMobile={trashImage}
            removeStyle
          />
        </ContainerButton>

        <Input
          id="file"
          type="file"
          accept={supportedFileTypes.join(',')}
          ref={inputRef}
          defaultValue={defaultValue}
          onChange={handleChangeFile}
          {...rest}
        />
      </ContainerInput>
      {/* <TextInfo text="Imagens de até 5mb" /> */}
    </>
  );
};

export { InputFileGymLogo };
