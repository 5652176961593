import styled from 'styled-components';
import { Form as FormComponent } from '@unform/web';

import { Button } from '../../components/Button';
import { TextInfo } from '../../components/TextInfo';

export const Body = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  background-color: #313131;
  color: #f5f5f5;

  @media (max-width: 1199px) {
    min-width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;

  margin: auto;
  min-width: 1200px;
  max-width: 1400px;

  @media (max-width: 1199px) {
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
    height: 100vh;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

// info

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 50px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  max-height: 120px;

  @media (max-width: 1199px) {
    flex-direction: column;
  }
`;

export const ImageLogo = styled.img`
  width: 90px;
  height: 90px;
  padding-right: 20px;

  @media (max-width: 1199px) {
    padding: 0;
    margin-bottom: 10px;
  }
`;

export const TextLogo = styled.h1`
  font-family: 'Black Ops One', cursive;
  padding-left: 20px;

  border-left: 1px solid #f5f5f580;

  font-size: 38px;

  @media (max-width: 1199px) {
    .mobile-none {
      display: none;
    }
    border: none;
    padding: 0;
    font-size: 25px;
  }
`;

export const Info = styled.h2`
  max-width: 500px;
  font-size: 35px;
  font-weight: 500;
  font-variant: small-caps;

  margin: 50px auto;

  @media (max-width: 1199px) {
    font-size: 25px;
    text-align: center;
  }
`;

// login

export const ContainerLogin = styled.div`
  padding: 80px 30px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  min-width: 450px;

  background-color: #151515;
  box-shadow: 0px 2px 20px #151515;

  border-radius: 8px;

  @media (max-width: 1199px) {
    display: flex;
    min-width: 100%;
    padding: 0;
    margin: 0;

    background-color: transparent;
    box-shadow: none;
  }
`;

export const Form = styled(FormComponent)`
  & > *:nth-child(1),
  & > *:nth-child(2) {
    margin-bottom: 20px;
  }
`;

export const ContainerLink = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;

  margin: 10px 0;

  color: #f5f5f580;

  .link {
    text-decoration: none;
    color: #f5f5f5cc;
    font-weight: 600;
    cursor: pointer;
  }
`;

export const ButtonSignIn = styled(Button)`
  margin-top: 50px;
  margin-bottom: 20px;
`;

export const InfoMobile = styled(TextInfo)`
  @media (min-width: 1199px) {
    display: none;
  }

  span {
    color: #f5f5f5;
  }
`;
