import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';

import { InputFileGymLogo } from '../../components/InputFileGymLogo';
import { InputText } from '../../components/InputText';
import { Painel } from '../../components/Painel';
import { useAuth } from '../../hooks/auth';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import { api } from '../../services/api';
import { getValidationErrors } from '../../utils/getValidationErrors';
import { phoneMask } from '../../utils/inputMasks';

import { Confirm, Container, Form, Info, Separate } from './styles';

interface FormProps {
  name: string;
  phone?: string;
  address?: string;
}

const GymProfile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user, updateUser } = useAuth();
  const { addModal } = useModal();
  const { addToast } = useToast();

  const [formatedPhone, setFormatedPhone] = useState('');

  const handleSubmit = useCallback(
    async (data: FormProps): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup.string().required('Compo obrigatório'),
          address: yup.string(),
          phone: yup
            .string()
            .nullable()
            .transform((value: string, o: string) => (o === '' ? null : value))
            .min(10, 'Insira o seu telefone com DDD'),
        });

        await schema.validate(data, { abortEarly: false });

        console.log(data);

        await api.put('gyms', {
          name: data.name,
          address: data.address,
          phone: phoneMask(data?.phone, true),
        });

        const userData = await api.get('users');
        updateUser(userData.data);

        addToast({
          type: 'success',
          title: 'Dados do perfil atualizados com sucesso',
        });

        // history.push('/dashboard');
      } catch (err: any) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização dos dados',
          description:
            'Houve um erro na atualizaçãp dos dados. Tente novamente mais tarde.',
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    if (user?.gym?.phone) {
      setFormatedPhone(phoneMask(user.gym.phone));
    }
  }, [user]);

  return (
    <Painel>
      <Container>
        <h3>Dados da academia</h3>
        <Form
          ref={formRef}
          initialData={{ name: user?.gym?.name, address: user?.gym?.address }}
          onSubmit={handleSubmit}
        >
          <InputFileGymLogo name="logo" defaultFile={user?.gym?.logo_url} />
          <Info text="Imagem de até 5mb." />
          <Separate label="Informações gerais" />
          <InputText name="name" label="Nome" />
          <InputText name="address" label="Endereço" />
          <InputText
            name="phone"
            label="Telefone"
            type="phone"
            maxLength={11}
            defaultValue={formatedPhone}
            onBlur={() => {
              formRef.current?.setFieldValue(
                'phone',
                phoneMask(formRef.current?.getFieldValue('phone')),
              );
            }}
            onChange={() => {
              formRef.current?.setFieldValue(
                'phone',
                formRef.current?.getFieldValue('phone').replace(/\D/g, ''),
              );
            }}
          />

          <Confirm name="submit" type="submit" value="Confirmar alterações" />
        </Form>
      </Container>
    </Painel>
  );
};

export { GymProfile };
