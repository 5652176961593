import React, { useCallback, useEffect, useState } from 'react';
import { ButtonOptions } from '../../components/ButtonOptions';
import { CardWarning } from '../../components/CardWarning';
import { Painel } from '../../components/Painel';
import { TextInfo } from '../../components/TextInfo';
import { useModal } from '../../hooks/modal';
import { useToast } from '../../hooks/toast';
import { AddWarning } from '../../modais/addWarning';
import { ChangeWarning } from '../../modais/changeWarning';
import { api } from '../../services/api';

import alertIcon from './assets/alert.svg';

import {
  Container,
  ContainerInfo,
  ContainerWarnings,
  Header,
  NoDataContent,
  ScrollWarnings,
  TitleContainerWarnings,
} from './styles';
import { ButtonCircle } from '../../components/ButtonCircle';

interface IWarningsProps {
  id: string;
  title: string;
  content: string;
}

const Warnings: React.FC = () => {
  const [warnings, setWarnings] = useState<IWarningsProps[]>([]);
  const [loading, setLoading] = useState(true);

  const { addToast } = useToast();
  const { addModal, modals } = useModal();

  const handleRemoveWarning = useCallback(
    async (warning_id: string) => {
      try {
        await api.delete(`news/${warning_id}`);

        setWarnings(warnings.filter(warning => warning.id !== warning_id));

        addToast({ type: 'success', title: 'Aviso removido com sucesso.' });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro na remoção do aviso',
          description:
            'Houve um erro na remoção do aviso. Caso o problema persista entre em contato com o administrador do sistema.',
        });
      }
    },
    [addToast, warnings],
  );

  useEffect(() => {
    api
      .get('news')
      .then(response => setWarnings(response.data))
      .catch(err => console.log(err?.response?.status))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get('news')
      .then(response => setWarnings(response.data))
      .catch(err => console.log(err?.response?.status))
      .finally(() => {
        setLoading(false);
      });
  }, [modals]);

  return (
    <Painel loading={loading}>
      <Container>
        <Header>
          <h3>Avisos</h3>
          <ButtonCircle
            name="addNews"
            value="Novo Aviso"
            image="add"
            onClick={() => addModal(<AddWarning />)}
          />
        </Header>

        <ContainerInfo>
          <TextInfo text="Essa é uma pré-visualização de como os avisos estarão no app" />
          <TextInfo text="Os avisos ficarão disponíveis na tela inicial de todos os alunos da academia gerenciada por você" />
          <TextInfo text="Mova-os para os lados para visualizar todos, e caso necessário, é possivel editá-los ou remove-los" />
          <TextInfo text="O último aviso criado ou editado será o primeiro a ser exibido" />
        </ContainerInfo>

        <ContainerWarnings>
          <TitleContainerWarnings>
            <img src={alertIcon} alt="icon" />
            <span>Avisos</span>
          </TitleContainerWarnings>
          <ScrollWarnings>
            {warnings.length ? (
              warnings.map(warning => (
                <CardWarning
                  key={warning.id}
                  title={warning.title}
                  content={warning.content}
                  functionRemoveButton={() => handleRemoveWarning(warning.id)}
                  functionChangeButton={() =>
                    addModal(
                      <ChangeWarning
                        id={warning.id}
                        title={warning.title}
                        content={warning.content}
                      />,
                    )
                  }
                />
              ))
            ) : (
              <NoDataContent>
                <span>Sem avisos.</span>
              </NoDataContent>
            )}
          </ScrollWarnings>
        </ContainerWarnings>
      </Container>
    </Painel>
  );
};

export { Warnings };
