import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f5f5f5;

  min-height: 500px;
`;

export const Content = styled.div`
  width: calc(100% - 40px);
  margin: auto;
`;

export const Title = styled.h2`
  font-size: 32px;
  margin: 15px 0;
  font-weight: 400;

  span {
    color: #ff955f;
  }

  @media (max-width: 1199px) {
    font-size: 23px;
  }
`;

export const PaymentMethods = styled.div``;
