import React, { HtmlHTMLAttributes } from 'react';

import {
  BottonHeader,
  CloseButton,
  Container,
  ContainerImgExercise,
  ContainerImgUser,
  Content,
  TopHeader,
} from './styles';

import noMidiaExercise from '../../assets/no-midia.png';
import noAvatarUser from '../../assets/no-avatar.jpg';
import { useModal } from '../../hooks/modal';

interface headerModalProps extends HtmlHTMLAttributes<HTMLDivElement> {
  title: string;
  subtitleLeft?: string;
  subtitleRight?: string;
  avatar_user?: string | undefined;
  midia_exercise?: string | undefined;
}

const HeaderModal3: React.FC<headerModalProps> = ({
  title,
  subtitleLeft,
  subtitleRight,
  avatar_user,
  midia_exercise,
  ...rest
}) => {
  const { removeModal } = useModal();
  return (
    <Container {...rest}>
      <CloseButton name="close" onClick={() => removeModal()} />
      <ContainerImgUser>
        <img src={avatar_user || noAvatarUser} alt="img user" />
      </ContainerImgUser>
      <ContainerImgExercise>
        <img src={midia_exercise || noMidiaExercise} alt="img user" />
      </ContainerImgExercise>
      <Content>
        <TopHeader>
          <span>{title}</span>
        </TopHeader>
        <BottonHeader>
          <span>{subtitleLeft}</span>
          <span>{subtitleRight}</span>
        </BottonHeader>
      </Content>
    </Container>
  );
};

export { HeaderModal3 };
