import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Card, Container, Content, Form } from './styles';
import { Painel } from '../../../components/Painel';
import { GymsService, IGym } from '../../../services/GymsService';
import { InputSearch } from '../../../components/InputSearch';
import { Select } from '../../../components/Select';
import { useToast } from '../../../hooks/toast';
import { TextInfo } from '../../../components/TextInfo';

const Gyms: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const gymsService = new GymsService();

  const { addToast } = useToast();

  const [gyms, setGyms] = useState([] as IGym[]);
  const [filter, setFilter] = useState<IGym[]>([]);

  const handleSubmit = useCallback(async (data): Promise<void> => {
    //
  }, []);

  const handleChangeInputTextForm = useCallback(() => {
    setFilter(gyms);
    const input = formRef.current?.getFieldValue('searchGyms') as string;

    const filterInputText = gyms.filter(gym =>
      gym.name.toLowerCase().includes(input.toLowerCase()),
    );
  }, [gyms]);

  const getGyms = useCallback(() => {
    gymsService.getGyms().then(response => {
      setGyms(response.data);
      setFilter(response.data);
    });
  }, [gymsService]);

  const handleUnlockGym = useCallback(
    (gym_id: string) => {
      gymsService
        .unlock(gym_id)
        .then(() => {
          addToast({
            type: 'success',
            title: 'Academia desbloqueada',
          });
          getGyms();
        })
        .catch(err => {
          addToast({
            type: 'error',
            title: 'Erro ao desbloquear academia',
          });
        });
    },
    [addToast, getGyms, gymsService],
  );

  useEffect(() => {
    getGyms();
  }, []);

  return (
    <Painel>
      <Container>
        <TextInfo text="BETA" />
        <h3>Academias</h3>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputSearch name="searchGyms" onChange={handleChangeInputTextForm} />
          {/* <Select
            name="select"
            options={accessLevel}
            placeholder="Nível de acesso"
            onChange={handleChangeSelectForm}
            blurInputOnSelect
          /> */}
          {/* <ButtonOptions name="button" value="Adicionar usuário(a)" /> */}
        </Form>

        <Content>
          {filter.map(gym => (
            <Card
              key={gym.id}
              id={gym.id}
              name={gym.name}
              domain={gym.domain}
              is_active={gym.is_active}
              logo={gym.logo_url}
              onClick={() => handleUnlockGym(gym.id)}
            />
          ))}
        </Content>
      </Container>
    </Painel>
  );
};

export { Gyms };
