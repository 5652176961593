import styled, { css } from 'styled-components';
import { IconBaseProps } from 'react-icons';
import { shade } from 'polished';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

interface InputProps {
  labelColor?: string;
}

export const Container = styled.div<InputProps>`
  flex: 1;

  span {
    font-size: 13px;
    color: ${props => (props.labelColor ? props.labelColor : '#151515')};
    margin-left: 11px;
    line-height: 25px;
  }

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const ContainerInput = styled.div<ContainerProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: border-color 0.5s;
  background-color: #f5f5f5;

  height: 35px;
  border-radius: 20px;
  border: 1px solid transparent;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  @media (max-width: 1199px) {
    height: 30px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFilled &&
    css`
      border-color: #ff9000;
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: #ff9000;
    `}
`;

export const Input = styled.input`
  display: flex;
  flex: 1;
  padding: 0 10px;
  width: calc(100% - 20px);
  border: none;
  background-color: transparent;

  color: #151515;

  font-size: 22px;
  font-weight: 500;

  @media (max-width: 1199px) {
    font-size: 18px;
  }
`;

export const ContainerError = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;

  span {
    color: #c53030;
  }
`;

export const ContainerSuccess = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;

  span {
    color: #32a54a;
  }
`;

export const Info = styled(Tooltip)`
  height: 20px;
  margin: 0 10px;
  position: absolute;
  right: 0;

  svg {
    margin: 0;
  }

  span {
    background: #ff955f;
    color: #f5f5f5;

    &::before {
      border-color: #ff955f transparent;
    }
  }
`;
