import styled from 'styled-components';

export const Container = styled.button`
  width: 250px;
  height: 250px;

  background-color: #c4c4c480;
  transition: background-color 0.5s;
  border: none;

  &:hover {
    background-color: #c4c4c4;
  }

  border-radius: 5px;

  display: flex;
  position: relative;

  justify-content: center;
  align-items: center;

  cursor: pointer;

  @media (max-width: 1199px) {
    width: 100%;
    height: 120px;
  }
`;

export const Title = styled.span`
  position: absolute;

  top: 10px;
  left: 10px;

  font-size: 16px;
  font-weight: 500;
`;

export const Data = styled.span`
  font-size: 48px;
  font-weight: 600;
`;
