import styled, { css } from 'styled-components';
import { IconBaseProps } from 'react-icons';
import { shade } from 'polished';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div`
  span {
    font-size: 13px;
    color: #151515;
    margin-left: 11px;
    line-height: 25px;
  }
`;

export const ContainerInput = styled.div<ContainerProps>`
  display: flex;
  width: calc(100% - 20px);
  height: 170px;

  padding: 10px;

  transition: border-color 0.5s;

  border-radius: 15px;
  border: 1px solid transparent;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  background-color: #f5f5f5;

  color: #151515;

  font-size: 22px;
  font-weight: 500;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      border-color: #ff9000;
    `}

  ${props =>
    props.isFilled &&
    css`
      border-color: #ff9000;
    `}

  &:placeholder-shown {
    font-size: 18px;
  }
`;

export const Input = styled.textarea`
  display: flex;
  flex: 1;
  padding: 0 10px;
  width: calc(100% - 20px);
  border: none;
  background-color: transparent;

  color: #151515;

  font-size: 22px;
  font-weight: 500;

  @media (max-width: 1199px) {
    font-size: 18px;
  }
`;
