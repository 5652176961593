import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  width: 220px;
  height: 41px;

  display: flex;

  justify-content: center;
  align-items: center;

  border: none;

  background-color: #ff955f;

  color: #f5f5f5;
  font-size: 32px;

  border-radius: 5px;

  transition: background-color 0.2s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${shade(0.2, '#ff955f')};
  }

  span {
    font-size: 20px;
  }

  &:disabled {
    background-color: ${shade(0.5, '#ff955f')};
  }
`;
