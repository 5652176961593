import { useField } from '@unform/core';
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from 'react';

import { ContainerInput, Input } from './styles';

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  style?: any;
}

interface inputRef {
  focus(): void;
}

const InputChar: React.ForwardRefRenderFunction<inputRef, inputProps> = (
  { name, style, ...rest },
  ref,
) => {
  const inputElementRef = useRef<any>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { defaultValue, fieldName, error, registerField } = useField(name);

  useImperativeHandle(ref, () => ({
    focus() {
      inputElementRef.current.focus();
    },
  }));

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputElementRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputElementRef.current?.value);
  }, []);

  return (
    <ContainerInput
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      style={style}
    >
      <Input
        ref={inputElementRef}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        maxLength={1}
        autoComplete="none"
        {...rest}
      />
      {/* {error && <img src={errorImg} alt="img error" />} */}
    </ContainerInput>
  );
};

export default forwardRef(InputChar);
