/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiMail, FiLock } from 'react-icons/fi';
import { CustomDialog } from 'react-st-modal';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import logo from '../../assets/logo.svg';
import { InputLogin } from '../../components/InputLogin';

import {
  Body,
  Container,
  ContainerInfo,
  ContainerLogin,
  ImageLogo,
  Info,
  LogoContainer,
  TextLogo,
  Form,
  ContainerLink,
  ButtonSignIn,
  InfoMobile,
} from './styles';

import { AddUser } from '../../modais/addUser';
import { ForgotPassword } from '../../modais/forgotPassword';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { api } from '../../services/api';
import { getValidationErrors } from '../../utils/getValidationErrors';
import { useModal } from '../../hooks/modal';
import { SignUp } from '../../modais/singUp';

interface ISignInProps {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { addModal } = useModal();
  const { signIn, signOut } = useAuth();
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    const root = document.getElementById('root');
    document.title = 'Sua Academia';

    signOut();

    if (root) {
      root.style.backgroundColor = '#f5f5f5';
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: ISignInProps): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Digite um email válido'),
          password: Yup.string().required('Senha obrogatória'),
        });

        await schema.validate(data, { abortEarly: false });

        addToast({
          title: 'Processando login',
          description: 'Aguarde ...',
        });

        const response = await api.post('session/adm', {
          email: data.email,
          password: data.password,
        });

        await signIn({ token: response.data.token, user: response.data.user });

        navigate('/dashboard');
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        if (err?.response?.status === 400) {
          addToast({
            type: 'error',
            title: 'Credenciais inválidas',
            description: 'Verifique suas credenciais e tente novamente..',
          });
          return;
        }

        if (err?.response?.status === 401) {
          addToast({
            type: 'error',
            title: 'Nivel de acesso',
            description:
              'Acesso permitido apenas administradores e funcionários. Para alunos, acesse o app mobile.',
          });
          return;
        }

        if (err?.response?.status === 423) {
          addToast({
            type: 'error',
            title: 'Acesso bloqueado',
            description:
              'Usuário ou Academia bloqueados. Entre em contato com o administrador do sistema.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer login, cheque as credenciais e tente novamente.',
        });
      }
    },
    [addToast],
  );

  const handleForgotPassword = useCallback(() => {
    addModal(<ForgotPassword />);
  }, []);

  const handleSignUp = useCallback(() => {
    addModal(<SignUp />);
  }, []);

  return (
    <Body>
      <Container>
        <ContainerInfo>
          <LogoContainer>
            <ImageLogo src={logo} />
            <TextLogo>
              Sua
              <br className="mobile-none" /> Academia
            </TextLogo>
          </LogoContainer>
          <Info>
            área de acesso destinada aos administradores e profissionais{' '}
          </Info>
        </ContainerInfo>

        <ContainerLogin>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputLogin
              name="email"
              type="email"
              inputLabel="Email"
              placeholder="exemple@domain.com"
              icon={FiMail}
            />

            <InputLogin
              name="password"
              type="password"
              placeholder="**********"
              inputLabel="Senha"
              icon={FiLock}
            />
            <ContainerLink>
              <span>Esqueceu sua senha?</span>
              <span className="link" onClick={handleForgotPassword}>
                {' '}
                Clique aqui
              </span>
            </ContainerLink>
            <ContainerLink>
              <span>Possui um código de cadastro?</span>
              <span className="link" onClick={handleSignUp}>
                {' '}
                Clique aqui
              </span>
            </ContainerLink>
            <ButtonSignIn name="submit" value="Entrar" />
          </Form>

          <InfoMobile text="Recomendamos o acesso pelo computador para melhor vezualização." />
        </ContainerLogin>
      </Container>
    </Body>
  );
};

export { SignIn };
