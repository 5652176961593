import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { InputSearch } from '../../components/InputSearch';
import { Select } from '../../components/Select';
import { Painel } from '../../components/Painel';

import { Container, Content, Form, Card, Header } from './styles';
import { api } from '../../services/api';
import { ButtonCircle } from '../../components/ButtonCircle';
import { AddUser } from '../../modais/addUser';
import { useModal } from '../../hooks/modal';

interface IUserProps {
  id: string;
  fullname: string;
  username: string;
  level_access: number;
  avatar_url: string | undefined;
}

const accessLevel = [
  { value: -1, label: 'Todos(as)' },
  { value: 0, label: 'Aluno(a)' },
  { value: 1, label: 'Funcionário(a)' },
  { value: 2, label: 'Administrador(a)' },
];

const Users: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<IUserProps[]>([]);
  const [select, setSelect] = useState(-1);
  const [filter, setFilter] = useState<IUserProps[]>([]);

  const { addModal } = useModal();

  useEffect(() => {
    api
      .get('users/gym')
      .then(response => {
        setUsers(response?.data);
        setFilter(response?.data);
      })
      .catch(err => console.log(err?.response?.status))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChangeSelectForm = useCallback(
    (e: any) => {
      setFilter(users);
      setSelect(e.value);
      setSelect(e.value);

      const input = formRef.current?.getFieldValue('searchUsers') as string;

      const filterSelect = users.filter(user =>
        e.value === -1 ? user : user.level_access === e.value,
      );

      const filterInputText = filterSelect.filter(user =>
        user.fullname.toLowerCase().includes(input.toLowerCase()),
      );

      setFilter(filterInputText);
    },
    [select, users],
  );

  const handleChangeInputTextForm = useCallback(() => {
    setFilter(users);
    const input = formRef.current?.getFieldValue('searchUsers') as string;

    const filterInputText = users.filter(user =>
      user.fullname.toLowerCase().includes(input.toLowerCase()),
    );

    const filterSelect = filterInputText.filter(user =>
      select === -1 ? user : user.level_access === select,
    );

    setFilter(filterSelect);
  }, [select, users]);

  const handleSubmit = useCallback(async (data): Promise<void> => {
    try {
      // formRef.current?.setErrors({});
      // const schema = Yup.object().shape({
      //   email: Yup.string()
      //     .required('Email obrigatório')
      //     .email('Digite um email válido'),
      //   password: Yup.string().required('Senha obrogatória'),
      // });
      // await schema.validate(data, { abortEarly: false });
      // await signIn({
      //   email: data.email,
      //   password: data.password,
      // });
      // history.push('/dashboard');
    } catch (err) {
      // if (err instanceof Yup.ValidationError) {
      //   const errors = getValidationErrors(err);
      //   formRef.current?.setErrors(errors);
      // }
    }
  }, []);

  return (
    <Painel loading={loading}>
      <Container>
        <Header>
          <h3>Usuários</h3>
          <ButtonCircle
            name="addUsers"
            value="Novo Usuário"
            image="add"
            onClick={() => addModal(<AddUser />)}
          />
        </Header>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputSearch
            name="searchUsers"
            onChange={handleChangeInputTextForm}
          />
          <Select
            name="select"
            options={accessLevel}
            placeholder="Nível de acesso"
            onChange={handleChangeSelectForm}
            blurInputOnSelect
          />
          {/* <ButtonOptions name="button" value="Adicionar usuário(a)" /> */}
        </Form>
        <Content>
          {filter.map(userCurrent => (
            <Card
              key={userCurrent.id}
              avatar={userCurrent.avatar_url}
              name={userCurrent.fullname}
              username={userCurrent.username}
              level={userCurrent.level_access}
              id={userCurrent.id}
            />
          ))}
        </Content>
      </Container>
    </Painel>
  );
};

export { Users };
