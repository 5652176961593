import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  width: 26px;
  height: 26px;

  background-color: transparent;

  color: #f5f5f5;
  border: none;

  transition: background-color 0.2s;

  &:hover {
    background-color: ${shade(0.2, '#ff955f')};
  }
`;
