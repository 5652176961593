import React, { HtmlHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = HtmlHTMLAttributes<HTMLDivElement>;

const ButtonChange: React.FC<ButtonProps> = ({ ...rest }) => {
  return (
    <Container {...rest}>
      <span>Editar</span>
    </Container>
  );
};

export { ButtonChange };
