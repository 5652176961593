import styled from 'styled-components';
import { Form } from '@unform/web';
import { TextInfo } from '../../components/TextInfo';
import { Select as SelectComponent } from '../../components/Select';
import { ButtonConfirm } from '../../components/ButtonConfirm';

export const Container = styled.div`
  background-color: #f5f5f5;
`;

export const Content = styled(Form)`
  margin: auto;
  width: calc(100% - 40px);

  padding-bottom: 40px;

  h2 {
    font-size: 35px;
    font-weight: 400;

    margin-bottom: 15px;
  }
`;

//    INFO

export const ContainerInfo = styled.div`
  margin: 15px 0;
  width: calc(100% - 40px);
`;

export const Info = styled(TextInfo)`
  span {
    font-size: 13px;
  }
`;

//    DATA SUBSCRIPTION

export const ContainerDataSubscription = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1199px) {
    border: none;
    height: auto;
  }
`;

export const DataSubscription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 5px;

  font-size: 21px;

  width: 100%;

  div {
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-right: 2px;
    }
  }

  & > span {
    color: #9b9b9b;
  }

  &:last-child {
    margin-top: 30px;
  }

  @media (max-width: 1199px) {
    font-size: 18px;
  }
`;

export const Select = styled(SelectComponent)`
  /* width: 60px;
  height: 25px; */

  .react-select__input-container {
    display: none;
  }

  .react-select__control {
    flex: 1;
    flex-direction: row;
    max-width: 150px;
    /* background-color: blue; */
  }

  .react-select__menu-list {
    flex-direction: column;
    width: 100%;
  }
`;

export const Submit = styled(ButtonConfirm)`
  margin: 40px auto;
  margin-bottom: 0;
`;
