import React, { HTMLAttributes, MouseEventHandler } from 'react';

import { ButtonClose, Container, ContainerLabel, Label } from './styles';

interface IButtonProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  isMain: boolean;
  onClickClose?: MouseEventHandler;
  onClickSetMain?: MouseEventHandler;
}

const CardMuscleGroup: React.FC<IButtonProps> = ({
  label,
  isMain,
  onClickClose,
  onClickSetMain,
  ...rest
}) => {
  return (
    <Container isMain={isMain} {...rest}>
      <ContainerLabel onClick={onClickSetMain}>
        <Label>{label}</Label>
      </ContainerLabel>
      <ButtonClose name="close" lightMode onClick={onClickClose} />
    </Container>
  );
};

export { CardMuscleGroup };
