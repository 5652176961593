/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTransition, animated, useSpring, SpringProps } from 'react-spring';
import { Painel } from '../../components/Painel';

import { useModal } from '../../hooks/modal';
import { api } from '../../services/api';

import { Card } from './components/Card';

import { CloseNotification, ContainerNotification, Content } from './styles';
import { useToast } from '../../hooks/toast';

interface IResponseProps {
  exercisesRegistered: number;
  usersRegistered: number;
  usersActiveInCurrentWeek: number;
}

const Dashboard: React.FC = () => {
  const [data, setData] = useState<IResponseProps>();
  const [loading, setLoading] = useState(true);

  const navigation = useNavigate();
  const { addToast } = useToast();

  // const [springs, setSprings] = useSpring(() => ({
  //   from: { y: `-100%` },
  // }));

  // const handleClick = useCallback(() => {
  //   setSprings.start({
  //     from: {
  //       y: `-100%`,
  //     },
  //     to: {
  //       y: `0px`,
  //     },
  //   });
  // }, []);

  // const closeNotification = useCallback(() => {
  //   setSprings.start({
  //     from: {
  //       y: `0px`,
  //     },
  //     to: {
  //       y: `-100%`,
  //     },
  //   });
  // }, [setSprings]);

  const handleClickActiveUsersToday = useCallback(() => {
    addToast({
      type: 'info',
      title: 'Funcionalidade em breve.',
    });
  }, []);

  useEffect(() => {
    api
      .get('gyms/dashboard')
      .then(response => {
        setData(response.data);
      })
      .catch(err => {
        console.log(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Painel loading={loading}>
      <Content>
        {/* <ContainerNotification
          style={{
            ...springs,
          }}
        >
          <p>Faturas disponíveis.</p>
          <CloseNotification
            name="close-button"
            onClick={() => closeNotification()}
          />
        </ContainerNotification> */}
        <Card
          title="Alunos Cadastrados"
          data={data?.usersRegistered || '-'}
          onClick={() => navigation('/users')}
        />
        <Card
          title="Exercícios Cadastrados"
          data={data?.exercisesRegistered || '-'}
          onClick={() => navigation('/exercises')}
        />
        <Card
          title="Usuários ativos hoje"
          data={data?.usersActiveInCurrentWeek || '-'}
          onClick={handleClickActiveUsersToday}
        />
      </Content>
    </Painel>
  );
};

export { Dashboard };
