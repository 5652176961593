import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import * as Yup from 'yup';

import { HeaderModal2 } from '../../components/HeaderModal2';
import { InputCheckbox } from '../../components/InputCheckbox';
import { Select } from '../../components/Select';
import { useToast } from '../../hooks/toast';
import { levelAccess } from '../../objects/select';
import { api } from '../../services/api';
import { getValidationErrors } from '../../utils/getValidationErrors';

import {
  Container,
  ContainerInline,
  FormContainer,
  Label,
  Submit,
} from './styles';

interface IChangeUserProps {
  avatar_url?: string;
  name: string;
  level_access: number;
  is_active: boolean;
  id: string;
}

interface IFormProps {
  levelAccess: { value: number; label: string };
  isActive: boolean;
}

const ChangeDataUser: React.FC<IChangeUserProps> = ({
  avatar_url,
  name,
  level_access,
  is_active,
  id,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const defaulValueSelect = useCallback(() => {
    const value = levelAccess.find(level => level.value === level_access);

    return value;
  }, [level_access]);

  const handleSubmit = useCallback(
    async (data: IFormProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          levelAccess: Yup.number().required(),
          // isActive: Yup.boolean().required(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put(`users/level-access/${id}`, {
          level_access: data.levelAccess,
        });

        addToast({
          type: 'success',
          title: 'Alterações realizadas com sucesso.',
        });

        addToast({
          type: 'info',
          title:
            'Em mudanças de nivel de acesso, o usuário editado precisa sair e entrar novamente no app.',
        });
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        if (err?.response?.status === 401) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para executar essa ação.',
          });
          return;
        }

        if (err?.response?.status === 403) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para executar essa ação.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Houve um erro. Tente novamente.',
        });
      }
    },
    [addToast, id],
  );

  return (
    <Container>
      <HeaderModal2
        avatar_user={avatar_url}
        title="Configurações do usuário"
        subtitle={name}
      />

      <FormContainer
        initialData={{ levelAccess: level_access }}
        onSubmit={handleSubmit}
      >
        <ContainerInline>
          <Label>Nivel de acesso:</Label>
          <Select
            name="levelAccess"
            options={levelAccess}
            placeholder="Nível de acesso"
            defaultValue={defaulValueSelect}
          />
        </ContainerInline>
        {/* <ContainerInline>
          <Label>Acesso habilitado:</Label>
          <InputCheckbox name="isActive" defaultChecked={is_active} />
        </ContainerInline> */}
        <Submit name="submit" value="Concluir" />
      </FormContainer>
    </Container>
  );
};

export { ChangeDataUser };
