import { Form } from '@unform/web';
import styled from 'styled-components';
import { ButtonChangeImage } from '../../components/ButtonChangeImage';
import { ButtonConfirm } from '../../components/ButtonConfirm';
import { InputText } from '../../components/InputText';
import { TextInfo } from '../../components/TextInfo';

export const Container = styled.div`
  width: 100%;

  background-color: #f5f5f5;
`;

export const ContainerImg = styled.div`
  width: 170px;
  height: 170px;

  position: relative;

  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  img {
    height: 100%;
    width: auto;
  }
`;

export const ButtonImg = styled(ButtonChangeImage)`
  position: absolute;

  bottom: 0;
  right: 0;
`;

export const Info = styled(TextInfo)`
  margin: 10px 0;
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;

  padding: 0 20px;
  margin: 10px 0;

  & > * {
    margin-bottom: 10px;
  }
`;

export const Input = styled(InputText)``;

export const ContainerInline = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  grid-gap: 10px;

  @media (max-width: 1199px) {
    grid-gap: 5px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Submit = styled(ButtonConfirm)`
  align-self: center;
  margin: 20px 0;
`;

export const ContainerCardsMuscleGroups = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
`;
