import React, { ButtonHTMLAttributes } from 'react';

import Lottie from 'react-lottie';
import { Container } from './styles';

import loadingLottie from '../../assets/loading_white.json';

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  value: string;
  loading?: boolean;
}

const ButtonConfirm: React.FC<buttonProps> = ({
  name,
  value,
  loading = false,
  ...rest
}) => {
  return (
    <Container type="submit" {...rest}>
      {!loading ? (
        <span>{value}</span>
      ) : (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loadingLottie,
          }}
          width={100}
          height={100}
        />
      )}
    </Container>
  );
};

export { ButtonConfirm };
