import { useField } from '@unform/core';
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertTriangle } from 'react-icons/fi';

import {
  Container,
  ContainerError,
  ContainerInput,
  Input,
  Label,
} from './styles';

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  inputLabel?: string;
  icon?: React.ComponentType<IconBaseProps>;
  style?: any;
}

const InputLogin: React.FC<inputProps> = ({
  name,
  icon: Icon,
  inputLabel,
  style,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { defaultValue, fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <Container>
      <Label>{inputLabel}</Label>
      <ContainerInput
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
      >
        {Icon && <Icon size={20} style={{ marginLeft: 8, marginRight: 8 }} />}
        <Input
          ref={inputRef}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          autoCapitalize="none"
          {...rest}
        />
      </ContainerInput>
      {!!error && (
        <ContainerError>
          <FiAlertTriangle size={15} color="#c53030" />
          <span>{error}</span>
        </ContainerError>
      )}
    </Container>
  );
};

export { InputLogin };
