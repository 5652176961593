import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  /* min-width: 50px; */
  width: 50px;
  height: 50px;
  max-width: 50px;

  display: flex;
  position: relative;

  justify-content: center;
  align-items: center;

  border-radius: 100%;

  background-color: #ff955f;

  color: #f5f5f5;
  font-size: 32px;

  border: none;

  transition: background-color 0.2s;
  transition: max-width 1s;

  cursor: pointer;

  span {
    display: none;
    white-space: nowrap;
  }

  &:hover {
    background-color: ${shade(0.2, '#ff955f')};
    width: auto;
    max-width: 1000px;
    border-radius: 35px;
    padding: 0 15px;

    span {
      display: initial;
      font-size: 20px;
      margin-left: 15px;
    }
  }

  &:disabled {
    background-color: ${shade(0.5, '#ff955f')};
  }
`;
