/* eslint-disable no-unused-expressions */
import React, { HtmlHTMLAttributes, useCallback, useState } from 'react';
import { CustomDialog } from 'react-st-modal';

import { Container, ContainerContent, Subtitle, Title } from './styles';

import noMidia from '../../assets/no-midia.png';
import { ButtonRemove } from './components/ButtonRemove';
import { ButtonChange } from './components/ButtonChange';

interface CardWarningsProps extends HtmlHTMLAttributes<HTMLDivElement> {
  title: string;
  content: string;
  disableOptHover?: boolean;
  functionChangeButton?: any;
  functionRemoveButton?: any;
  id?: string;
  midia_url?: string | undefined;
}

const CardWarning: React.FC<CardWarningsProps> = ({
  title,
  content,
  id,
  midia_url,
  disableOptHover = false,
  functionChangeButton,
  functionRemoveButton,
  ...rest
}) => {
  const [hover, setHover] = useState(false);

  const handleSetHover = useCallback((state: boolean) => {
    setHover(state);
  }, []);

  return (
    <Container
      onMouseEnter={() => {
        !disableOptHover ? handleSetHover(true) : '';
      }}
      onMouseLeave={() => {
        !disableOptHover ? handleSetHover(false) : '';
      }}
      {...rest}
    >
      <ContainerContent>
        <Title>{title}</Title>
        <Subtitle>{content}</Subtitle>
      </ContainerContent>
      {hover && <ButtonRemove onClick={functionRemoveButton} />}
      {hover && <ButtonChange onClick={functionChangeButton} />}
    </Container>
  );
};

export { CardWarning };
