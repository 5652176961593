import React from 'react';
import { ChangeMail1 } from './changeMail1';

interface ChangeMailProps {
  avatar_url?: string;
  fullname: string;
  email: string;
}

const ChangeMail: React.FC<ChangeMailProps> = ({
  avatar_url,
  fullname,
  email,
}) => {
  return (
    <ChangeMail1 avatar_url={avatar_url} fullname={fullname} email={email} />
  );
};

export { ChangeMail };
