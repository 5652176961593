import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { Painel } from '../../components/Painel';
import { InputText } from '../../components/InputText';
import { ButtonSecurity } from '../../components/ButtonSecurity';

import { ChangeMail } from '../../modais/changeMail';
import { ChangePassoword } from '../../modais/changePassword';

import {
  Container,
  Form,
  Separate,
  Confirm,
  ButtonDeleteAccount,
  Info,
} from './styles';

import { useAuth } from '../../hooks/auth';
import { InputFileAvatar } from '../../components/InputFileAvatar';
import { useModal } from '../../hooks/modal';
import { birthdayMask, phoneMask } from '../../utils/inputMasks';
import { getValidationErrors } from '../../utils/getValidationErrors';
import { formatDateForServer } from '../../utils/formatDate';
import { api } from '../../services/api';
import { useToast } from '../../hooks/toast';
import { formatName } from '../../utils/formatName';
import { DeleteAccount } from '../../modais/deleteAccount';

interface FormProps {
  fullname: string;
  nickname: string;
  phone?: string;
  birthday?: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user, updateUser } = useAuth();
  const { addModal } = useModal();
  const { addToast } = useToast();

  const [formatedBirtday, setFormatedBirtday] = useState('');
  const [formatedPhone, setFormatedPhone] = useState('');

  const handleSubmit = useCallback(
    async (data: FormProps): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          fullname: Yup.string().required('Compo obrigatório'),
          nickname: Yup.string().required('Compo obrigatório'),
          phone: Yup.string()
            .nullable()
            .transform((value: string, o: string) => (o === '' ? null : value))
            .min(10, 'Insira o seu telefone com DDD'),
          birthday: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const response = await api.put('users/profile', {
          fullname: data.fullname,
          nickname: data.nickname,
          phone: phoneMask(data?.phone, true),
          birthday: formatDateForServer(data.birthday),
        });

        updateUser(response.data);

        addToast({
          type: 'success',
          title: 'Dados do perfil atualizados com sucesso',
        });

        // history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização dos dados',
          description:
            'Houve um erro na atualização dos dados. Tente novamente mais tarde.',
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    if (user?.birthday) {
      setFormatedBirtday(birthdayMask(user.birthday));
    }
    if (user?.phone) {
      setFormatedPhone(phoneMask(user.phone));
    }
  }, [user]);

  return (
    <Painel>
      <Container>
        <h3>Perfil</h3>
        <Form
          ref={formRef}
          initialData={{ fullname: user?.fullname, nickname: user?.nickname }}
          onSubmit={handleSubmit}
        >
          <InputFileAvatar name="avatar" defaultFile={user?.avatar_url} />
          <Info text="Imagem de até 5mb." />
          <Separate label="Informações gerais" />
          <InputText name="fullname" label="Nome completo" />
          <InputText name="nickname" label="Apelido" />
          <InputText
            name="phone"
            label="Telefone"
            type="phone"
            maxLength={11}
            defaultValue={formatedPhone}
            onBlur={() => {
              formRef.current?.setFieldValue(
                'phone',
                phoneMask(formRef.current?.getFieldValue('phone')),
              );
            }}
            onChange={() => {
              formRef.current?.setFieldValue(
                'phone',
                formRef.current?.getFieldValue('phone').replace(/\D/g, ''),
              );
            }}
          />
          <InputText
            name="birthday"
            label="Data de nascimento"
            defaultValue={formatedBirtday}
            type="date"
          />

          <Separate label="Segurança" />

          <ButtonSecurity
            name="changeEmail"
            value="Alterar email"
            type="button"
            onClick={() =>
              addModal(
                <ChangeMail
                  fullname={formatName(user.fullname)}
                  avatar_url={user.avatar_url}
                  email={user.email}
                />,
              )
            }
          />
          <ButtonSecurity
            name="changePassword"
            value="Alterar senha"
            type="button"
            onClick={() =>
              addModal(
                <ChangePassoword
                  fullname={formatName(user.fullname)}
                  avatar_url={user.avatar_url}
                />,
              )
            }
          />

          <Separate label="Gerenciamento da conta" />

          <ButtonDeleteAccount
            name="DeleteAccount"
            value="Apagar conta"
            type="button"
            arrowRight={false}
            onClick={() => addModal(<DeleteAccount />)}
          />

          <Confirm name="submit" type="submit" value="Confirmar alterações" />
        </Form>
      </Container>
    </Painel>
  );
};

export { Profile };
