import React, { InputHTMLAttributes } from 'react';

import { Container } from './styles';

import camera from './assets/camera.svg';

interface buttonProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const ButtonChangeImage: React.FC<buttonProps> = ({ name, ...rest }) => {
  return (
    <Container {...rest}>
      <img src={camera} alt="icon" />
    </Container>
  );
};

export { ButtonChangeImage };
