import { FormHandles } from '@unform/core';
import React, { useCallback, useRef, useState } from 'react';
import { CustomDialog, useDialog } from 'react-st-modal';
import * as yup from 'yup';

import { HeaderModal1 } from '../../../components/HeaderModal1';
import { InputText } from '../../../components/InputText';
import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';
import { api } from '../../../services/api';
import { getValidationErrors } from '../../../utils/getValidationErrors';

import { ForgotPassword2 } from '../forgotPassword2';

import { Container, FormContainer, Info, Submit } from './styles';

interface formProps {
  email: string;
}

const ForgotPassword1: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: formProps) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);

        const schema = yup.object().shape({
          email: yup
            .string()
            .email('Insira um email válido.')
            .required('Campo obrigatório.'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.post('users/password/forgot', {
          email: data.email,
        });

        addToast({
          type: 'info',
          title: 'Token enviado',
          description: 'Verifique no seu email e na caixa de span.',
        });

        setLoading(false);
        removeModal();
        addModal(<ForgotPassword2 email={data.email} />);
      } catch (err: any) {
        setLoading(false);

        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          console.log(errors);
          formRef.current?.setErrors(errors);
          return;
        }

        if (err?.response?.status === 404) {
          addToast({
            type: 'error',
            title: 'Email náo cadastrado',
            description:
              'Esse email não está cadastrado na nossa plataforma. Entre em contato com a sua instituição para realizar o cadastro.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na requisição',
          description:
            'Houve um erro na requisição realizada. Tente novamente mais tarde.',
        });
      }
    },
    [addModal, addToast, removeModal],
  );

  return (
    <Container>
      <HeaderModal1 title="Recuperar senha" />
      <Info text="Insira o email cadastrado. Fique atento, um token será enviado ao seu novo email." />

      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <InputText label="Insira o seu email cadastrado" name="email" />

        <Submit name="submit" value="Solicitar token" loading={loading} />
      </FormContainer>
    </Container>
  );
};

export { ForgotPassword1 };
