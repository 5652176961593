import { Form } from '@unform/web';
import styled from 'styled-components';
import { ButtonConfirm } from '../../components/ButtonConfirm';
import { InputText } from '../../components/InputText';
import { TextInfo } from '../../components/TextInfo';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  background-color: #f5f5f5;
`;

export const Info = styled(TextInfo)`
  margin: 10px 0;
`;

export const FormContainer = styled(Form)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: calc(100% - 40px);

  margin: 10px auto;
  grid-gap: 5px;

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: flex-start;

    width: calc(100% - 40px);
    margin: 10px auto;
  }
`;

export const ContainerInline = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Submit = styled(ButtonConfirm)`
  align-self: center;
  margin: 30px 0;
`;

export const Content = styled.div`
  width: calc(100% - 40px);

  margin: 10px auto;
  height: 250px;
  overflow-y: scroll;

  display: grid;
  grid-gap: 10px;

  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;

  @media (max-width: 1199px) {
    grid-template-columns: 1fr;
  }
`;
