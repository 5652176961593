import { useField } from '@unform/core';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AiFillCaretRight } from 'react-icons/ai';

import {
  ContainerButton,
  ContainerInput,
  ContainerMidia,
  Input,
  Button,
  ContainerContainerMidia,
  Player,
} from './styles';

import noMidia from '../../assets/no-midia.png';
import cameraImage from './assets/camera.svg';
import trashImage from './assets/trash.svg';

import { api } from '../../services/api';
import { useToast } from '../../hooks/toast';
import { TextInfo } from '../TextInfo';
import { Loading } from '../Loading';

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;

  id?: string;
  style?: any;
  defaultFile?: string;
  video_url?: string;
}

const InputFileExercise: React.FC<inputProps> = ({
  id,
  name,
  style,
  defaultFile,
  video_url,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { addToast } = useToast();

  const [file, setFile] = useState<string | undefined>(defaultFile);
  const [video, setVideo] = useState<string | undefined>(video_url);
  const [loading, setloading] = useState(false);

  const { defaultValue, fieldName, error, registerField } = useField(name);

  const supportedFileTypes = [
    '.png',
    '.jpg',
    '.jpeg',
    '.mp4',
    '.avi',
    'quicktime',
  ];

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setFile(defaultFile);
    setVideo(video_url);
  }, [defaultFile]);

  const handleChangeFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setloading(true);
      try {
        if (e.target.files) {
          const data = new FormData();

          if (e.target.files[0]) {
            const [, type] = e.target.files[0].type.split('/');

            if (
              !supportedFileTypes.some(x =>
                x.toUpperCase().includes(type.toUpperCase()),
              )
            ) {
              addToast({
                type: 'error',
                title: `Arquivo ${type} não suportado`,
                description: `Os tipos de arquivos suportados são ${supportedFileTypes.join(
                  ', ',
                )}`,
              });
              setloading(false);
              return;
            }
          }

          data.append('file', e.target.files[0]);

          const response = await api.patch(`exercises/midia/${id}`, data, {
            headers: {
              identificator: 'training-midia',
            },
          });

          setFile(response.data.thumbnail_url);
          setVideo(response.data.video_url);

          setloading(false);
          addToast({
            type: 'success',
            title: 'Upload realizado com sucesso.',
          });
        }
      } catch (err: any) {
        setloading(false);

        if (err?.response?.status === 413) {
          addToast({
            type: 'error',
            title: 'Arquivo muito grande',
            description:
              'O arquivo enviado excede o limite permitido. Tente novamente com um arquivo menor.',
          });
          addToast({
            type: 'info',
            title: 'Permitidos arquivos de até 5mb.',
          });

          return;
        }

        if (err?.response?.status === 403) {
          addToast({
            type: 'error',
            title: 'Você não tem permissão para executar essa ação.',
          });
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description:
            'Ocorreu um erro na atualização. tente novamente mais tarde.',
        });
        console.log(err);
      }
    },
    [addToast, id],
  );

  const handleRemoveFile = useCallback(async () => {
    setloading(true);
    try {
      const response = await api.delete(`exercises/midia/${id}`);

      setFile(response.data.thumbnail_url);
      setVideo(response.data.video_url);

      setloading(false);
      addToast({
        type: 'success',
        title: 'Arquivo removido com sucesso.',
      });
    } catch (err: any) {
      setloading(false);

      if (err?.response?.status === 403) {
        addToast({
          type: 'error',
          title: 'Você não tem permissão para executar essa ação.',
        });
        return;
      }

      addToast({
        type: 'error',
        title: 'Erro na atualização',
        description:
          'Ocorreu um erro na atualização. tente novamente mais tarde.',
      });
    }
  }, [addToast, id]);

  return (
    <>
      <ContainerInput style={style}>
        <ContainerContainerMidia>
          {loading ? (
            <ContainerMidia>
              <Loading no_shadow={loading} />
            </ContainerMidia>
          ) : (
            <>
              {video && (
                <Player onClick={() => window.open(video)}>
                  <AiFillCaretRight color="#f5f5f580" size={40} />
                </Player>
              )}
              <ContainerMidia>
                <img src={file || noMidia} alt="midia exercise" />
              </ContainerMidia>
            </>
          )}
        </ContainerContainerMidia>

        <ContainerButton>
          <label htmlFor="file">
            <Button
              name="changeImage"
              value={!file ? 'Enviar Arquivo' : 'Alterar Arquivo'}
              imageMobile={cameraImage}
            />
          </label>

          <Button
            name="removeImage"
            value="Remover Arquivo"
            onClick={handleRemoveFile}
            imageMobile={trashImage}
            removeStyle
          />
        </ContainerButton>

        <Input
          id="file"
          type="file"
          accept={supportedFileTypes.join(',')}
          ref={inputRef}
          defaultValue={defaultValue}
          onChange={handleChangeFile}
          {...rest}
        />
      </ContainerInput>
      {/* <TextInfo text="Imagens de até 5mb." /> */}
    </>
  );
};

export { InputFileExercise };
