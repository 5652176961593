import styled, { css } from 'styled-components';
import { IconBaseProps } from 'react-icons';
import { shade } from 'polished';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div``;

export const ContainerInput = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding: 0;
  padding: 5px 0;
  border-bottom: 1px solid #f5f5f5cc;
  transition: border-color 0.5s;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #ff9000;
      border-color: #ff9000;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #ff9000;
      border-color: #ff9000;
    `}
`;

export const Label = styled.span`
  font-size: 11.5px;
  color: #f5f5f5cc;

  margin-bottom: 5px;
`;

export const Icon = styled.img<IconBaseProps>``;

export const Input = styled.input`
  display: flex;
  flex: 1;
  border: none;
  background-color: transparent;

  color: #f5f5f5;

  font-size: 20px;
  font-weight: 500;
`;

export const ContainerError = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;

  svg {
    margin-right: 5px;
  }

  span {
    font-size: 15px;
    color: #c53030;
  }
`;
