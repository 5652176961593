import React, { ButtonHTMLAttributes, InputHTMLAttributes } from 'react';

import { Container } from './styles';

import closeImg from './assets/close.svg';
import closeImgWhite from './assets/close-white.svg';

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  value?: string;
  lightMode?: boolean;
}

const ButtonFlat: React.FC<buttonProps> = ({
  name,
  value = 'close',
  lightMode = false,
  ...rest
}) => {
  return (
    <Container type="button" value={value} {...rest}>
      <img src={lightMode ? closeImgWhite : closeImg} alt="Close button" />
    </Container>
  );
};

export { ButtonFlat };
