import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Container,
  Button,
  ButtonNewTraining,
  ContainerButtonsTrainings,
} from './styles';
import { useAuth } from '../../hooks/auth';

import addImage from './assets/add.svg';
import { useToast } from '../../hooks/toast';

interface buttonProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
  username: string;
  day?: number;
  lastTraining?: number;
}

const ButtonsDaysOfWeek: React.FC<buttonProps> = ({
  name,
  day,
  username,
  lastTraining = 0,
  ...rest
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const maxTrainings = 6;

  const [qtdTrainings, setQtdTrainings] = useState(lastTraining);

  const handleAddTraining = useCallback(() => {
    if (qtdTrainings < maxTrainings) {
      if (lastTraining >= qtdTrainings) {
        setQtdTrainings(qtdTrainings + 1);
      } else {
        addToast({
          type: 'error',
          title: 'Adicione exercícios no último plano de treino criado.',
        });
      }
    } else {
      addToast({
        type: 'error',
        title: 'Quantidade máxima atingida.',
      });
    }
  }, [addToast, lastTraining, qtdTrainings]);

  // TODO
  // criar botão para remover o ultimo dia de treino
  // se vazio, remove o ultimo sem perguntar
  // se tem algum treino, confirma e remove

  useEffect(() => {
    setQtdTrainings(lastTraining);
  }, [lastTraining]);

  const data = [
    {
      index: 0,
      labelDay: 'D',
      labelABC: 'A',
    },
    {
      index: 1,
      labelDay: 'S',
      labelABC: 'B',
    },
    {
      index: 2,
      labelDay: 'T',
      labelABC: 'C',
    },
    {
      index: 3,
      labelDay: 'Q',
      labelABC: 'D',
    },
    {
      index: 4,
      labelDay: 'Q',
      labelABC: 'E',
    },
    {
      index: 5,
      labelDay: 'S',
      labelABC: 'F',
    },
    {
      index: 6,
      labelDay: 'S',
      labelABC: 'G',
    },
  ];

  return (
    <Container>
      <ContainerButtonsTrainings>
        {data
          .filter(button => button.index <= qtdTrainings)
          .map(button => {
            //
            return (
              <Button
                key={button.index}
                active={day === button.index}
                to={`/users/${username}/${button.index}`}
              >
                <span>
                  {user?.gym?.training_type === 'ABC'
                    ? button.labelABC
                    : button.labelDay}
                </span>
              </Button>
            );
          })}
      </ContainerButtonsTrainings>
      <ButtonNewTraining
        name="add"
        value="Adicionar treino"
        image={addImage}
        onClick={handleAddTraining}
      />
    </Container>
  );
};

export { ButtonsDaysOfWeek };
