import React, { useEffect } from 'react';
import {
  Navigate,
  useLocation,
  RouteProps,
  RouterProps,
} from 'react-router-dom';

import { IUser, useAuth } from '../hooks/auth';
import { useToast } from '../hooks/toast';

interface IRouteProps {
  isPrivate?: boolean;
  component: React.ReactElement;
  minLevelRequired?: number;
}

const AuthRoute: React.FC<IRouteProps> = ({
  isPrivate = false,
  minLevelRequired = 1,
  component,
  ...rest
}) => {
  const { pathname } = useLocation();
  const { addToast } = useToast();

  const user = localStorage.getItem('@SuaAcademia:user');

  if (!user) {
    return <Navigate to="/" replace />;
  }

  const parsedUser = JSON.parse(user) as IUser;

  if (minLevelRequired !== 1) {
    if (parsedUser.level_access < minLevelRequired) {
      addToast({
        type: 'error',
        title: 'Acesso negado.',
        description: `Você não tem acesso a essa página.`,
      });

      return <Navigate to="/dashboard" />;
    }
  }

  return component;
};

export { AuthRoute };
