import { Form } from '@unform/web';
import styled from 'styled-components';
import { ButtonConfirm } from '../../components/ButtonConfirm';
import { InputText } from '../../components/InputText';
import { TextInfo } from '../../components/TextInfo';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  background-color: #f5f5f5;
`;

export const Info = styled(TextInfo)`
  margin: 10px 0;
`;

export const FormContainer = styled(Form)`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  width: calc(100% - 40px);

  margin: 10px auto;
  grid-gap: 5px;

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: flex-start;

    width: calc(100% - 40px);
    margin: 10px auto;
  }
`;

export const Label = styled.label`
  font-size: 20px;
  font-weight: 400;
`;

export const ContainerInline = styled.div`
  width: 60%;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1199px) {
    flex-direction: column;
    row-gap: 5px;

    width: 100%;
  }
`;

export const Submit = styled(ButtonConfirm)`
  align-self: center;
  margin: 30px 0;
`;
