import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 65px;
  height: 30px;

  top: -5px;
  right: 0;

  border-radius: 20px;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #e00000;
  cursor: pointer;

  @keyframes show-button {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: show-button 0.3s;

  &:hover {
    background-color: ${shade(0.2, '#e00000')};
  }

  & > span {
    font-size: 12px;
    font-weight: 500;
    color: #f5f5f5;

    text-align: center;
  }
`;
