const phoneMask = (value: string | undefined, unmasked = false) => {
  if (!value) {
    return '';
  }

  const phone = value.replace(/\D/g, '');

  if (unmasked) {
    return phone;
  }

  if (phone.length === 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1 $2-$3');
  }

  if (phone.length >= 11) {
    return phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '$1 $2 $3-$4');
  }

  return value;
};

const birthdayMask = (value: Date) => {
  const date = new Date(value);
  return `${date.getFullYear()}-${date.getMonth() < 10 ? '0' : ''}${
    date.getMonth() + 1
  }-${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
};

export { phoneMask, birthdayMask };
