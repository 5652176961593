import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin: 0 10px;
  }

  @media (max-width: 1199px) {
    width: calc(100% - 40px);
    margin: auto;

    & > * {
      margin: 3px;
    }
  }
`;
