import React from 'react';

import { AuthProvider } from './auth';
import { ModalProvider } from './modal';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({ children }) => {
  return (
    <ToastProvider>
      <AuthProvider>
        <ModalProvider>{children}</ModalProvider>
      </AuthProvider>
    </ToastProvider>
  );
};

export { AppProvider };
