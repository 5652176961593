import React from 'react';

import loadingLottie from '../../assets/loading.json';

import logo from '../../assets/logo.svg';

import { Container, LogoImg, Lottie } from './styles';

interface loadingProps {
  no_shadow?: boolean;
}

const Loading: React.FC<loadingProps> = ({ no_shadow = false }) => {
  return (
    <Container className="loading" no_shadow={no_shadow}>
      {/* <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loadingLottie,
        }}
        width={100}
        height={100}
      /> */}

      <LogoImg no_shadow={no_shadow} src={logo} alt="logo img" />
    </Container>
  );
};

export { Loading };
