import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 300px;
  height: 80px;
  border-radius: 5px;

  display: flex;
  position: relative;
  align-items: center;

  background: #f5f5f5;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  transition: background-color 0.5s;

  &:hover {
    background-color: ${shade(0.2, '#c4c4c480')};
  }

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 90%;
  height: 90%;
  margin: auto;
`;

export const Title = styled.span`
  font-size: 16px;
  color: #151515;
`;

export const Subtitle = styled.span`
  font-size: 13px;
  color: #151515;
`;
