/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { api } from './api';

export interface IMuscleGroup {
  id: string;
  name: string;
  is_main: boolean;
  created_at?: Date;
  updated_at?: Date;
}

export interface IExercise {
  id: string;
  name: string;
  thumbnail_url?: string | undefined;
  video_url?: string | undefined;
  muscle_groups: IMuscleGroup[];
  description?: string;
}

class ExercisesService {
  private apiService = api;

  public async getMuscleGroups() {
    return this.apiService.get<IMuscleGroup[]>('exercises/muscle_groups');
  }

  public async getExercisesOfGym() {
    return this.apiService.get<IExercise[]>('exercises');
  }

  public async createExercise(data: Omit<IExercise, 'id'>) {
    return this.apiService.post(`exercises`, {
      name: data.name,
      muscle_groups: data.muscle_groups,
      description: data.description || undefined,
    });
  }

  public async updateExercise(data: IExercise) {
    return this.apiService.put(`exercises/${data.id}`, {
      name: data.name,
      muscle_groups: data.muscle_groups,
      description: data.description || undefined,
    });
  }
}

export { ExercisesService };
