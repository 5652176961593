import { Form as FormComponent } from '@unform/web';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { InputText } from '../../components/InputText';
import { SeparateLabel } from '../../components/SeparateLabel';

export const Container = styled.div`
  min-width: 1200px;
  max-width: 1400px;

  min-height: 100vh;

  margin: 0 auto;
  padding: 50px 0;

  display: flex;
  background-color: #313131;

  justify-content: space-evenly;
  align-items: flex-start;

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;

    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);

    margin: 0 auto;
  }
`;

// Info
export const ContainerInfo = styled.div`
  max-width: calc(45% - 40px);
  margin: 0 20px;

  color: #f5f5f5;

  @media (max-width: 1199px) {
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
    margin: 0;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
  }

  h2 {
    text-align: center;
    font-size: 28px;
    font-weight: 400;
  }

  @media (max-width: 1199px) {
    h2 {
      width: calc(100vw - 40px);
    }
  }
`;

export const TextInfo = styled.div`
  margin-top: 50px;

  p {
    font-size: 21px;
    font-weight: 300;
    margin-top: 20px;

    span {
      color: #ff955f;
      font-weight: 400;
    }
  }

  @media (max-width: 1199px) {
    p {
      width: calc(100vw - 40px);
      margin: 20px auto;
    }
  }
`;

export const ImageInfo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  img {
    height: 300px;
  }

  @media (max-width: 1199px) {
    img {
      display: none;
    }
  }
`;

// Register
export const ContainerRegister = styled.div`
  max-width: 650px;
  min-width: 400px;
  width: 650px;

  margin-right: 20px;

  border-radius: 8px;

  padding: 40px 30px;

  background-color: #151515;

  @media (max-width: 1199px) {
    width: calc(100vw - 100px);
    max-width: calc(100vw - 100px);
    min-width: calc(100vw - 100px);

    margin: 0;
  }
`;

export const Form = styled(FormComponent)`
  display: flex;
  flex-direction: column;
`;

export const Label = styled(SeparateLabel)`
  margin: 10px 0;
  span {
    color: #f5f5f580;
  }
  div {
    background-color: #f5f5f580;
  }
`;

export const Input = styled(InputText)`
  color: #151515;

  &::placeholder {
    font-size: 18px;
  }
`;

export const ContainerCheckboxBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:not(& + &) {
    margin-top: 15px;
  }

  & + & {
    margin-top: 5px;
  }

  label {
    margin-left: 10px;
    font-size: 16px;

    color: #f5f5f5;

    a {
      font-size: 16px;
      color: #f5f5f5;
    }

    &.black-ops {
      font-family: 'Black Ops One', cursive;
      margin-left: 0;
    }
  }
`;

export const ButtonSubmit = styled(Button)`
  max-width: 400px;
  margin: 30px auto 0;
`;
