import { shade } from 'polished';
import styled, { css } from 'styled-components';

import imgMenu from './assets/menu.svg';
import imgLeft from './assets/left.svg';

export const ContainerMenu = styled.div``;

export const Container = styled.div`
  background-color: #f5f5f5;
  margin: auto;

  position: relative;

  box-shadow: 0px 3px 10px #15151580;

  min-width: 1200px;
  max-width: 1400px;
  // 170px é a altura do header mobile
  height: calc(100vh - 170px);
  min-height: none;

  display: flex;

  z-index: 10;

  scroll-behavior: smooth;

  @media (max-width: 1199px) {
    min-width: 100%;
    max-width: 100%;
    // 80px é a altura do header mobile
    height: none;
    min-height: calc(100vh - 80px);

    box-shadow: none;

    position: initial;
  }
`;

export const Overlay = styled.div`
  position: absolute;

  z-index: 99;

  width: 100%;
  height: 100%;

  background-color: #15151580;

  @media (max-width: 1199px) {
    display: absolute;
    position: fixed;

    height: 100vh;
    width: 100vw;

    top: 0;
    left: 0;
  }
`;

interface IMenuProps {
  activeMenu: boolean;
}

export const InfoProfile = styled.div<IMenuProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: center;

  transition: width 0.5s, height 0.5s, margin-top 0.5s;

  ${props =>
    props.activeMenu
      ? css`
          margin-top: -35px;
        `
      : css`
          margin-top: 0px;
        `}

  .container-avatar {
    display: flex;
    margin: 8px 0;

    justify-content: center;

    overflow: hidden;

    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

    border-radius: 50%;

    ${props =>
      props.activeMenu
        ? css`
            width: 100px;
            height: 100px;
          `
        : css`
            width: 40px;
            height: 40px;
          `}
  }

  img {
    cursor: pointer;

    width: auto;
    height: 100%;

    border-radius: 5px;
  }

  h2 {
    font-size: 21px;
    color: #151515;
    font-weight: 500;
    text-align: center;

    ${props =>
      props.activeMenu
        ? css`
            display: block;
          `
        : css`
            display: none;
          `}
  }

  h3 {
    font-size: 13px;
    color: #151515;
    font-weight: 500;
    text-align: center;

    ${props =>
      props.activeMenu
        ? css`
            display: block;
          `
        : css`
            display: none;
          `}
  }
`;

export const ButtonMenu = styled.button<IMenuProps>`
  border: none;

  background-color: #9f9e9e;

  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;

  min-width: 50px;
  min-height: 50px;

  transition: background-color 0.5s;

  z-index: 10;

  &:hover {
    background-color: ${shade(0.5, '#9f9e9e')};
  }

  ${props =>
    props.activeMenu
      ? css`
          background-image: url(${imgLeft});
          align-self: flex-end;
          background-color: transparent;
        `
      : css`
          background-image: url(${imgMenu});
          align-self: flex-end;
        `}
`;

export const ButtonMenuMobile = styled.button<IMenuProps>`
  border: none;
  display: none;

  background-color: transparent;

  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;

  min-width: 50px;
  min-height: 50px;

  transition: background-color 0.5s;

  &:hover {
    background-color: ${shade(0.5, '#9f9e9e')};
  }

  ${props =>
    props.activeMenu
      ? css`
          background-image: url(${imgLeft});
          align-self: flex-end;
          background-color: transparent;
        `
      : css`
          background-image: url(${imgMenu});
          align-self: flex-end;
        `}

  @media (max-width: 1199px) {
    display: flex;

    position: absolute;
    top: 20px;
    left: 10px;
  }
`;

export const DropDown = styled.div<IMenuProps>`
  ${props =>
    props.activeMenu
      ? css`
          width: 250px;
        `
      : css`
          width: 50px;
        `}

  background-color: #c4c4c4;
  display: flex;
  /* display: none; */

  // 170px é a altura do header
  /* height: calc(100vh - 170px); */

  align-items: center;
  flex-direction: column;

  z-index: 100;

  transition: width 0.5s, opacity 0.5s, height 0.5s, min-width 0.5s;

  #opts {
    width: 100%;

    display: flex;
    flex: 1;

    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    overflow-y: auto;
    overflow-x: hidden;

    a {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    a:link,
    a:visited {
      text-decoration: none;
      color: #151515;
    }

    ${props =>
      props.activeMenu
        ? css`
            display: flex;
          `
        : css`
            display: none;
          `}

    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > span {
        font-family: 'Black Ops One', cursive;
        color: #f5f5f5;
        font-size: 21px;

        margin: 20px 0;

        text-shadow: -1px 3px 5px #15151580;
      }
    }
  }

  @media (max-width: 1199px) {
    height: 100%;

    display: flex;
    position: fixed;

    top: 0;
    left: 0;

    ${props =>
      props.activeMenu
        ? css`
            width: 250px;
            left: 0px;
          `
        : css`
            width: 50px;
            left: -50px;
          `}
  }
`;

export const Divisor = styled.div`
  max-width: 90%;

  margin: 10px 0;

  height: 1.5px;
  background-color: #15151580;
`;

export const ChildrenContent = styled.div`
  overflow-x: hidden;
  transition: width 0.5s;

  flex: 1;

  @media (max-width: 1199px) {
    overflow-x: visible;
  }
`;
