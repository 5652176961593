import styled, { css } from 'styled-components';
import { ButtonFlat } from '../ButtonFlat';

interface IContainerProps {
  isMain: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;

  height: auto;
  width: min-content;

  box-shadow: 2px 2px 4px #15151580;

  ${props =>
    props.isMain
      ? css`
          border-left: 3px solid #ff955f;
          div span {
            font-weight: 500;
          }
        `
      : css`
          border-left: 3px solid #15151580;
        `}
`;

export const ContainerLabel = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
`;

export const Label = styled.span`
  font-size: 15px;
  color: #15151580;
  margin: 0 10px;
`;

export const ButtonClose = styled(ButtonFlat)`
  img {
    transform: scale(0.7);
  }
`;
