import React, { ButtonHTMLAttributes } from 'react';

import { Container, ContainerImg, ContainerName } from './styles';

import noUser from '../../assets/no-avatar.jpg';
import key0 from '../../assets/access0.svg';
import key1 from '../../assets/access1.svg';
import key2 from '../../assets/access2.svg';
import key4 from '../../assets/access.svg';
import { formatName } from '../../utils/formatName';

const key = [key0, key1, key2, key4];
interface cardUserProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  id: string;
  username: string;
  avatar?: string;
  level?: number;
}

const CardUser: React.FC<cardUserProps> = ({
  name,
  id,
  level = 0,
  username,
  avatar,
  ...rest
}) => {
  const nameFormatted = formatName(name);

  return (
    <Container to={`/users/${username}/0`}>
      <ContainerImg>
        <img src={avatar || noUser} alt="img user" />
      </ContainerImg>
      <ContainerName>
        <span>{nameFormatted}</span>
        <img src={level > 2 ? key[3] : key[level]} alt="access level" />
      </ContainerName>
    </Container>
  );
};

export { CardUser };
