import { useField } from '@unform/core';
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ContainerInput, Input } from './styles';

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
}

const InputCheckboxBox: React.FC<inputProps> = ({ name, label, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { defaultValue, fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
    setIsChecked(!!inputRef.current?.checked);
  }, [fieldName, registerField]);

  const handleClickCheckbox = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.checked = !inputRef.current.checked;
      setIsChecked(!!inputRef.current.checked);
    }
  }, [isChecked]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.checked);
  }, []);

  return (
    <ContainerInput onClick={handleClickCheckbox}>
      <Input
        ref={inputRef}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        isChecked={isChecked}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        onClick={handleClickCheckbox}
        type="checkbox"
        {...rest}
      />

      {/* {error && <img src={errorImg} alt="img error" />} */}
    </ContainerInput>
  );
};

export { InputCheckboxBox };
