const data = [
  {
    index: 0,
    labelDay: 'D',
    labelABC: 'A',
    labelDayOfWeek: 'Domingo',
  },
  {
    index: 1,
    labelDay: 'S',
    labelABC: 'B',
    labelDayOfWeek: 'Segunda-feira',
  },
  {
    index: 2,
    labelDay: 'T',
    labelABC: 'C',
    labelDayOfWeek: 'Terça-feira',
  },
  {
    index: 3,
    labelDay: 'Q',
    labelABC: 'D',
    labelDayOfWeek: 'Quarta-feira',
  },
  {
    index: 4,
    labelDay: 'Q',
    labelABC: 'E',
    labelDayOfWeek: 'Quinta-feira',
  },
  {
    index: 5,
    labelDay: 'S',
    labelABC: 'F',
    labelDayOfWeek: 'Sexta-feira',
  },
  {
    index: 6,
    labelDay: 'S',
    labelABC: 'G',
    labelDayOfWeek: 'Sábado',
  },
  {
    index: '',
    labelDay: '-',
    labelABC: '-',
    labelDayOfWeek: '-',
  },
];

const formatDayOfWeek = (day: number | undefined) => {
  switch (day) {
    case 0:
      return data[0];
    case 1:
      return data[1];
    case 2:
      return data[2];
    case 3:
      return data[3];
    case 4:
      return data[4];
    case 5:
      return data[5];
    case 6:
      return data[6];
    default:
      return data[7];
  }
};

export { formatDayOfWeek };
