import React, { ButtonHTMLAttributes } from 'react';

import { Container, ContainerImg, ContainerName } from './styles';

import pixLogo from './assets/pix-logo.svg';

interface cardPaymentMethodProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  method?: 'pix';
}

const methodLogo = {
  pix: pixLogo,
};

const CardPaymentMethod: React.FC<cardPaymentMethodProps> = ({
  name,
  method,
  ...rest
}) => {
  return (
    <Container {...rest}>
      {method && (
        <ContainerImg>
          <img src={methodLogo[method]} alt="payment method logo" />
        </ContainerImg>
      )}

      <ContainerName>
        <span>{name}</span>
      </ContainerName>
    </Container>
  );
};

export { CardPaymentMethod };
