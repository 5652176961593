import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 } from 'uuid';
import { ModalContainer } from '../components/ModalContainer';

interface ModalContextData {
  addModal(modal: React.ReactNode): any;
  removeModal(): any;
  removeAllModals(): any;
  modals: ModalProps[];
}

interface ModalProps {
  id: string;
  modal: React.ReactNode;
  valueReturn?: any;
  isOpen: boolean;
}

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

const ModalProvider: React.FC = ({ children }) => {
  const [modals, setModals] = useState<ModalProps[]>([]);

  const addModal = useCallback(modal => {
    const newModal = {
      id: v4(),
      modal,
      isOpen: true,
    } as ModalProps;

    setModals(state => [...state, newModal]);

    return newModal;
  }, []);

  const removeModal = useCallback(() => {
    const modal = modals.find(
      current => current.id === modals[modals.length - 1].id,
    );

    if (modal) {
      modal.isOpen = false;
    }

    setModals(state =>
      state.filter(currentModal => currentModal.id !== modal?.id),
    );
  }, [modals]);

  const removeAllModals = useCallback(() => {
    setModals([]);
  }, []);

  // useEffect(() => {
  //   console.log(modals);
  // }, [modals]);

  return (
    <ModalContext.Provider
      value={{ addModal, removeModal, removeAllModals, modals }}
    >
      {modals.map(modal => (
        <ModalContainer key={modal.id} id={modal.id}>
          {modal.modal}
        </ModalContainer>
      ))}
      {children}
    </ModalContext.Provider>
  );
};

function useModal(): ModalContextData {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
}

export { ModalProvider, useModal };
