import React, { useEffect, useState } from 'react';

import { animated, useTransition } from 'react-spring';
import Toast from './Toast';

import { ToastMessage } from '../../hooks/toast';
import { Container } from './styles';

interface ToastContainerProps {
  messages: ToastMessage[];
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  const [items, setItems] = useState([
    { id: 1, title: 'Item1' },
    { id: 2, title: 'Item2' },
  ]);
  const transition = useTransition(messages, {
    from: { right: '-120%', opacity: 0 },
    enter: { right: '0%', opacity: 1 },
    leave: { right: '-120%', opacity: 0 },
  });

  const fragment = transition((style, item) => {
    return <Toast style={style} message={item} />;
  });

  return <Container>{fragment}</Container>;
};

export default ToastContainer;
