import { animated } from 'react-spring';
import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  display: flex;

  height: 100%;
  width: 100%;

  flex: 1;

  justify-content: center;
  align-items: center;

  background-color: #15151580;

  z-index: 500;

  @media (max-width: 1199px) {
    padding: 0;
  }
`;

export const Container = styled(animated.div)`
  position: absolute;
  overflow: hidden;

  min-width: 700px;
  max-width: 900px;

  max-height: 90vh;
  overflow-y: auto;

  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  background-color: #15151580;

  z-index: 1000;

  @media (max-width: 1199px) {
    min-width: 90%;
    max-width: 90%;
  }
`;
