import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Link } from 'react-router-dom';
import { ButtonCircle } from '../ButtonCircle';

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  margin-bottom: 15px;
`;

interface buttonProps {
  active?: boolean;
}

export const Button = styled(Link)<buttonProps>`
  min-width: 100px;
  height: 32px;

  margin-right: 10px;

  display: flex;

  justify-content: center;
  align-items: center;

  border: none;

  text-decoration: none;

  ${props =>
    props.active
      ? css`
          background-color: #ff955f;
          color: #f5f5f5;

          &:hover {
            background-color: ${shade(0.2, '#ff955f')};
          }
        `
      : css`
          background-color: #c4c4c4;
          color: #151515;
          &:hover {
            background-color: ${shade(0.2, '#c4c4c4')};
          }
        `}

  font-size: 32px;

  border-radius: 15px;

  transition: background-color 0.2s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  span {
    font-size: 20px;
  }

  @media (max-width: 1199px) {
    width: 32px;
    min-width: initial;
    border-radius: 50%;
  }
`;

export const ButtonNewTraining = styled(ButtonCircle)`
  width: 32px !important;
  min-width: 32px !important;
  height: 32px !important;

  border-radius: 50%;
  margin-left: 10px;

  /* transition: max-width 0.1s; */
  /* transition: width 0.1s; */

  &:hover {
    width: auto !important;
    min-width: auto !important;
    /* max-width: 1000px !important; */
    span {
      font-size: 18px;
    }
  }
`;

export const ContainerButtonsTrainings = styled.div`
  display: flex;
  justify-content: flex-start;

  flex: 1;

  @media (max-width: 1199px) {
    justify-content: space-between;
  }
`;
