import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import { CustomDialog, useDialog } from 'react-st-modal';
import * as yup from 'yup';

import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';

import { HeaderModal1 } from '../../../components/HeaderModal1';
import { InputText } from '../../../components/InputText';
import { InputToken } from '../../../components/InputToken';
import { ForgotPassword3 } from '../forgotPassword3';

import { Container, FormContainer, Info, Submit } from './styles';
import { api } from '../../../services/api';
import { getValidationErrors } from '../../../utils/getValidationErrors';

interface formProps {
  c1: string;
  c2: string;
  c3: string;
  c4: string;
  c5: string;
}

interface modalProps {
  email: string;
}

const ForgotPassword2: React.FC<modalProps> = ({ email }) => {
  const formRef = useRef<FormHandles>(null);
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: formProps) => {
      try {
        const code: string = data.c1 + data.c2 + data.c3 + data.c4 + data.c5;

        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          c1: yup.string().required('Campo obrigatório'),
          c2: yup.string().required('Campo obrigatório'),
          c3: yup.string().required('Campo obrigatório'),
          c4: yup.string().required('Campo obrigatório'),
          c5: yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        console.log(code);

        await api.post(
          'users/password/verify-token',
          { email },
          {
            headers: {
              code: code.toUpperCase(),
            },
          },
        );

        removeModal();
        addModal(<ForgotPassword3 code={code.toUpperCase()} />);
      } catch (err: any) {
        if (err instanceof yup.ValidationError) {
          formRef.current?.setErrors({
            c1: 'Required',
            c2: 'Required',
            c3: 'Required',
            c4: 'Required',
            c5: 'Required',
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Token inválido.',
        });
      }
    },
    [addModal, addToast, email, removeModal],
  );

  return (
    <Container>
      <HeaderModal1 title="Recuperar senha" />
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <Info text="Agora, insira abaixo o código recebido no email para confirmação." />
        <InputToken />
        <Info text="Verifique também na caixa de spam." />

        <Submit name="submit" value="Validar email" />
      </FormContainer>
    </Container>
  );
};

export { ForgotPassword2 };
