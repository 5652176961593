import styled, { css } from 'styled-components';
import { IconBaseProps } from 'react-icons';
import { shade } from 'polished';
import ReactSelect from 'react-select';

interface ContainerProps {
  isFocused?: boolean;
  isFilled?: boolean;
  isErrored?: boolean;
  isFlex1Mobile?: boolean;
}

export const ContainerInput = styled.div<ContainerProps>`
  display: flex;

  flex-direction: column;
  padding: 0;
  transition: border-color 0.5s;

  span {
    font-size: 13px;
    color: #151515;
    margin-left: 11px;
    line-height: 25px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #ff9000;
      border-color: #ff9000;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #ff9000;
      border-color: #ff9000;
    `}

  @media (max-width: 1199px) {
    ${props =>
      !props.isFlex1Mobile &&
      css`
        width: 100%;
      `}
  }
`;

export const SelectComponent = styled(ReactSelect)<ContainerProps>`
  /* min-width: 250px; */

  border-radius: 15px;

  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;

  color: #151515;

  font-size: 22px;
  font-weight: 500;

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFilled &&
    css`
      border-color: #ff9000;
    `}

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const ContainerError = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;

  span {
    color: #c53030;
    font-size: 13px;
    margin-left: 10px;
  }
`;
