import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  width: 100%;
  height: 45px;

  display: flex;
  position: relative;

  justify-content: center;
  align-items: center;

  border: none;

  background-color: #f5f5f5;

  color: #f5f5f5;
  font-size: 32px;

  border-radius: 5px;

  transition: background-color 0.2s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${shade(0.2, '#f5f5f5')};
  }

  span {
    font-size: 20px;
    color: #151515;
  }

  img {
    position: absolute;
    right: 5px;
  }
`;
