import styled from 'styled-components';
import { Form as FormContainer } from '@unform/web';
import { CardExercise } from '../../components/CardExercise';

export const Container = styled.div`
  padding: 20px;
  min-height: 100vh;

  @media (max-width: 1199px) {
    width: calc(100% - 40px);
    margin: auto;

    padding: 0px;
  }
`;

export const ContainerProfile = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 15px;
`;

export const ContainerAvatar = styled.div`
  width: 120px;
  height: 120px;

  border-radius: 120px;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    max-height: 100%;
    width: auto;
  }
`;

export const ContainerDataProfile = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: flex-end;
`;

export const ContainerName = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 21px;
    font-weight: 500;
  }

  img {
    margin-left: 10px;
  }
`;

export const ContainerButtonFloat = styled.div`
  position: absolute;

  @media (max-width: 1199px) {
    position: fixed;
  }

  right: 30px;
  bottom: 30px;
`;

export const ContainerLastExercise = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  span {
    font-size: 16px;
  }

  & :first-child {
    font-weight: 500;
    margin-bottom: 5px;
  }
`;

export const Form = styled(FormContainer)`
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: center;

  grid-gap: 5px;

  .line2 {
    grid-gap: 5px;
    display: flex;
  }

  @media (max-width: 1199px) {
    flex-direction: column;

    .line2 {
      width: 100%;

      display: flex;
      justify-content: space-between;
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  margin-top: 10px;

  display: grid;
  grid-gap: 1rem;
  justify-content: space-between;

  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 1199px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 100px;
  }
`;

export const Card = styled(CardExercise)``;
