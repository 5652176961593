import { FormHandles } from '@unform/core';
import React, { useCallback, useRef } from 'react';
import * as yup from 'yup';

import { HeaderModal1 } from '../../../components/HeaderModal1';
import { useModal } from '../../../hooks/modal';
import { useToast } from '../../../hooks/toast';
import { api } from '../../../services/api';
import { formatDateForServer } from '../../../utils/formatDate';
import { getValidationErrors } from '../../../utils/getValidationErrors';
import { phoneMask } from '../../../utils/inputMasks';
import { SignUp3 } from '../signUp3';

import {
  Container,
  FormContainer,
  Info,
  Input,
  Separate,
  Submit,
} from './styles';

interface modalProps {
  fullname?: string;
  nickname?: string;
  email: string;
  gym_id: string;
  code: string;
}

interface formProps {
  fullname: string;
  nickname: string;
  birthday?: string;
  phone?: string;
}

const SignUp2: React.FC<modalProps> = ({
  fullname,
  nickname,
  email,
  gym_id,
  code,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addModal } = useModal();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: formProps): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          fullname: yup.string().required('Compo obrigatório'),
          nickname: yup.string().required('Compo obrigatório'),
          phone: yup
            .string()
            .nullable()
            .transform((value: string, o: string) => (o === '' ? null : value))
            .min(10, 'Insira o seu telefone com DDD'),
          birthday: yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const userData = {
          fullname: data.fullname,
          nickname: data.nickname,
          phone: phoneMask(data?.phone, true),
          birthday: formatDateForServer(data.birthday),
          email,
          gym_id,
          code,
        };

        addModal(
          <SignUp3
            fullname={userData.fullname}
            nickname={userData.nickname}
            email={userData.email}
            gym_id={userData.gym_id}
            code={userData.code}
            birthday={userData.birthday}
            phone={userData.phone}
          />,
        );
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização dos dados',
          description:
            'Houve um erro na atualização dos dados. Tente novamente.',
        });
      }
    },
    [addToast],
  );

  return (
    <Container>
      <HeaderModal1 title="Continuar Cadastro" />
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <Info text="Complete o seu cadastro, inserindo as informações solicitadas abaixo." />

        <Separate label="Informações pessoais" />

        <Input name="fullname" label="Nome completo" defaultValue={fullname} />
        <Input name="nickname" label="Apelido" defaultValue={nickname} />
        <Input name="birthday" label="Data de nascimento" type="date" />

        <Separate label="Informações para contato" />

        <Input
          name="phone"
          label="Telefone"
          type="phone"
          maxLength={11}
          onBlur={() => {
            formRef.current?.setFieldValue(
              'phone',
              phoneMask(formRef.current?.getFieldValue('phone')),
            );
          }}
          onChange={() => {
            formRef.current?.setFieldValue(
              'phone',
              formRef.current?.getFieldValue('phone').replace(/\D/g, ''),
            );
          }}
        />

        <Submit
          name="submit"
          value="Continuar"
          onSubmit={() => formRef.current?.submitForm()}
        />
      </FormContainer>
    </Container>
  );
};

export { SignUp2 };
