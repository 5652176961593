import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ComingSoon } from '../pages/ComingSoon';

import { SignIn } from '../pages/signIn';
import { HelloWorld } from '../pages/helloWorld';

import { Dashboard } from '../pages/dashboard';
import { Exercises } from '../pages/exercises';
import { GymProfile } from '../pages/gymProfile';
import { Profile } from '../pages/profile';
import { UserExercises } from '../pages/userExercises';
import { Users } from '../pages/users';
import { Warnings } from '../pages/warnings';
import { AuthRoute } from './Route';
import { NewGym } from '../pages/newGym';
import { Payments } from '../pages/payments';
import { Subscriptions } from '../pages/subscriptions';
import { Gyms } from '../pages/admin/gyms';

const Router: React.FC = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<ComingSoon />} /> */}

      <Route path="/" element={<SignIn />} />
      <Route path="/new-gym" element={<NewGym />} />
      <Route path="/void" element={<HelloWorld />} />

      <Route
        path="/dashboard"
        element={<AuthRoute component={<Dashboard />} />}
      />
      <Route
        path="/users"
        element={<AuthRoute component={<Users />} minLevelRequired={1} />}
      />
      <Route
        path="/users/:username"
        element={
          <AuthRoute component={<UserExercises />} minLevelRequired={1} />
        }
      />
      <Route
        path="/users/:username/:day"
        element={
          <AuthRoute component={<UserExercises />} minLevelRequired={1} />
        }
      />
      <Route
        path="/exercises"
        element={<AuthRoute component={<Exercises />} minLevelRequired={1} />}
      />
      <Route
        path="/gym/warnings"
        element={<AuthRoute component={<Warnings />} minLevelRequired={1} />}
      />
      <Route
        path="/gym/profile"
        element={<AuthRoute component={<GymProfile />} minLevelRequired={2} />}
      />
      <Route
        path="/gym/payments"
        element={<AuthRoute component={<Payments />} minLevelRequired={2} />}
      />
      <Route
        path="/gym/subscriptions"
        element={
          <AuthRoute component={<Subscriptions />} minLevelRequired={2} />
        }
      />
      <Route
        path="/admin/gyms"
        element={<AuthRoute component={<Gyms />} minLevelRequired={3} />}
      />
      <Route
        path="/profile"
        element={<AuthRoute component={<Profile />} minLevelRequired={1} />}
      />
    </Routes>
  );
};

export { Router };
