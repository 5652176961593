import styled from 'styled-components';
import { Form as FormContainer } from '@unform/web';

import { SeparateLabel } from '../../components/SeparateLabel';
import { ButtonConfirm } from '../../components/ButtonConfirm';
import { TextInfo } from '../../components/TextInfo';

export const Container = styled.div`
  padding: 20px;
  min-height: 100vh;

  h3 {
    font-size: 32px;
    color: #151515;
    margin: 20px 0;
  }

  @media (max-width: 1199px) {
    padding: 0;
    width: calc(100% - 40px);
    margin: auto;
  }
`;

export const Form = styled(FormContainer)`
  display: flex;
  width: 560px;

  margin: auto;

  flex-direction: column;

  & > * {
    margin-bottom: 10px;
  }

  @media (max-width: 1199px) {
    width: 100%;
  }
`;

export const Info = styled(TextInfo)`
  margin: 10px 0;
`;

export const Separate = styled(SeparateLabel)``;

export const Confirm = styled(ButtonConfirm)`
  align-self: center;
  margin: 30px 0;
`;
